import React, { useCallback } from 'react'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { breakpoint, cryptojs } from 'utils'
import useAuth from 'hooks/useAuth'
import IconConfigWithTheme from 'components/common/icon-config-with-theme'
import useThemeWithPartner from 'hooks/useThemeWithPartner'

const Style = styled.div`
    label: nav-favorite;

    /* position: absolute; */

    position: relative;
    display: none;
    width: 30px;
    height: 100%;
    align-items: center;
    font-size: 25px;
    margin-left: 1rem;

    ${breakpoint('LG')} {
        display: flex;
    }

    img {
        object-fit: cover;
        font-size: 1rem;
    }

    .fa-heart {
        color: var(--navbar-favorite-icon-color);
        margin-left: auto;
    }
`

function NavFavourite({ heart = false }) {
    const navigate = useNavigate()
    const { auth, profile } = useAuth()
    const theme = useThemeWithPartner()

    const gotoFavourite = useCallback(() => {
        if (theme.name !== 'betterperiod') {
            if (auth.isAuth) {
                let params = ''
                if (profile.userXPartner) {
                    if (profile.userXPartner.partnerTheme === 'default_humatrix') {
                        params = '?voucher=TLP&billing=human01'
                    }
                    if (profile.userXPartner.partnerTheme === 'default_sunday') {
                        params = '?voucher=sunday01'
                    }
                    if (profile.userXPartner.partnerTheme === 'default_oceanopd') {
                        params = '?voucher=oceanopd01'
                    }
                }

                navigate('/store/favourite' + params)
            } else {
                const from = {
                    path: '/store/favourite',
                    params: ''
                }

                navigate('/user/line?to=' + cryptojs.encode(from))
            }
        }
    }, [auth.isAuth])

    return (
        <Style onClick={gotoFavourite}>
            {!heart ? (
                <IconConfigWithTheme pageName='nav_favorite' iconName='heart' />
            ) : (
                <img src='/images/common/nav-favorite.gif' alt='nav-favorite' />
            )}
        </Style>
    )
}

export default NavFavourite
