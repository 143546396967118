import React, { useEffect } from 'react';
import { PopupUtils } from './popup-utils';

/**
 * @version 1.0.1 create
 * @see showClose: true, isClickOutSideClose: true
 *
 * @param {{
 * show?: boolean,
 * title?: string | Element,
 * message?: string | Element,
 * children: React.ReactNode,
 * icon?: 'success' | 'warning' | 'error' | Element,
 * type?: 'success' | 'warning' | 'error',
 * maxWidth?: string,
 * maxHeight?: string,
 * showClose?: boolean | true,
 * isClickOutSideClose?: boolean | true,
 * onClose?:()=> void,
 * buttons?: Array.<{
 * label:string,
 * variant:'primary' | 'second' | 'danger' | 'info',
 * action?:({loading: (value:boolean)=>void, close: ()=>void, startAction: ()=>void, endAction: ()=>void})
 * }>,
 * classNames?:{title: string, message: string, buttons: string}
 * } & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns
 */

function PopupJsx({ show, children, ...props }) {
    const popupIndex = React.useRef(null);
    const isShow2 = React.useRef(null);

    useEffect(() => {
        if (show) {
            popupIndex.current = PopupUtils.open({ children, ...props });
            isShow2.current = true;
        }

        if (!show && isShow2.current) {
            PopupUtils.close(popupIndex.current);
            isShow2.current = false;
        }
    }, [show]);

    useEffect(() => {
        if (isShow2.current && popupIndex.current) {
            PopupUtils.updateProps({ children, ...props }, popupIndex.current);
        }
    }, [children]);

    return <></>;
}

export default PopupJsx;
