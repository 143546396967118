import { useEffect, useRef, useState } from 'react'
import StepperItemComponent from './StepperItemComponent'
import StepperWrapperComponent from './StepperWrapperComponent'
import { StatusType } from '.'
// import './stepper-variable.css'

export type ProgressDotRender = (
    iconDot: any,
    info: {
        index: number
        status: StatusType
        title: React.ReactNode
        description: React.ReactNode
    }
) => React.ReactNode

export interface ItemsProps {
    title?: React.ReactNode
    description?: React.ReactNode
    icon?: React.ReactNode
    disabled?: boolean
    onClick?: () => void
}
export interface StepsListProps extends ItemsProps {
    status: StatusType
    percent: number
}

export interface StepperProps {
    current: number
    percent?: number
    items: ItemsProps[]
    progressDot?: boolean | ProgressDotRender
    status?: StatusType
    itemWidth?: number
    fixHeight?: number | string
    className?: string
}

function Stepper({ current, percent, status, items, itemWidth = 300, fixHeight, progressDot, ...props }: Readonly<StepperProps>) {
    const ref = useRef<HTMLDivElement>(null!)
    const refScroll = useRef<HTMLDivElement>(null!)
    //
    const [stepsList, setStepsList] = useState<StepsListProps[]>([])

    const getWidth = (el: HTMLElement) => {
        let elWidth = el?.offsetWidth ?? 0
        if (el) {
            elWidth += parseInt(window.getComputedStyle(el).getPropertyValue('margin-left'))
            elWidth += parseInt(window.getComputedStyle(el).getPropertyValue('margin-right'))
            elWidth += parseInt(window.getComputedStyle(el).getPropertyValue('padding-left'))
            elWidth += parseInt(window.getComputedStyle(el).getPropertyValue('padding-right'))
            elWidth += parseInt(window.getComputedStyle(el).getPropertyValue('border-left'))
            elWidth += parseInt(window.getComputedStyle(el).getPropertyValue('border-right'))
        }

        return elWidth
    }

    const scrollToCurrentStep = (currentStep: number) => {
        if (ref.current) {
            ref.current.scrollTo({
                behavior: 'smooth',
                left: currentStep * itemWidth,
                top: 0
            })
        }
    }

    useEffect(() => {
        const setProgressStatus = () => {
            if (items.length > 0) {
                let temp: StepsListProps[] = []
                items.forEach((item, index) => {
                    let statusOfItem: StatusType = 'wait'
                    let percentOfItem: number = 0
                    if (index === current) {
                        statusOfItem = status ?? 'process'
                        percentOfItem = percent ?? 0
                    } else if (index < current) {
                        statusOfItem = 'finish'
                        percentOfItem = 100
                    }

                    temp.push({
                        ...item,
                        status: statusOfItem,
                        percent: percentOfItem
                    })
                })

                setStepsList([...temp])

                setTimeout(() => {
                    scrollToCurrentStep(current)
                }, 1500)
            }
        }
        setProgressStatus()
    }, [items.toString()])

    return (
        <StepperWrapperComponent wrapperRef={ref} scrollRef={refScroll} style={{ height: fixHeight }} {...props}>
            {stepsList.map((item, index) => (
                <StepperItemComponent
                    step={index}
                    width={itemWidth}
                    key={`step-key-${index}` as React.Key}
                    progressDot={progressDot}
                    {...item}
                />
            ))}
        </StepperWrapperComponent>
    )
}

export default Stepper
