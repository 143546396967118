import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const Style = styled.div`
    label: page-loading;

    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-normal-1);
    font-size: 1.2rem;
    background-color: #ffffff;
    z-index: var(--page-loading-zindex);

    &:not(.__active) {
        display: none;
    }

    .rocket {
        width: 100%;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;

        img {
            object-fit: cover;
        }
    }
    .spinner {
        margin: 100px auto;
        width: 100px;
        height: 50px;
        text-align: center;
        font-size: 10px;
    }

    .spinner > div {
        background-color: ${(props) => (props.spinnerColor ? props.spinnerColor : 'var(--primary-color)')};
        height: 100%;
        width: 6px;
        display: inline-block;
        margin: 0 4px;
        border-radius: 6px;

        -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
        animation: sk-stretchdelay 1.2s infinite ease-in-out;
    }

    .spinner .rect2 {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s;
    }

    .spinner .rect3 {
        -webkit-animation-delay: -1s;
        animation-delay: -1s;
    }

    .spinner .rect4 {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
    }

    .spinner .rect5 {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s;
    }

    @-webkit-keyframes sk-stretchdelay {
        0%,
        40%,
        100% {
            -webkit-transform: scaleY(0.4);
        }
        20% {
            -webkit-transform: scaleY(1);
        }
    }

    @keyframes sk-stretchdelay {
        0%,
        40%,
        100% {
            transform: scaleY(0.4);
            -webkit-transform: scaleY(0.4);
        }
        20% {
            transform: scaleY(1);
            -webkit-transform: scaleY(1);
        }
    }
`

/**
 *
 * @param {{active: boolean | false, type: 'spinner' | 'rocket'}} props
 * @returns
 */

function PageLoading({ active = false, type = 'spinner', spinnerColor = '', ...props }) {
    useEffect(() => {
        if (active) {
            document.body.classList.add('page-loding')
        } else {
            document.body.classList.remove('page-loding')
        }
    }, [active])

    if (PageLoading.types[type] === 'SPINNER') {
        return (
            <Style {...props} className={active ? '__active' : '__inactive'} spinnerColor={spinnerColor} data-test-active={`${active}`}>
                <div className='spinner'>
                    <div className='rect1'></div>
                    <div className='rect2'></div>
                    <div className='rect3'></div>
                    <div className='rect4'></div>
                    <div className='rect5'></div>
                </div>
            </Style>
        )
    }

    if (PageLoading.types[type] === 'ROCKET') {
        return (
            <Style {...props} className={active ? '__active' : '__inactive'}>
                <div className='rocket'>
                    <img src='/images/common/rocket-green.gif' alt='rocket-green' />
                </div>
            </Style>
        )
    }

    return null
}

PageLoading.types = {
    rocket: 'ROCKET',
    spinner: 'SPINNER'
}

PageLoading.defaultProps = {
    active: false,
    type: 'spinner'
}

PageLoading.propTypes = {
    active: PropTypes.bool,
    type: PropTypes.oneOf(['spinner', 'rocket'])
}

export default PageLoading
