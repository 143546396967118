import styled from '@emotion/styled'
import React from 'react'
import { breakpoint } from 'utils'
import NavLogo from './nav-logo'
import NavLanguage from './nav-language'
import NavLogin from './nav-login'
import NavProfile from './nav-profile'
import useAuth from 'hooks/useAuth'
import NavMenuBar from './nav-menu-bar'
import NavFavourite from './nav-favourite'

const Style = styled.div`
    label: nav;

    position: relative;
    display: flex;
    height: var(--navbar-height);
    align-items: center;
    padding: 15px 5%;
    background-color: var(--navbar-background-color);
    z-index: var(--navbar-zindex);

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: ${(props) => (props.hasInProgress ? 'block' : 'none')};
        background-color: transparent;
        /* background-color: #f00; */
    }

    ${breakpoint('XL')} {
        padding: 10px 5%;
    }
    ${breakpoint('LG')} {
        justify-content: flex-end;
    }
`

function Navbar({ hasInProgress = false, mobileLanguage = false }) {
    const { auth } = useAuth()

    return (
        <Style className='navbar' hasInProgress={hasInProgress}>
            <NavLogo />
            <NavMenuBar role={auth.role} />
            <NavLanguage mobileLanguage={mobileLanguage} />
            <NavLogin />
            <NavProfile />
            {!hasInProgress && <NavFavourite />}
        </Style>
    )
}

export default Navbar
