import React from 'react';
import FormGroup from '../form-group';
import { extendesClassname } from '../utils';
import FormInvalidFeedback from '../form-invalid-feedback';
import FormRecommend from '../form-recommend';
import FormLabel from '../form-label';

/**
 *
 * @param {{
 * title?: React.ReactNode,
 * label?: React.ReactNode,
 * optionList?: Array<{
 *      label: String |Element,
 *      value: String,
 * }>,
 * classNameLabel?: String,
 * classNameField?: String,
 * errorMsg?: React.ReactNode,
 * recommendMsg?: React.ReactNode,
 * isInvalid?: Boolean,
 * labelTextSmall?: Boolean,
 * titleTextSmall?: Boolean,
 * xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'auto' | undefined,
 * sm?: xs,
 * md?: xs,
 * lg?: xs,
 * xl?: xs,
 * xxl?: xs,
 * xxxl?: xs,
 * } & React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>} peops
 * @returns
 */
function FieldSelect({
    title = '',
    label = '',
    optionList = [],
    classNameLabel = '',
    classNameField = '',
    errorMsg = '',
    recommendMsg = '',
    isInvalid = false,
    labelTextSmall = true,
    titleTextSmall = true,
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
    xxxl,
    children,
    ...props
}) {
    const selectRef = React.useRef(null);

    React.useEffect(() => {
        const setInvalid = () => {
            const _isInvalidClassname = selectRef.current?.classList?.contains('is-invalid');

            if (isInvalid) {
                selectRef.current.setCustomValidity('Invalid field.');

                if (!_isInvalidClassname) selectRef.current.classList.add('is-invalid');
            } else if (_isInvalidClassname) {
                selectRef.current.classList.remove('is-invalid');
            }
        };

        setInvalid();

        return () => selectRef.current.setCustomValidity('');
    }, [isInvalid]);

    return (
        <FormGroup xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl} xxxl={xxxl} className={classNameField}>
            {(title || label) && (
                <FormLabel textSmall={labelTextSmall && titleTextSmall} className={classNameLabel} required={props.required}>
                    {title || label}
                </FormLabel>
            )}
            <select {...props} {...extendesClassname(['exp-form-control', props.className])} ref={selectRef}>
                {optionList.map((el, index) => (
                    <option key={index} value={el.value}>
                        {el.label}
                    </option>
                ))}
                {children}
            </select>
            {errorMsg && <FormInvalidFeedback>{errorMsg}</FormInvalidFeedback>}
            {recommendMsg && <FormRecommend>{recommendMsg}</FormRecommend>}
        </FormGroup>
    );
}

export default FieldSelect;
