import React from 'react'
import FieldCheckbox from './field-checkbox'
import FieldCheckboxGroup from './field-checkbox-group'
import FieldDate from './field-date'
import FieldFile from './field-file'
import FieldMask from './field-mask'
import FieldRadio from './field-radio'
import FieldRadioGroup from './field-radio-group'
import FieldSelect from './field-select'
import FieldControl from './field-control'
import FieldTextArea from './field-textarea'
import FieldPassWord from './field-password'
import FieldNumeric from './field-numeric'

function Field({ type = '', ...props }) {
    if (type === 'checkbox-group') return <FieldCheckboxGroup {...props} />

    if (type === 'checkbox') return <FieldCheckbox {...props} />

    if (type === 'file') return <FieldFile {...props} />

    if (type === 'date') return <FieldDate {...props} />

    if (type === 'mask') return <FieldMask {...props} />

    if (type === 'radio-group') return <FieldRadioGroup {...props} />

    if (type === 'radio') return <FieldRadio {...props} />

    if (type === 'select') return <FieldSelect {...props} />

    if (type === 'textarea') return <FieldTextArea {...props} />

    if (type === 'password') return <FieldPassWord {...props} />

    if (type === 'numeric') return <FieldNumeric {...props} />

    return <FieldControl {...props} />
}

Field.Checkbox = FieldCheckbox
Field.CheckboxGroup = FieldCheckboxGroup
Field.Radio = FieldRadio
Field.RadioGroup = FieldRadioGroup
Field.Date = FieldDate
Field.File = FieldFile
Field.Mask = FieldMask
Field.Select = FieldSelect
Field.TextArea = FieldTextArea
Field.Numeric = FieldNumeric
Field.Control = FieldControl

export default Field
