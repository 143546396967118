import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { extendesClassname } from 'utils'

const NavigationStyle = styled.div`
    label: navigation;

    position: fixed;
    left: 2%;
    right: 2%;
    bottom: 30px;
    display: none;
    max-width: 500px;
    margin: auto;
    padding: 10px 25px 4px 25px;
    border-radius: 60px;
    overflow-x: auto;
    z-index: var(--navigation-zindex);
    background-color: var(--navigation-nav);

    @media screen and (max-width: 580px) {
        display: block;
    }

    .navigation-menu {
        display: flex;
        align-items: center;
        width: 100%;
        overflow-x: auto;
    }
`

const MenuBoxStyle = styled.div`
    label: navigation-menu;

    width: fit-content;
    color: var(--navigation-box);
    text-align: center;
    margin: auto;
    padding: 0 10px;

    &.active {
        color: var(--navigation-box-active);
    }

    i {
        font-size: 1.4rem;
    }
`

function Navigation({ children }) {
    return (
        <NavigationStyle className='navigation-nav'>
            <div className='navigation-menu'>{children}</div>
        </NavigationStyle>
    )
}

/**
 *
 * @param {{title: string, icon: element, to: string, tag: string, className, children?: React.ReactNode }} props
 * @returns
 */

function MenuBox({ title, icon, to, tag, className, children }) {
    const navigate = useNavigate()
    const getActive = () => {
        const location = window.location

        if (to === location.pathname || location.pathname.includes(tag)) return 'active'

        return null
    }

    return (
        <MenuBoxStyle {...extendesClassname([getActive(), className])} onClick={() => navigate(to)}>
            {icon && <div>{icon}</div>}
            {title && <div className='small'>{title}</div>}
            {children}
        </MenuBoxStyle>
    )
}

MenuBox.defaultProps = {
    to: '#',
    tag: ''
}

MenuBox.propTypes = {
    to: PropTypes.any.isRequired,
    tag: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    icon: PropTypes.node,
    className: PropTypes.string
}

export { Navigation, MenuBox }
