import React from 'react'
import styled from '@emotion/styled'
import SidebarProfile from './sidebar-profile'
import Sidebarlanguage from './sidebar-language'
import SidebarAddress from './sidebar-address'
import SidebarInfo from './sidebar-info'

function SideBarContent({ hasInProgress }) {
    return (
        <Style className='content'>
            <SidebarProfile hasInProgress={hasInProgress} />
            <Sidebarlanguage />
            <SidebarInfo />
            <SidebarAddress />
        </Style>
    )
}

export default SideBarContent

const Style = styled.div`
    label: sidebar-content;

    display: flex;
    flex-direction: column;
    height: calc(var(--body-height) - 50px);
    padding: 10px 30px;
    overflow-y: auto;
    background-color: var(--primary-color);
    z-index: 10;
`
