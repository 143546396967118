import React from 'react'
import { render } from 'react-dom'
import * as serviceWorker from './serviceWorker'
import App from './app'
import './i18n' // *** set timezone with i18next
import { BrowserRouter } from 'react-router-dom'

const rootElement = document.getElementById('root')

// remove console
if (process.env.REACT_APP_FILE_NAME === '.env.production') {
    console.log = () => {}
    console.debug = () => {}
    // console.error = () => {}
}

if (rootElement === null) {
    throw new Error('No root element')
}

render(
    <BrowserRouter forceRefresh={false}>
        <App />
    </BrowserRouter>,
    rootElement
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.unregister()
