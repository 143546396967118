import { DEFAULT_BREAKPOINTS_SIZE } from 'constants/default-config';

/**
 *
 * @see XS = 576px
 * @see SM = 576px
 * @see MD = 768px
 * @see LG = 992px
 * @see XL = 1200px
 * @see XXL = 1440px
 * @see XXL = 1680px
 * @see mediafeature = 'max'
 *
 * @param {'XS' | 'SM' | 'MD' | 'LG' | 'XL' | 'XXL' | 'XXXL' | string} bp
 * @param {'min' | 'max'} mediafeature
 * @returns
 */

function breakpoint(bp, mediafeature = 'max') {
    const isExistingBreakpoint = Object.keys(DEFAULT_BREAKPOINTS_SIZE).some((b) => b === bp);

    if (isExistingBreakpoint) {
        return `@media screen and (${mediafeature}-width: ${DEFAULT_BREAKPOINTS_SIZE[bp]}px)`;
    }

    return `@media screen and (${mediafeature}-width: ${bp})`;
}

export default breakpoint;
