import { css } from '@emotion/react'

export const yaphromTheme = css`
    html[theme='yaphrom'] {
        --white: #ffffff;
        --lightseagreen-1: #8acbca;
        --lightseagreen-2: #c7e9ea;
        --lightseagreen-3: #e4f7f7;
        --lightseagreen-transparent-15: rgba(0, 172, 170, 0.15);
        --lightseagreen-transparent-45: rgba(0, 172, 170, 0.45);
        --green-1: #49ba00;
        --green-2: #00b15f;
        --green-3: #008180;
        --red-1: #c30000;
        --red-2: #fe5f55;
        --red-3: #ff715c;
        --red-4: #fbdad9;
        --pink-1: #ffeeee;
        --orange-1: #b53800;
        --orange-2: #f66e32;
        --orange-3: #ff8c5a;
        --orange-4: #ffb268;
        --orange-5: #f48d5b;
        --blue-1: #0071ff;
        --blue-2: #3a91f7;
        --blue-3: #8abaff;
        --blue-4: #e5f0ff;
        --yellow: #fdb359;
        --gray-2: #707070;
        &[lang='th'],
        &[lang='TH'] {
            --font-title: Prompt, sans-serif;
            --font-subtitle: Prompt, sans-serif;
            --font-detail1: Prompt, sans-serif;
            --font-detail2: Sarabun-Regular, sans-serif;
            --font-body: Prompt, sans-serif;
        }
        &[lang='en'],
        &[lang='EN'] {
            --font-title: Prompt, sans-serif;
            --font-subtitle: Prompt, sans-serif;
            --font-detail1: Prompt, sans-serif;
            --font-detail2: Sarabun-Regular, sans-serif;
            --font-body: Prompt, sans-serif;
        }
        --P1: #9dd0c7;
        --P2: #296099;
        --P3: var(--text-normal-4);
        --N1: #000000;
        --N2: #707070;
        --N3: #00000080;
        --N4: #a0a0a0;
        --N5: #c1c1c1;
        --N6: #0000001a;
        --N7: #ebebeb;
        --primary-color: var(--P1);
        --second-color: var(--P2);
        --third-color: var(--N4);
        --info-color: var(--N1);
        --success-color: var(--green-1);
        --icon-primary-color: var(--lightseagreen-1);
        --icon-second-color: var(--orange-2);
        --icon-third-color: var(--P2);
        --icon-fourth-color: var(--green-3);
        --text-title-color: var(--P1);
        --text-title-weight: 500;
        --text-subtitle-color: var(--P1);
        --text-subtitle-weight: 500;
        --text-normal-1: var(--N1);
        --text-normal-2: var(--N2);
        --text-normal-3: var(--N3);
        --text-normal-4: var(--N4);
        --text-normal-5: var(--N5);
        --text-normal-6: var(--N6);
        --text-normal-7: var(--N7);
        --text-duotone-set1-color1: var(--N4);
        --text-duotone-set1-color2: var(--N1);
        --title-1: var(--N1);
        --title-3: var(--P2);
        body {
            --body-color: var(--N2);
            --body-background-color: #f9f9fa;
        }
        .btn,
        .theme-btn {
            --button-radius: 1.5rem;
        }
        .btn-primary,
        .theme-btn-primary {
            --btn-primary-color: var(--white);
            --btn-primary-border-color: var(--P2);
            --btn-primary-background-color: var(--P2);
            --btn-primary-hover-color: var(--white);
            --btn-primary-hover-border-color: var(--P2);
            --btn-primary-hover-background-color: var(--green-3);
        }
        .btn-second,
        .theme-btn-second {
            --btn-second-color: var(--P2);
            --btn-second-border-color: var(--P2);
            --btn-second-background-color: var(--white);
            --btn-second-hover-color: var(--white);
            --btn-second-hover-border-color: var(--P2);
            --btn-second-hover-background-color: var(--P2);
        }
        .btn-danger,
        .theme-btn-danger {
            --btn-danger-color: var(--white);
            --btn-danger-border-color: var(--red-1);
            --btn-danger-background-color: var(--red-1);
            --btn-danger-hover-color: var(--white);
            --btn-danger-hover-border-color: var(--red-1);
            --btn-danger-hover-background-color: #a70202;
        }
        .btn-info,
        .theme-btn-info {
            --btn-info-color: var(--white);
            --btn-info-border-color: var(--orange-2);
            --btn-info-background-color: var(--orange-2);
            --btn-info-hover-color: var(--white);
            --btn-info-hover-border-color: #f25916;
            --btn-info-hover-background-color: #f25916;
        }
        .btn-blue,
        .theme-btn-blue {
            --btn-blue-color: var(--white);
            --btn-blue-border-color: var(--blue-2);
            --btn-blue-background-color: var(--blue-2);
            --btn-blue-hover-color: var(--white);
            --btn-blue-hover-border-color: var(--blue-2);
            --btn-blue-hover-background-color: var(--blue-2);
        }
        .btn-orange,
        .theme-btn-orange {
            --btn-orange-color: var(--white);
            --btn-orange-border-color: #f66e32;
            --btn-orange-background-color: #f66e32;
            --btn-orange-hover-color: var(--white);
            --btn-orange-hover-border-color: #f66e32;
            --btn-orange-hover-background-color: #f66e32;
        }
        .btn-calendar {
            --btn-calendar-color: var(--P2);
            --btn-calendar-radius: 1.5rem;
        }
        .navbar {
            --navbar-background-color: var(--N7);
            --navbar-menu-bar-color: var(--N2);
            --navbar-vertical-bar-color: var(--N3);
            --navbar-language-color-inactive: var(--N2);
            --navbar-language-color-active: var(--N1);
            --navbar-login-color: var(--N2);
            --navbar-register-color: var(--N2);
            --navbar-favorite-icon-color: var(--P1);
            --navbar-profile-color: var(--N2);
            --navbar-profile-background-color: var(--P1);
            --navbar-dropdown-color-inactive: var(--N4);
            --navbar-dropdown-color-active: var(--P1);
            --navbar-dropdown-icon-color: var(--P1);
            --navbar-dropdown-background-color: var(--N7);
        }
        .navigation-nav {
            --navigation-nav: var(--P1);
            --navigation-box: rgba(255, 255, 255, 0.5);
            --navigation-box-active: rgba(255, 255, 255, 1);
        }
        --progressbar-height: 60px;
        .progressbar {
            --progressbar-padding: 0;
            --progressbar-point-size: 14px;
            --progressbar-point-size-active: 20px;
            --progressbar-point-border-size: 0px;
            --progressbar-line-width: 140px;
            --progressbar-line-height: 4px;
            --progressbar-label-font-size: 75%;
            --progressbar-point-color: var(--N4);
            --progressbar-point-color-active: var(--white);
            --progressbar-point-color-disabled: #000;
            --progressbar-point-border-color: transparent;
            --progressbar-point-border-color-active: transparent;
            --progressbar-point-border-color-disabled: #00f;
            --progressbar-point-background-color: var(--N7);
            --progressbar-point-background-color-active: var(--P2);
            --progressbar-point-background-color-disabled: #777;
            --progressbar-line-background-color: var(--N7);
            --progressbar-line-background-color-active: var(--P2);
            --progressbar-label-color: var(--N4);
            --progressbar-label-color-active: var(--N2);
        }
        .stepper-variable {
            --steps-item-icon-size: var(--progressbar-point-size);
            --steps-item-icon-size-active: var(--progressbar-point-size-active);
            --steps-item-icon-color: var(--progressbar-point-background-color);
            --steps-item-icon-color-active: var(--progressbar-point-background-color-active);
            --steps-item-dot-size: 8px;
            --steps-item-dot-color: var(--progressbar-point-color);
            --steps-item-dot-color-active: var(--progressbar-point-color-active);
            --steps-item-tail-heght: var(--progressbar-line-height);
            --steps-item-tail-color: var(--progressbar-line-background-color);
            --steps-item-tail-color-active: var(--progressbar-line-background-color-active);
            --steps-item-content-color: var(--progressbar-label-color);
            --steps-item-content-color-active: var(--progressbar-label-color-active);
        }
        .breadcrumbs {
            --breadcrumbs-icon-color: #80a8a8;
            --breadcrumbs-background-color: var(--P1);
        }
        form,
        input,
        fieldset {
            --input-color: #495057;
            --input-border-color: #ced4da;
            --input-placehoder-color: var(--N5);
            --input-focus-border-color: #80bdff;
            --input-hover-border-color: #80bdff;
            --input-valid-color: #495057;
            --input-valid-border-color: #28a745;
            --input-invalid-color: #dc3545;
            --input-invalid-border-color: #dc3545;
            --input-background-color: var(--white);
        }
        .input-radio-type1 {
            --input-radio-type1-background-color: #f2fbfb;
        }
        .input-checkbox-type2 {
            --input-checkbox-type2-bubble-text-color-active: var(--green-3);
            --input-checkbox-type2-bubble-text-color-inactive: var(--green-3);
            --input-checkbox-type2-bubble-remove-icon-color: var(--green-3);
            --input-checkbox-type2-bubble-border-color-active: #e2eff0;
            --input-checkbox-type2-bubble-border-color-inactive: var(--P2);
            --input-checkbox-type2-bubble-background-color-active: #e2eff0;
            --input-checkbox-type2-bubble-background-color-inactive: var(--white);
        }
        .existing-health-bubble {
            --existing-health-bubble-text-color-active: var(--N2);
            --existing-health-bubble-text-color-inactive: var(--P1);
            --existing-health-bubble-icon-remove-color: #f83636;
            --existing-health-bubble-border-color-active: transparent;
            --existing-health-bubble-border-color-inactive: transparent;
            --existing-health-bubble-background-color-active: var(--N7);
            --existing-health-bubble-background-color-inactive: #c7e9ea;
        }
        .autocomplete-button {
            --autocomplete-button-color: var(--P1);
            --autocomplete-button-background-color: var(--lightseagreen-2);
        }
        .doctor-language {
            --doctor-language-color: var(--white);
            --doctor-language-background-color: var(--P2);
        }
        .service-tabs {
            --service-tabs-color: var(--white);
            --service-tabs-color-active: var(--white);
            --service-tabs-background-color: var(--N5);
            --service-tabs-background-color-active: var(--P2);
            --service-tabs-border: transparent;
        }
        .address-tag {
            --address-tag-color: var(--P2);
            --address-tag-icon-color: var(--white);
            --address-tag-icon-background-color: var(--green-3);
            --address-tag-background-color: var(--lightseagreen-3);
        }
        --address-add-background-color: var(--lightseagreen-3);
        .address-select-consult {
            --address-select-consult-color-active: var(--N1);
            --address-select-consult-color-inactive: var(--N1);
            --address-select-consult-icon-color-active: var(--P2);
            --address-select-consult-icon-color-inactive: #e8e8e8;
            --address-select-consult-border-color-active: var(--P2);
            --address-select-consult-border-color-inactive: var(--N4);
            --address-select-consult-background-color-active: #f2fbfb;
            --address-select-consult-background-color-inactive: var(--white);
        }
        .select-payment {
            --select-payment-color-active: var(--N1);
            --select-payment-color-inactive: var(--N1);
            --select-payment-icon-color-active: var(--P2);
            --select-payment-icon-color-inactive: #7f7c7c;
            --select-payment-border-color-active: var(--P2);
            --select-payment-border-color-inactive: var(--N4);
            --select-payment-background-color-active: #f2fbfb;
            --select-payment-background-color-inactive: var(--white);
        }
        .pharmacy-favourite-icon {
            --pharmacy-favourite-icon-color-active: var(--orange-2);
            --pharmacy-favourite-icon-color-inactive: #444444;
        }
        .medicine-list,
        .medicine-net-total,
        .medicine-total {
            --medicine-list-net-total-background-color: #75aaaa;
            --medicine-list-approve-background-color: var(--lightseagreen-3);
        }
        .health-info-icon {
            --congenital-disease-icon-background-color: var(--orange);
            --frequently-drug-icon-background-color: var(--second-color);
            --drug-allergy-icon-background-color: var(--orange-1);
        }
        .claim-input-voucher {
            --claim-input-voucher-background-color: var(--lightseagreen-3);
        }
        --claim-address-panel-background-color: #e5f7f6;
        --claim-joincall-panel-background-color: #e5f7f6;
        --claim-pending-med-panel-background-color: var(--lightseagreen-3);
        --claim-srvmed-order-payment-info-background-color: var(--lightseagreen-3);
        --incall-btn-refresh-background-color: #303030;
        --report-consult-background-color: var(--lightseagreen-3);
        --payment-summary-panel-background-color: var(--white);
        --payment-summary-background-color: var(--lightseagreen-3);
        --terms-conditons-popup-panel-background-color: var(--lightseagreen-3);
        --color-text-gray-3: var(--N5);
        --srvmed-finish-text-detail: var(--N4);
    }
`
