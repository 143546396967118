import React from 'react'
import { splitIntoRows } from './utils'
import { LOCALE } from 'components/common/exp-calendarjs'

function CalendarMonthPanel({
    locale = 'th',
    currentDate = new Date(),
    onPrevYear = () => null,
    onNextyear = () => null,
    onSelectMonth = () => null,
    onShowYearOfDecade = () => null
}) {
    const monthsShort = LOCALE[locale].monthsShort
    const monthOfYear = splitIntoRows(monthsShort, 3)

    return (
        <div className='calendar-month-panel'>
            <div className='calendar-month-panel-header'>
                <button className='calendar-month-panel-prev-year-btn' onClick={onPrevYear}>
                    {'<<'}
                </button>
                <span className='calendar-month-panel-year-select' onClick={onShowYearOfDecade}>
                    {currentDate.getFullYear()}
                </span>
                <button className='calendar-month-panel-next-year-btn' onClick={onNextyear}>
                    {'>>'}
                </button>
            </div>
            <div className='calendar-month-panel-body'>
                <table className='calendar-table'>
                    <tbody className='calendar-tbody'>
                        {monthOfYear.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {row.map((cell, cellIndex) => (
                                    <td
                                        key={cellIndex}
                                        className={
                                            currentDate.getMonth() === rowIndex * 3 + cellIndex
                                                ? 'calendar-month-panel-cell calendar-month-panel-selected-cell'
                                                : 'calendar-month-panel-cell'
                                        }
                                        onClick={() => onSelectMonth(rowIndex * 3 + cellIndex)}
                                    >
                                        <div className='calendar-month-panel-month'>{cell}</div>
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default CalendarMonthPanel
