import styled from '@emotion/styled/macro'
import React, { useRef, useState } from 'react'

const Style = styled.div`
    label: exp-collapse;

    &.exp-collapse:not(.show) {
        display: none;
    }

    &.exp-collapsing {
        height: 0;
        overflow: hidden;
        transition: height 0.35s;
    }

    .exp-collapse-content {
        position: relative;
        padding: 1px;
    }
`

/**
 *
 * @param {{
 * show: boolean,
 * children: Element
 * } & React.HTMLInputElement<T> } props
 * @returns
 */
function EXPCollapse({ show = false, children }) {
    const mainRef = useRef(null)
    const contentRef = useRef(null)
    const timer1Ref = useRef(null)
    const timer2Ref = useRef(null)
    //
    const [contentHeight, setContentHeight] = useState({})

    React.useEffect(() => {
        const onCheckDefaultShow = () => {
            if (show && mainRef.current) {
                mainRef.current.classList.add('show')
            }
        }

        onCheckDefaultShow()
    }, [])

    React.useEffect(() => {
        //run after useEffect onCheckDefaultShow()

        const onShow = () => {
            if (show && mainRef.current?.classList) {
                mainRef.current.classList.remove('exp-collapse')
                mainRef.current.classList.add('exp-collapsing')
                timer1Ref.current = setTimeout(() => {
                    setContentHeight({ height: `${contentRef.current.getBoundingClientRect().height}px` })
                    timer2Ref.current = setTimeout(() => {
                        setContentHeight({})
                        mainRef.current.classList.remove('exp-collapsing')
                        mainRef.current.classList.add('exp-collapse', 'show')
                    }, 350)
                }, 100)
            }

            if (!show && mainRef.current.classList.contains('show')) {
                mainRef.current.classList.remove('exp-collapse', 'show')
                setContentHeight({ height: `${contentRef.current.getBoundingClientRect().height}px` })

                timer1Ref.current = setTimeout(() => {
                    mainRef.current.classList.add('exp-collapsing')
                    setContentHeight({})

                    timer2Ref.current = setTimeout(() => {
                        mainRef.current.classList.remove('exp-collapsing')
                        mainRef.current.classList.add('exp-collapse')
                    }, 300)
                }, 50)
                //
            }
        }
        onShow()

        return () => {
            clearTimeout(timer1Ref.current)
            clearTimeout(timer2Ref.current)
        }
    }, [show, mainRef.current])

    return (
        <Style ref={mainRef} aria-expanded={show} className='exp-collapse' style={{ ...contentHeight }}>
            <div className='exp-collapse-content' ref={contentRef}>
                <div className='exp-collapse-body'>{children}</div>
            </div>
        </Style>
    )
}

export default EXPCollapse
