export const atFor = (style, start, end, multiply, unit) => {
    let result = ''
    for (let index = start; index <= end; index++) {
        result += `
        &-${index * multiply} {
            ${style}: calc(${multiply}${unit} * ${index}) !important 
        }

        `
    }
    return result
}
