import { isMobile, isAndroid, isIOS, isChrome, isSafari, isMacOs, isWindows, isEdgeChromium, browserName } from 'react-device-detect'
import cryptojs from './cryptoJs'
import { getCookie } from 'cookies-next'
import i18n from '../i18n'

const WEBVIEW_LIST = ['Facebook', 'Chrome WebView', 'Line', 'WebKit']

function isWebview() {
    return isMobile && WEBVIEW_LIST.some((s) => browserName.includes(s))
}

function getBrowserRecommend() {
    if (isMobile && isAndroid && browserName && !isChrome && !browserName.includes('Samsung') && !browserName.includes('Chrome WebView')) {
        return 'chrome'
    }
    if (isMobile && isIOS && !isSafari && browserName && !browserName.includes('Line') && !browserName.includes('WebKit')) {
        return 'safari'
    }
    if (isWindows && !isChrome && !isEdgeChromium) {
        return 'chrome'
    }
    if (isMacOs && !isSafari && !isChrome) {
        return 'safari'
    }

    return ''
}

function getUrlToOpenExternalBrowser(path = '/home', params = '', profile = {}) {
    const token = getCookie('userToken', { domain: process.env.REACT_APP_DOMAIN })

    if (token && profile) {
        const encodeParams = cryptojs.encode({
            path: path,
            params: params,
            data: {
                authorization: token,
                partner: profile?.partner,
                userId: profile?.userId,
                userType: profile?.userType,
                language: i18n.language?.toUpperCase()
            }
        })

        return `${window.location.origin}/line/redirect?to=${encodeParams}&openExternalBrowser=1`
    }

    return `https://liff.line.me/${process.env.REACT_APP_LIFF_APPID_HOME}`
}

function checkDevice(path = '/home', params = '', profile = {}) {
    const _isWebview = isWebview()
    if (!_isWebview) {
        return {
            isWebview: false,
            browserRecommend: '',
            redirectUrl: ''
        }
    }

    console.debug('browserName: ', browserName)

    return {
        isWebview: true,
        browserRecommend: getBrowserRecommend(),
        redirectUrl: getUrlToOpenExternalBrowser(path, params, profile)
    }
}

export default checkDevice

export { isWebview, getBrowserRecommend, getUrlToOpenExternalBrowser }
