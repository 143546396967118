import React from 'react'
import styled from '@emotion/styled'
import { language } from 'utils'
import { useTranslation } from 'react-i18next'
import { LOGO_IMAGE_WITH_THEME } from 'constants/images-config'
import useThemeWithPartner from 'hooks/useThemeWithPartner'

const Style = styled.div`
    label: sidebar-header;

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    padding: 10px 15px;
    background-color: var(--text-normal-7);

    .logo {
        width: 100%;
        height: 100%;

        img {
            object-position: left;
        }
    }
`

function SidebarHeader() {
    const { i18n } = useTranslation()
    const theme = useThemeWithPartner()
    return (
        <Style>
            <div className='logo'>
                <img src={LOGO_IMAGE_WITH_THEME[theme.name][i18n.language.toLowerCase()]} alt={theme.name} className='nav-logo-image' />
            </div>
        </Style>
    )
}

export default SidebarHeader
