import SlideUp from './slideup'
import { LiftUpUtils } from './lift-up-utils'
import { LiftUpWrapper } from './lift-wrapper'

const EXPLiftUp = {
    register: (ref) => LiftUpUtils.register(ref),
    close: () => LiftUpUtils.closeAll(),
    Init: LiftUpWrapper,
    slideUp: SlideUp
}

export { SlideUp }
export default EXPLiftUp
