import { breakpoint, fluidSizing } from 'utils'
import { fontFace } from './css-font-face'
import '../font-awesome/css/all.css'

const { css } = require('@emotion/react')

const { utilityStyle } = require('./css-utility')
const { color } = require('./css-text-color')
const { root } = require('./css-variable')
const { input } = require('./css-input')
const { col } = require('./css-col')
const { button } = require('./css-button')
const { listStyle } = require('./css-list-style')

const GlobalStyle = css`
    //
    html {
        width: 100%;
        height: 100%;
        overflow: hidden;
        ${fluidSizing('font-size', { XS: 14, MD: 15, XXL: 16 })}

        & div[data-text='title'] {
            font-family: var(--font-body);
        }
        .font-title {
            font-family: var(--font-title);
            font-weight: 500;
        }
        .font-subtitle {
            font-family: var(--font-subtitle);
        }
        .font-detail1 {
            font-family: var(--font-detail1);
        }
        .font-detail2 {
            font-family: var(--font-detail2);
        }
    }

    body {
        position: relative;
        height: 100%;
        color: var(--body-color);
        font-weight: 400;
        padding-right: 0;
        overflow-x: hidden;
        overflow-y: auto;
        /* font-size: 1rem; */
        font-family: var(--font-body);

        ${fluidSizing('font-size', { XS: 14, MD: 15, XXL: 16 })}

        ${breakpoint('XL')} {
            &::-webkit-scrollbar-track {
                /* border-radius: 10px; */
                background-color: #f5f5f5;
            }
            &::-webkit-scrollbar {
                width: 2px;
                background-color: #f5f5f5;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: #777;
            }
        }
    }
    hr {
        width: 100%;
    }
    /* image */
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }
    /* div */
    div:focus {
        outline: unset;
    }

    //section
    section {
        position: relative;
        z-index: 2;
    }
    /* link */
    a:hover {
        text-decoration: underline;
    }

    /* font face */
    ${fontFace}
    /* extens grid layout */
    ${col}
    // text color
    ${color}
    //root
    ${root} 
    //theme
    /* theme */
    //button
    ${button}
     //input
    ${input}
    //utility
    ${utilityStyle}
    // list style
    ${listStyle()}
`

export default GlobalStyle
