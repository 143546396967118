import moment from 'moment'

/**
 * @see period example: { open: '00:00', close: '00:00' }
 *
 * @param {{open: string, close: string}} period
 * @returns
 */

function openPeriod(period) {
    if (!period || !period.open || !period.close) {
        return {
            open: false,
            time: ''
        }
    }

    return {
        open: moment().isBetween(moment(period.open, 'HH:mm'), moment(period.close, 'HH:mm')),
        time: period.open + ' - ' + period.close
    }
}

export default openPeriod
