import React, { CSSProperties } from 'react'
import { StepperWrapper, StepperWrapperScroll } from './Stepper.styled'

export interface StepperWrapperComponentProps {
    wrapperRef?: React.MutableRefObject<HTMLDivElement>
    scrollRef?: React.MutableRefObject<HTMLDivElement>
    children: React.ReactNode
    style?: CSSProperties
}

function StepperWrapperComponent({ wrapperRef, scrollRef, children, ...props }: Readonly<StepperWrapperComponentProps>) {
    return (
        <StepperWrapperScroll className='stepper-variable' ref={wrapperRef} {...props}>
            <StepperWrapper ref={scrollRef}>{children}</StepperWrapper>
        </StepperWrapperScroll>
    )
}

export default StepperWrapperComponent
