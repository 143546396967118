import Slider from 'react-slick';
import IconConfigWithTheme from 'components/common/icon-config-with-theme';
import { useNavigate } from 'react-router-dom';
import { useServiceData } from './homeServiceData';
import { HomeServiceBox, HomeServiceWrapper } from './Home.styled';

const homeServiceAliveSlideSetting = {
    arrows: false,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1680,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: false,
                dots: true,
            },
        },
        {
            breakpoint: 1080,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: false,
                dots: true,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                centerMode: true,
                dots: true,
            },
        },
    ],
};

function HomeServiceAlive() {
    const navigate = useNavigate();
    const serviceData = useServiceData(true);
    //

    const onClickCard = (url: string) => {
        navigate(url);
    };

    return (
        <HomeServiceWrapper>
            <div className='home-service-list'>
                <Slider {...homeServiceAliveSlideSetting}>
                    {serviceData.map((item, index) => (
                        <div data-test-id={item.serviceId} className='h-100' key={index}>
                            <HomeServiceBox backgroundColor={item.backgroundColor}>
                                <div className='home-service-box-container h-100' onClick={() => onClickCard(item.redirectUrl)}>
                                    <div className='home-service-box-title' data-test-id='title'>
                                        {item.titleIcon.type === 'icon' ? (
                                            <IconConfigWithTheme
                                                pageName={item.titleIcon.pageName as string}
                                                iconName={item.titleIcon.iconName as string}
                                            />
                                        ) : (
                                            <img {...item.titleIcon.imageOptions} />
                                        )}{' '}
                                        {item.title}
                                    </div>
                                    <div className='home-service-box-content'>
                                        <div className='home-service-box-detail'>
                                            {item.detail}
                                            <div className='-text-subDetail'>{item.subDetail}</div>
                                        </div>
                                        <div className='home-service-box-img'>
                                            <img loading='eager' src={item.imgUrl} alt='' />
                                        </div>
                                    </div>
                                    {item.footer && (
                                        <div className='home-service-box-goto'>
                                            <span>{item.footer}</span>&nbsp;
                                        </div>
                                    )}
                                </div>
                            </HomeServiceBox>
                        </div>
                    ))}
                </Slider>
            </div>
        </HomeServiceWrapper>
    );
}

export default HomeServiceAlive;
