import styled from '@emotion/styled'
import { cryptojs, getFullName } from 'utils'
import { EXPCollapse } from 'components/common/exp-collapse'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useAuth from 'hooks/useAuth'
import IconConfigWithTheme from 'components/common/icon-config-with-theme'

function SidebarProfile({ hasInProgress }) {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { auth, profile } = useAuth()
    const [showMenu, setShowMenu] = useState(false)

    const getProfilePhoto = () => {
        if (!profile.displayName && !profile.userImgUrl) return <IconConfigWithTheme pageName='sidebar_profile' iconName='display_name' />

        if (profile.userImgUrl) {
            return <img src={profile.userImgUrl} alt='' />
        }

        return <div className='first-letter'>{profile.displayName.substring(0, 1)?.toUpperCase()}</div>
    }
    const onLogin = (e) => {
        e.preventDefault()

        if (window.location.origin.includes('fastcare.co')) {
            const to = { path: '/user/plugin', data: { partner: '18', autoLogin: 0 } }
            return navigate('/user/line?to=' + cryptojs.encode(to))
        }
        if (window.location.origin.includes('yaphrom')) {
            const to = { path: '/user/plugin', data: { partner: '15', autoLogin: 0 } }
            return navigate('/user/line?to=' + cryptojs.encode(to))
        }

        return navigate('/user/line')
    }

    if (!auth.isAuth) {
        return (
            <Style hasInProgress={hasInProgress}>
                <div className='profile'>
                    <div className='photo'>
                        <IconConfigWithTheme pageName='sidebar_profile' iconName='display_name' />
                    </div>
                    <div className='name' data-test-id='profileName'>
                        <div>{t('sidebar:hi')}</div>
                        <a href='/login' className='fw-400 text-white' onClick={onLogin}>
                            {t('sidebar:login_or_signup')}
                        </a>
                    </div>
                </div>
            </Style>
        )
    }

    return (
        <Style hasInProgress={hasInProgress}>
            <div className='profile' onClick={() => setShowMenu(!showMenu)}>
                <div className='photo'>{getProfilePhoto()}</div>
                <div className='name' data-test-id='profileName'>
                    {getFullName(profile.displayName)}
                </div>
                <IconConfigWithTheme
                    pageName='sidebar_profile'
                    iconName={`${showMenu ? 'show_menu' : 'not_show_menu'}`}
                    className='ml-auto'
                />
            </div>
            <ProfileMenu role={profile.roleAuthority} show={showMenu} />
        </Style>
    )
}

function ProfileMenu({ show, role }) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { userLogout } = useAuth()

    const onLogout = () => {
        userLogout()
        setTimeout(() => {
            if (window.location.origin.includes('fastcare.co')) {
                const to = { path: '/user/plugin', data: { partner: '18', autoLogin: 0 } }
                window.location.replace('/user/line?to=' + cryptojs.encode(to))
            } else if (window.location.origin.includes('yaphrom')) {
                const to = { path: '/user/plugin', data: { partner: '15', autoLogin: 0 } }
                window.location.replace('/user/line?to=' + cryptojs.encode(to))
            } else {
                navigate('/user/line')
            }
        }, 350)
    }

    if (!role) {
        return (
            <EXPCollapse show={show}>
                <ul className='-menu'>
                    <li>
                        <div className='-list' onClick={onLogout}>
                            <IconConfigWithTheme pageName='sidebar_profile' iconName='log_out' />

                            <div>{t('sidebar:profile_dropdown.logout')}</div>
                        </div>
                    </li>
                </ul>
            </EXPCollapse>
        )
    }
    if (role === 'ROLE_WRITER') {
        return (
            <EXPCollapse show={show}>
                <ul className='menu'>
                    <li>
                        <div className='list' onClick={onLogout}>
                            <IconConfigWithTheme pageName='sidebar_profile' iconName='log_out' />

                            <div>{t('sidebar:profile_dropdown.logout')}</div>
                        </div>
                    </li>
                </ul>
            </EXPCollapse>
        )
    }
    return (
        <EXPCollapse show={show}>
            <ul className='-menu'>
                <li>
                    <a href='/user/account' className='-list'>
                        <IconConfigWithTheme pageName='sidebar_profile' iconName='account_setting' />

                        <div>{t('sidebar:profile_dropdown.account_setting')}</div>
                    </a>
                </li>
                <li>
                    <div className='-list' onClick={onLogout}>
                        <IconConfigWithTheme pageName='sidebar_profile' iconName='log_out' />

                        <div>{t('sidebar:profile_dropdown.logout')}</div>
                    </div>
                </li>
            </ul>
        </EXPCollapse>
    )
}

export default SidebarProfile

const Style = styled.div`
    label: sidebar-profile;

    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: ${(props) => (props.hasInProgress ? 'block' : 'none')};
        background-color: transparent;
    }

    .profile {
        display: flex;
        align-items: center;
        font-weight: 600;
        margin: 1.5rem 0;

        .photo {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
            background-color: var(--second-color);

            img {
                object-fit: cover;
            }
        }

        .name {
            margin-left: 10px;
        }
    }
    .-menu {
        list-style: none;
        .-list {
            display: grid;
            grid-template-columns: 40px auto;
            grid-gap: 10px;
            color: var(--white);
            align-items: center;
            padding: 10px 0px;
            .-img {
                width: 30px;
                height: 30px;
            }
        }
    }
`
