// import
import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { breakpoint, extendesClassname } from 'utils'
import { useNavigate } from 'react-router-dom'
import IconConfigWithTheme from '../icon-config-with-theme'
import useThemeWithPartner from 'hooks/useThemeWithPartner'

const Style = styled.div`
    label: breadcrumbs;

    position: relative;
    display: flex;
    width: 100%;
    height: var(--breadcrumbs-height);
    align-items: center;
    color: var(--white);
    padding: 0 60px;
    z-index: var(--breadcrumbs-zindex);
    background-color: var(--breadcrumbs-background-color);

    ${breakpoint('LG')} {
        padding: 0 40px;
    }
    ${breakpoint('MD')} {
        padding: 0 20px;
    }

    ${breakpoint('XS')} {
        padding: 0 10px;
        &::after {
            content: '';
            position: absolute;
            top: 96%;
            left: 0;
            right: 0;
            height: 40px;
            z-index: -1;
            background-color: var(--primary-color);
        }
    }

    .breadcrumbs {
        &-back {
            display: none;
            align-items: center;
            width: 2rem;
            height: 2rem;
            font-size: 2rem;
            margin-right: 0.5rem;

            ${breakpoint('XS')} {
                display: flex;
                font-size: 1.525rem;
            }
        }
        &-icon {
            font-size: 2.5rem;
            margin-right: 0.5rem;
            color: var(--breadcrumbs-icon-color);

            ${breakpoint('XS')} {
                font-size: 1.525rem;
            }
        }
        &-title {
            font-size: 1.2rem;
            font-weight: 500;
            word-break: break-all;

            ${breakpoint('XS')} {
                font-size: 1rem;
            }
        }
        &-link-list {
            display: flex;
            padding: 0;
            margin-bottom: 0;
            list-style: none;

            ${breakpoint('XS')} {
                display: none;
            }

            li + li {
                position: relative;
                padding-left: 0.5rem;

                &::before {
                    content: '';
                    display: inline-block;
                    width: 0.5rem;
                    height: 0.5rem;
                    border-top: 2px solid var(--text-normal-7);
                    border-right: 2px solid var(--text-normal-7);
                    margin-right: 0.5rem;
                    transform: rotate(45deg);
                }
            }
            a {
                color: var(--white);
            }
        }
    }
`

/**
 * @param {{
 * title:string,
 * icon: React.ReactNode,
 * linkList?: array,
 * size?: 'full'|'mobile'| 'desktop',
 * showBackIcon?: boolean | true,
 * onBack?: func,
 * className?: string,
 * children?: element
 * }} props
 */
function Breadcrumbs({ title, icon, showBackIcon, onBack, linkList, size, className, children, ...props }) {
    const navigate = useNavigate()
    const theme = useThemeWithPartner()

    const onGoback = () => {
        if (onBack) onBack()
        else navigate(-1)
    }
    return (
        <Style id='breadcrumbs' {...extendesClassname(['breadcrumbs', Breadcrumbs.sizes[size], className])} {...props}>
            {theme.name !== 'workplaze' && showBackIcon && (
                <div className='breadcrumbs-back' onClick={onGoback}>
                    <IconConfigWithTheme pageName='breadcrumbs' iconName='reverse' />
                </div>
            )}
            {icon && <div className='breadcrumbs-icon'>{icon}</div>}
            <div className='breadcrumbs-container'>
                {linkList.length > 0 && (
                    <ol className='breadcrumbs-link-list'>
                        {linkList.map((e, i) => (
                            <li key={i}>
                                <a href={e.link}>{e.title}</a>
                            </li>
                        ))}
                    </ol>
                )}
                <div className='breadcrumbs-title'>{title}</div>
            </div>
            {children}
        </Style>
    )
}

Breadcrumbs.sizes = {
    full: 'FULL_SIZE',
    mobile: 'ONLY_MOBILE',
    desktop: 'ONLY_DESKTOP'
}
Breadcrumbs.defaultProps = {
    linkList: [],
    size: 'full',
    showBackIcon: true,
    onBack: null
}

Breadcrumbs.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    size: PropTypes.oneOf(['full', 'mobile', 'desktop']),
    linkList: PropTypes.array,
    /** show in mobile size*/
    showBackIcon: PropTypes.bool,
    onBack: PropTypes.func,
    className: PropTypes.string,
    children: PropTypes.node
}

export default Breadcrumbs
