import React from 'react'
import styled from '@emotion/styled'

function HamburgerIcon({ show = false, onHandle = () => null }) {
    return (
        <Style className={show ? 'show' : 'closed'} onClick={onHandle}>
            <span>icon</span>
            <span>icon</span>
        </Style>
    )
}

export default HamburgerIcon

const Style = styled.div`
    label: hamburger-icon;

    position: absolute;
    top: calc((var(--navbar-height) - 26px) / 2);
    right: 0;
    width: 26px;
    height: 26px;
    font-size: 0;
    overflow: hidden;
    transform: translateX(-14px);

    span {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        &::before,
        &::after {
            content: '';
            position: absolute;
            top: calc(40% - 1px);
            left: 0;
            width: 80%;
            height: 2px;
            border-radius: 2px;
            background-color: var(--primary-color);
            transition: all 0.5s;
            overflow: hidden;
        }
        &:first-of-type {
            &::before {
                transform: rotate(-45deg);
            }

            &::after {
                top: 0px;
                transform: translateX(-50px);
            }
        }

        &:last-of-type {
            &::before {
                top: calc(80% - 2px);
                transform: translateX(50px);
            }
            &::after {
                transform: rotate(45deg);
            }
        }
    }

    &.closed {
        transform: translateX(${14 + 26}px);
        span {
            &:first-of-type {
                &::before {
                    transform: rotate(0);
                }

                &::after {
                    transform: translateX(0);
                }
            }

            &:last-of-type {
                &::before {
                    transform: translateX(0);
                }
                &::after {
                    transform: rotate(0);
                }
            }
        }
    }
`
