import React from 'react'
import { ISINAPPWEBVIEWBROWSER } from 'constants/default-config'
import useThemeWithPartner from 'hooks/useThemeWithPartner'
import { browserName, isMobile } from 'react-device-detect'

export default function WorkplazeThemeHideElement({ children }) {
    const theme = useThemeWithPartner()

    if (theme.name === 'workplaze' && isMobile && ISINAPPWEBVIEWBROWSER.some((e) => e === browserName)) return null

    return children
}
