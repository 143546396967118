import styled from '@emotion/styled/macro'
import React from 'react'
import { breakpoint, extendesClassname } from 'utils'

const StyleFormInput = styled.div`
    label: form-input;

    position: relative;
    width: 100%;
    max-width: ${(props) => props.maxWidth || 'var(--form-input-panel-max-width)'};
    margin: 0 auto;

    ${breakpoint('XS')} {
        max-width: unset;
    }
`

/**
 *
 * @param {{maxWidth?: string} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns
 */
function FormInput({ children, maxWidth, ...props }) {
    return (
        <StyleFormInput maxWidth={maxWidth} {...props} {...extendesClassname(['form-input-panel', props.className])}>
            {children}
        </StyleFormInput>
    )
}

export default FormInput
