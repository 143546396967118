import styled from '@emotion/styled'

export const DatetimeBox = styled.div<{ active?: boolean }>`
    width: 95%;
    text-align: center;
    border-radius: 4px;
    border: 1px solid var(--second-color);
    padding-top: 0.275rem;
    padding-bottom: 0.275rem;
    color: ${(props) => (props.active ? 'var(--white)' : 'var(--text-normal-1)')};
    background-color: ${(props) => (props.active ? 'var(--second-color)' : 'var(--white)')};
    cursor: pointer;
`
