import { PopupUtils } from './popup-utils';
import { PopupWrapper } from './popup-wrapper';
import PopupAlert from './popup-alert';
import PopupJsx from './popup-jsx';
import PopupSimpleAlert from './popup-simple-alert';

const EXPPopupJs = {
    register: (ref) => PopupUtils.register(ref),
    close: () => PopupUtils.closeAll(),
    Init: PopupWrapper,
    alert: PopupAlert,
    Jsx: PopupJsx,
    simple: PopupSimpleAlert,
};

export default EXPPopupJs;
