import { DEFAULT_LATLNG } from 'constants/default-config';

const options = {
    enableHighAccuracy: true,
    timeout: 50000,
    maximumAge: 0,
};

function logError(error) {
    switch (error.code) {
        case error.PERMISSION_DENIED:
            return 'User denied the request for Geolocation.';
        case error.POSITION_UNAVAILABLE:
            return 'Location information is unavailable.';
        case error.TIMEOUT:
            return 'The request to get user location timed out.';
        case error.UNKNOWN_ERROR:
            return 'An unknown error occurred.';
        default:
            return 'An unknown error';
    }
}

/**
 * @see if can not get current location return latitude= 13.765065, longitude= 100.538285
 * @param {boolean} continueAfterTimeout default= true, when time out if can get current location will reload
 * @returns {Promise<{ isAllowLocation: boolean, latitude: number, longitude: number }>}
 */

function getLocation(continueAfterTimeout = true) {
    return new Promise((resolve) => {
        let hasAllowAfterTimeout = false;
        let result = { isAllowLocation: false, latitude: DEFAULT_LATLNG.latitude, longitude: DEFAULT_LATLNG.longitude };

        const timer = setTimeout(() => {
            console.warn('Time out to allow location.');
            hasAllowAfterTimeout = true;
            resolve(result);
        }, 15000);

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (res) => {
                    result.isAllowLocation = true;
                    result.latitude = res.coords.latitude;
                    result.longitude = res.coords.longitude;

                    if (hasAllowAfterTimeout && continueAfterTimeout) window.location.reload();

                    clearTimeout(timer);
                    resolve(result);
                },
                (error) => {
                    console.log('error:', error);
                    console.warn(logError(error));
                    clearTimeout(timer);
                    resolve(result);
                },
                options
            );
        } else {
            console.warn('Geolocation is not supported by this browser.');
            clearTimeout(timer);
            resolve(result);
        }
    });
}

const locationjs = {
    getCurrentLocation: getLocation,
};

export default locationjs;
