import { POST } from 'api';
import { useEffect, useState } from 'react';
import ArticleBox from 'components/elements/article/article-box';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { HomeArticleWrapper } from './Home.styled';

function HomeArticle() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [articleList, setArticle_list] = useState<any[]>([]);

    useEffect(() => {
        let isUnmout = false;
        const getArticle = async () => {
            try {
                let result = await POST('/cms/getArticle', {
                    page: 1,
                    articleId: 0,
                    tagId: [],
                    // tagName: []
                });
                if (!isUnmout) setArticle_list(result.articles?.slice(0, 4));
            } catch (error) {
                console.error('cms/getArticle error: ', error);
            }
        };
        getArticle();

        return () => {
            isUnmout = true;
        };
    }, []);

    return (
        <div>
            {articleList?.length > 0 && (
                <HomeArticleWrapper>
                    <div className='home-article-title' data-test-id='title'>
                        {t('home:articles')}
                    </div>
                    <div className='home-article-list'>
                        {articleList.map((e, i) => {
                            const extraPath = e.seo?.urlParameter ? '/' + e.seo?.urlParameter : '';

                            return (
                                <ArticleBox
                                    key={i}
                                    data={e}
                                    path={'/article/detail/' + e.id + extraPath}
                                    onClick={() => navigate('/article/detail/' + e.id + extraPath)}
                                />
                            );
                        })}
                    </div>
                    <div className='text-center'>
                        <a
                            className='theme-btn theme-btn-second w-100 maw-300 text-decoration-none'
                            data-test-id='viewAllArticle'
                            href='/article'
                        >
                            {t('home:see_all_articles')}
                        </a>
                        {/* <Button
                                variant='second'
                                className='w-100 maw-300'
                                onClick={() => navigate('/article')}
                                data-test-id='viewAllArticle'
                            >
                              {t('home:see_all_articles')}
                            </Button> */}
                    </div>
                </HomeArticleWrapper>
            )}
        </div>
    );
}

export default HomeArticle;
