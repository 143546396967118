import React, { useState } from 'react'
import { breakpoint } from 'utils'
import styled from '@emotion/styled'
import HamburgerIcon from './hamburger-icon'
import SideBarContent from './sidebar-content'
import SideBarOverlay from './sidebar-overlay'
import SidebarHeader from './sidebar-header'

const Style = styled.div`
    label: side-bar;

    position: fixed;
    top: 0;
    bottom: 0;
    right: 100%;
    display: none;
    width: 450px;
    height: 100vh;
    color: var(--white);
    z-index: var(--sidebar-zindex);
    background-color: var(--sidebar-background-color);
    transform: translateX(450px);
    transition: transform 0.5s ease-in-out;
    overflow: visible;

    ${breakpoint('LG')} {
        display: block;
    }

    ${breakpoint('XS')} {
        width: 100vw;
        transform: translateX(100vw);
    }

    &.closed {
        position: absolute;
        height: var(--body-height);
        transform: translateX(0);
        transition: height 0.8s ease-in-out, transform 0.5s ease-in-out;
        opacity: 1;
    }
`

function Sidebar({ hasInProgress }) {
    const [showMenu, setShowMenu] = useState(false)

    return (
        <>
            <Style className={`sidebar ${showMenu ? 'show' : 'closed'}`}>
                <HamburgerIcon show={showMenu} onHandle={() => setShowMenu(!showMenu)} />
                <SidebarHeader />
                <SideBarContent hasInProgress={hasInProgress} />
            </Style>
            <SideBarOverlay show={showMenu} onClose={() => setShowMenu(!showMenu)} />
        </>
    )
}

export default Sidebar
