/**
 *
 * @param {string[]} classList
 * @returns
 */

export function extendesClassname(classList = []) {
    let className = []

    if (!Array.isArray(classList) || classList.length < 1) return null

    classList.forEach((value) => {
        if (value) className.push(value)
    })

    if (className.length <= 0) return null

    return { className: className.join(' ') }
}

/**
 *
 * @param {Object} col
 * @returns {String[]}
 */
export function getColClass(col = {}) {
    let result = []

    Object.keys(col).forEach((key) => {
        if (col[key] && key === 'xs') return result.push(`col-${col[key]}`)
        if (col[key]) return result.push(`col-${key}-${col[key]}`)
    })

    return result
}
