import LOCALE_TH from './locale/th'
import LOCALE_EN from './locale/en'

/**
 *
 * @param {Array} data
 * @param {Number} rowSize
 * @returns {Array<data>}
 */
function splitIntoRows(data = [], rowSize = 1) {
    const result = []
    let _rowSize = rowSize

    for (let i = 0; i < data.length; i += _rowSize) {
        result.push(data.slice(i, i + _rowSize))
    }

    return result
}

function runArrays(start, end, last = false, next = false) {
    const result = []

    for (let i = start; i <= end; i++) {
        result.push({
            label: i,
            last: last,
            next: next
        })
    }

    return result
}

function getDecadeBetween(year = 10) {
    const decade = 10
    const decadeStart = year - (year % decade)
    const decadeEnd = decadeStart + decade

    return {
        start: decadeStart,
        end: decadeEnd - 1
    }
}

function zeroPad(value, length = 2) {
    const padLength = length && length > 1 ? length : 2
    const strValue = String(value)

    if (padLength === 0 || strValue.length > padLength) return strValue

    const paddedNo = padLength - strValue.length
    const pad = Array(paddedNo).fill('0').join('')

    return pad + strValue
}

const isValidDate = (d) => {
    return d instanceof Date && !isNaN(d)
}

const LOCALE = {
    th: LOCALE_TH,
    en: LOCALE_EN
}

/**
 *
 * @param {Date} date
 * @param {String} format
 * @returns
 */
function dateFormat(date = null, format = '', local = 'th') {
    if (!isValidDate(date)) return ''

    const dateList = {
        year: date.getFullYear(),
        month: date.getMonth(),
        day: date.getDate()
    }
    const formatList = {
        YYYY: dateList.year.toString(),
        YY: dateList.year.toString().substring(2, 4),
        M: (dateList.month + 1).toString(),
        MM: zeroPad(dateList.month + 1),
        MMM: LOCALE[local.toLowerCase()].monthsShort[dateList.month],
        MMMM: LOCALE[local.toLowerCase()].months[dateList.month],
        D: dateList.day.toString(),
        DD: zeroPad(dateList.day)
    }

    if (!format) return null

    let _format = format

    const formatArray = _format.split(/[-\/ ]/) // for get format

    let result = _format

    formatArray.forEach((f) => {
        result = result.replace(f, formatList[f])
    })

    return result
}

/**
 *
 * @param {string[]} classList
 * @returns
 */

function extendesClassname(classList = []) {
    let className = []

    if (!Array.isArray(classList) || classList.length < 1) return null

    classList.forEach((value) => {
        if (value && typeof value === 'string') className.push(value)
    })

    if (className.length <= 0) return null

    return { className: className.join(' ') }
}

export { splitIntoRows, runArrays, getDecadeBetween, dateFormat, extendesClassname }
