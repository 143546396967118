import styled from '@emotion/styled/macro'
import React from 'react'
import { breakpoint, extendesClassname } from 'utils'

const StyleFormPanel = styled.div`
    label: form-panel;

    position: relative;
    width: 96%;
    max-width: 900px;
    min-height: var(--form-panel-min-height);
    padding: var(--form-panel-padding-y) var(--form-panel-padding-x);
    margin: auto auto var(--form-panel-margin-bottom) auto;
    border-radius: var(--panel-border-radius);
    box-shadow: 0 0 10px #0000001a;

    /* border: 1px solid #ff3300; // ! test */

    ${breakpoint('XS')} {
        width: 100%;
        box-shadow: none;

        &:not(.form-footer-panel-inside) {
            min-height: unset;
        }
    }

    /* for control form-footer-panel */
    .form-footer-panel {
        /* form-footer-panel sticky */
        ${breakpoint('XS', 'min')} {
            &.form-footer-panel-sticky .form-footer-panel-button {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }
        /* form-footer-panel inside */
        &:not(.form-footer-panel-sticky) .form-footer-panel-button {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
        }

        &.form-footer-panel-double-button .form-footer-panel-button {
            ${breakpoint('XS', 'min')} {
                max-width: var(--form-input-panel-max-width);
                left: calc(50% - (var(--form-input-panel-max-width) / 2));
                padding: 0;
            }
        }
    }
`

/**
 * @version 1.0.2 add parameter "numberOfButtons"
 * @version 1.0.1 create
 * @see default of isFooterOutside = false
 * @param {{
 * children?: React.ReactNode
 * } & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns
 */
function FormPanel({ children, ...props }) {
    const elementRef = React.useRef()

    const isFormFooterInside = React.useMemo(() => {
        let _isFormFooterInside = []

        if (elementRef.current) {
            _isFormFooterInside = elementRef.current.querySelectorAll('.form-footer-panel')
        }

        return _isFormFooterInside.length > 0
    }, [elementRef.current])

    return (
        <StyleFormPanel
            {...props}
            {...extendesClassname(['form-panel', isFormFooterInside ? 'form-footer-panel-inside' : '', props.className])}
            ref={elementRef}
        >
            {children}
        </StyleFormPanel>
    )
}

export default FormPanel
