import React from 'react'
import { Global } from '@emotion/react'
import useAuth from 'hooks/useAuth'
import { partnerTheme } from 'styles/theme'

const ThemeWithPartnerContext = React.createContext({})

const ThemeWithPartnerConsumer = ThemeWithPartnerContext.Consumer

function ThemeWithPartnerProvider({ children }) {
    const { auth, profile } = useAuth()
    const [theme, setTheme] = React.useState('default')
    //

    const onChangeTheme = React.useCallback(() => {
        return setTheme(theme)
    }, [])

    React.useEffect(() => {
        // init
        const getThemeWithPartner = () => {
            let _theme = 'default'

            if (auth.isAuth) {
                _theme = profile.theme
            } else if (window.location.origin.includes('fastcare.co')) {
                _theme = 'fastcare'
            } else if (window.location.origin.includes('yaphrom')) {
                _theme = 'yaphrom'
            } else if (window.location.pathname === '/line/oauth2callback') {
                _theme = 'default'
            }

            if (theme !== _theme) setTheme(_theme)
        }

        getThemeWithPartner()
    }, [profile.theme, JSON.stringify(auth)])

    const values = React.useMemo(() => {
        const _theme = partnerTheme[theme]
        const HTMLElement = document.querySelector('html')

        HTMLElement.setAttribute('theme', theme)

        return {
            name: theme,
            css: _theme,
            setTheme: onChangeTheme
        }
    }, [theme, setTheme])

    return (
        <ThemeWithPartnerContext.Provider value={values}>
            <Global styles={values.css} />
            {children}
        </ThemeWithPartnerContext.Provider>
    )
}

export { ThemeWithPartnerProvider, ThemeWithPartnerContext, ThemeWithPartnerConsumer }
export default ThemeWithPartnerProvider
