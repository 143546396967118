import React from 'react'
import styled from '@emotion/styled'
import { breakpoint, paramsjs } from 'utils'
import { useNavigate } from 'react-router-dom'
import { LOGO_IMAGE_WITH_THEME } from 'constants/images-config'
import { useTranslation } from 'react-i18next'
import useThemeWithPartner from 'hooks/useThemeWithPartner'

const Style = styled.div`
    label: nav-logo;

    position: relative;
    width: 120px;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    ${breakpoint('LG')} {
        position: absolute;
        left: calc(50% - 60px);
        margin: 0 auto;
        height: 30px;
        z-index: 0;
    }

    .nav-logo-image {
        object-fit: contain;
        object-position: center;
    }
`

function NavLogo() {
    const navigate = useNavigate()
    const { i18n } = useTranslation()
    const theme = useThemeWithPartner()
    //
    const onGoHome = (e) => {
        e.preventDefault()

        if (theme.name === 'betterperiod') return

        const params = paramsjs.query()?.voucher ? '?voucher=' + paramsjs.query()?.voucher : ''
        navigate('/home' + params)
    }

    return (
        <Style className='nav-logo'>
            <a href='/home' onClick={onGoHome}>
                <img src={LOGO_IMAGE_WITH_THEME[theme.name][i18n.language.toLowerCase()]} alt={theme.name} className='nav-logo-image' />
            </a>
        </Style>
    )
}

export default NavLogo
