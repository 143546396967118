import styled from '@emotion/styled'

export const StepperWrapperScroll = styled.div`
    overflow-x: auto;
    /* padding: 4px; */

    &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 0;
    }
    &::-webkit-scrollbar-thumb {
        background: #cccccc;
        border-radius: 4px;
    }
`
export const StepperWrapper = styled.div`
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    width: max-content;
    text-align: initial;
    line-height: 1.5715;
`
export const StepperItem = styled.div<{ percent: number; width: number }>`
    flex: none;
    width: ${({ width }) => `${width}px`};
    position: relative;
    display: inline-block;
    overflow: hidden;
    vertical-align: top;
    padding-bottom: 4px;
    padding-left: 4px;
    padding-top: 4px;
    white-space: nowrap;
    overflow: visible;
    &:last-child {
        flex: none;
        .steps-item-tail {
            display: none;
        }
    }
    .steps-item-container {
        position: relative;
    }
    .steps-item-tail {
        position: absolute;
        top: calc((var(--steps-item-icon-size-active) / 2) - (var(--steps-item-tail-heght) / 2));
        left: 0;
        width: 100%;
        padding: 0;
        margin-left: ${({ width }) => `calc(${width}px / 3)`};
        font-size: 0;
        &::after {
            display: inline-block;
            width: 100%;
            height: var(--steps-item-tail-heght);
            background: rgba(0, 0, 0, 0.06);
            border-radius: 1px;
            transition: background 0.3s;
            background-color: var(--steps-item-tail-color);
            content: '';
        }
        &::before {
            position: absolute;
            top: 0;
            bottom: 0;
            display: inline-block;
            width: ${({ percent }) => `${percent}%`};
            height: var(--steps-item-tail-heght);
            background: rgba(0, 0, 0, 0.06);
            border-radius: 1px;
            transition: background 0.3s;
            background-color: var(--steps-item-tail-color-active);
            content: '';
        }
    }

    .steps-item-icon {
        position: relative;
        display: block;
        width: var(--steps-item-icon-size-active);
        height: var(--steps-item-icon-size-active);
        margin: 0 8px 0 0;
        margin-left: ${({ width }) => `calc((${width}px / 3) - (var(--steps-item-icon-size-active) / 2))`};
        line-height: var(--steps-item-icon-size-active);
        text-align: center;
        transition: background-color 0.3s, border-color 0.3s;
        border: none;
        background-color: transparent;
    }
    .steps-item-icon-default,
    .steps-item-icon-custom {
        position: absolute;
        top: calc(50% - (var(--steps-item-icon-size) / 2));
        left: calc(50% - (var(--steps-item-icon-size) / 2));
        width: var(--steps-item-icon-size);
        height: var(--steps-item-icon-size);
        line-height: 0;
        border-radius: 100%;
        background-color: #fff;
        font-size: var(--steps-item-icon-size);
    }
    .steps-item-icon-default {
        background-color: var(--steps-item-icon-color);
    }

    .steps-item-dot-default {
        position: absolute;
        top: calc(50% - (var(--steps-item-dot-size) / 2));
        left: calc(50% - (var(--steps-item-dot-size) / 2));
        width: var(--steps-item-dot-size);
        height: var(--steps-item-dot-size);
        border-radius: 100%;
        background-color: var(--steps-item-dot-color);
    }

    .steps-item-content {
        display: block;
        width: ${({ width }) => `calc(${width}px / 1.5)`};
        margin-top: 4px;
        text-align: center;
        color: var(--steps-item-content-color);
    }

    .steps-item-title {
        display: inline-block;
    }

    &.steps-item-finish,
    &.steps-item-process {
        .steps-item-icon-default,
        .steps-item-icon-custom {
            top: calc(50% - (var(--steps-item-icon-size-active) / 2));
            left: calc(50% - (var(--steps-item-icon-size-active) / 2));
            width: var(--steps-item-icon-size-active);
            height: var(--steps-item-icon-size-active);
            color: var(--steps-item-icon-color-active);
            background-color: var(--steps-item-icon-color-active);
        }
        .steps-item-icon-custom {
            background-color: #fff;
        }
        .steps-item-dot-default {
            background-color: var(--steps-item-dot-color-active);
        }
        .steps-item-content {
            color: var(--steps-item-content-color-active);
            font-weight: 500;
        }
    }
`
