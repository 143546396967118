/**
 * @see ICON_CONFIG['pageName']['iconName']['theme']
 */
export const ICON_CONFIG = {
    breadcrumbs: {
        reverse: {
            default: 'far fa-arrow-left',
            workplaze: 'fal fa-arrow-to-left',
        },
    },
    calender_js: {
        reverse: {
            default: 'fas fa-chevron-circle-left',
            workplaze: 'fal fa-calendar-plus',
        },
        next: {
            default: 'fas fa-chevron-circle-right',
            workplaze: 'fal fa-chevron-circle-right',
        },
    },
    collapse_complete: {
        show_more: {
            default: 'fas fa-chevron-up',
            workplaze: 'fal fa-chevron-up',
        },
    },
    file_preview: {
        delete: {
            default: 'fas fa-times-circle',
            workplaze: 'fal fa-times-circle',
        },
        file_name_pdf: {
            default: 'far fa-file-pdf',
            workplaze: 'fal fa-file-pdf',
        },
        file_name_other: {
            default: 'fas fa-file-alt',
            workplaze: 'fal fa-file-alt',
        },
        reverse: {
            default: 'fas fa-chevron-left',
            workplaze: 'fal fa-chevron-left',
        },
        next: {
            default: 'fas fa-chevron-right',
            workplaze: 'fal fa-chevron-right',
        },
        zoom_out: {
            default: 'fas fa-minus',
            workplaze: 'fal fa-minus',
        },
        zoom_in: {
            default: 'fas fa-plus',
            workplaze: 'fal fa-plus',
        },
    },
    current_location: {
        location_arrow: {
            default: 'fas fa-location-arrow',
            workplaze: 'fal fa-location-arrow',
        },
    },
    popup: {
        success: {
            default: 'fas fa-check-circle',
            workplaze: 'fal fa-check-circle',
        },
        warning: {
            default: 'fas fa-exclamation-circle',
            workplaze: 'fal fa-exclamation-circle',
        },
        error: {
            default: 'fas fa-times-circle',
            workplaze: 'fal fa-times-circle',
        },
    },
    progress_bar: {
        finish: {
            default: 'fas fa-check-circle',
            workplaze: 'fal fa-check-circle',
        },
    },
    pagination: {
        reverse: {
            default: 'fas fa-chevron-left',
            workplaze: 'fal fa-chevron-left',
        },
        next: {
            default: 'fas fa-chevron-right',
            workplaze: 'fal fa-chevron-right',
        },
        dot: {
            default: 'fas fa-ellipsis-h',
            workplaze: 'fal fa-ellipsis-h',
        },
    },
    calendar_component: {
        reverse: {
            default: 'fas fa-chevron-circle-left',
            workplaze: 'fal fa-calendar-plus',
        },
        next: {
            default: 'fas fa-chevron-circle-right',
            workplaze: 'fal fa-chevron-circle-right',
        },
    },
    time_slote: {
        show_more: {
            default: 'fas fa-chevron-up',
            workplaze: 'fal fa-chevron-up',
        },
    },
    camera_profile_picture: {
        camera: {
            default: 'fas fa-camera',
            workplaze: 'fal fa-camera',
        },
    },
    camera_thai_idcard: {
        camera: {
            default: 'fas fa-camera',
            workplaze: 'fal fa-camera',
        },
        change_camera: {
            default: 'fas fa-sync-alt',
            workplaze: 'fal fa-sync',
        },
    },
    claim_summary_detail: {
        dicount: {
            default: 'fas fa-chevron-up',
            workplaze: 'fal fa-chevron-up',
        },
    },
    dalivery_fee_range: {
        motorcycle: {
            default: 'fas fa-motorcycle',
            workplaze: 'fal fa-motorcycle',
        },
    },
    footer: {
        envelope: {
            default: 'fas fa-envelope',
            workplaze: 'fal fa-envelope',
        },
        phone: {
            default: 'fas fa-phone-alt',
            workplaze: 'fal fa-phone-alt',
        },
        line: {
            default: 'fab fa-line',
            workplaze: 'fab fa-line',
        },
    },
    service_history_card: {
        video_call: {
            default: 'fas fa-video',
            workplaze: 'fal fa-video',
        },
    },
    nav_favorite: {
        heart: {
            default: 'far fa-heart',
            workplaze: 'fal fa-heart',
        },
    },
    nav_profile: {
        profile_menu: {
            default: 'fas fa-chevron-down',
            workplaze: 'fal fa-chevron-down',
        },
        health: {
            default: 'fas fa-heartbeat',
            workplaze: 'fal fa-heartbeat',
        },
        activities: {
            default: 'fas fa-calendar-check',
            workplaze: 'fal fa-calendar-check',
        },
        account_setting: {
            default: 'fas fa-user-cog',
            workplaze: 'fal fa-user-cog',
        },
        favourite_pharmacy: {
            default: 'fas fa-heart',
            workplaze: 'fas fa-heart',
        },
        log_out: {
            default: 'fas fa-sign-out-alt',
            workplaze: 'fal fa-sign-out',
        },
    },
    pharmacy_rating: {
        star: {
            default: 'fas fa-star',
            workplaze: 'fas fa-star',
        },
    },
    pharmacy_quality: {
        star: {
            default: 'fas fa-star',
            workplaze: 'fas fa-star',
        },
    },
    additional_component: {
        smoking: {
            default: 'fas fa-smoking',
            workplaze: 'fal fa-smoking',
        },
        add_details: {
            default: 'fas fa-plus',
            workplaze: 'fal fa-plus',
        },
        beer: {
            default: 'fas fa-beer',
            workplaze: 'fal fa-beer',
        },
    },
    health_component: {
        congenital_disease: {
            default: 'fas fa-file-medical-alt',
            workplaze: 'fal fa-notes-medical',
        },
        frequently_used_drug: {
            default: 'fas fa-prescription-bottle-alt',
            workplaze: 'fal fa-pills',
        },
        drug_allergy: {
            default: 'fas fa-pills',
            workplaze: 'fal fa-pills',
        },
        add_detail: {
            default: 'fas fa-plus',
            workplaze: 'fal fa-plus',
        },
    },
    personal_component: {
        date_of_birth: {
            default: 'far fa-birthday-cake',
            workplaze: 'fal fa-birthday-cake',
        },
        weight: {
            default: 'fas fa-weight',
            workplaze: 'fal fa-weight',
        },
        add_detail: {
            default: 'fas fa-plus',
            workplaze: 'fal fa-plus',
        },
        height: {
            default: 'fas fa-arrows-alt-v',
            workplaze: 'fal fa-arrows-v',
        },
    },
    summary_price_pickup: {
        dicount: {
            default: 'fas fa-chevron-up',
            workplaze: 'fal fa-chevron-up',
        },
    },
    medicine_photo: {
        camera: {
            default: 'fas fa-camera',
            workplaze: 'fal fa-camera',
        },
    },
    Medicine_select: {
        search: {
            default: 'fas fa-search',
            workplaze: 'fal fa-search',
        },
        info: {
            default: 'fas fa-info-circle',
            workplaze: 'fal fa-info-circle',
        },
    },
    symptoms_detail: {
        show_choice: {
            default: 'fas fa-angle-down',
            workplaze: 'fal fa-file-exclamation',
        },
    },
    symptoms_photo: {
        camera: {
            default: 'fas fa-camera',
            workplaze: 'fal fa-camera',
        },
    },
    service_card: {
        telepharmacy: {
            default: 'fas fa-phone-square',
            workplaze: 'fal fa-phone-volume',
        },
        fulfillment: {
            default: 'fas fa-file-medical',
            workplaze: 'fal fa-file-medical',
        },
        telemedicine: {
            default: 'fas fa-phone-square',
            workplaze: 'fal fa-phone-volume',
        },
        physiotherapy: {
            default: 'fas fa-dumbbell',
            workplaze: 'fal fa-dumbbell',
        },
        nutritionist: {
            default: 'fas fa-utensils',
            workplaze: 'fal fa-utensils',
        },
        favourite: {
            default: 'fas fa-heart',
            workplaze: 'fas fa-heart',
        },
        unfavourite: {
            default: 'far fa-heart',
            workplaze: 'fal fa-heart',
        },
        video_call: {
            default: 'fas fa-video',
            workplaze: 'fal fa-video',
        },
    },
    delivery_address: {
        firstname_lastname: {
            default: 'fas fa-map-marked-alt',
            workplaze: 'fal fa-map-marked-alt',
        },
        phone_number: {
            default: 'fas fa-phone-alt',
            workplaze: 'fal fa-phone-alt',
        },
        address: {
            default: 'fas fa-map-marked-alt',
            workplaze: 'fal fa-map-marked-alt',
        },
    },
    confirm_address: {
        close: {
            default: 'fas fa-times',
            workplaze: 'fal fa-times',
        },
        add_new_label: {
            default: 'fas fa-plus',
            workplaze: 'fal fa-plus',
        },
        address_type_home: {
            default: 'fas fa-home',
            workplaze: 'fal fa-home-lg-alt',
        },
        address_type_Official: {
            default: 'far fa-envelope',
            workplaze: 'fal fa-envelope',
        },
        address_type_Office: {
            default: 'far fa-building',
            workplaze: 'fal fa-building',
        },
    },
    darivery_med_address: {
        darivery: {
            default: 'fas fa-truck',
            workplaze: 'fal fa-truck',
        },
        firstname_lastname: {
            default: 'fas fa-map-marked-alt',
            workplaze: 'fal fa-map-marked-alt',
        },
        phone_number: {
            default: 'fas fa-phone-alt',
            workplaze: 'fal fa-phone-alt',
        },
        address: {
            default: 'fas fa-map-marked-alt',
            workplaze: 'fal fa-map-marked-alt',
        },
    },
    darivery_med_pharmacy: {
        collection_at_pharmacy: {
            default: 'fas fa-clinic-medical',
            workplaze: 'fal fa-clinic-medical',
        },
        date_and_time: {
            default: 'fas fa-calendar-check',
            workplaze: 'fal fa-calendar-check',
        },
        pharmacist: {
            default: 'fas fa-user-md',
            workplaze: 'fal fa-user-md',
        },
        phone_number: {
            default: 'fas fa-phone-alt',
            workplaze: 'fal fa-phone-alt',
        },
        pharmacy_address: {
            default: 'fas fa-map-marker-alt',
            workplaze: 'fal fa-map-marker-alt',
        },
    },
    summary_price: {
        dicount: {
            default: 'fas fa-chevron-up',
            workplaze: 'fal fa-chevron-up',
        },
    },
    sidebar_language: {
        show_lang: {
            default: 'fas fa-chevron-up',
            workplaze: 'fal fa-chevron-up',
        },
        not_show_lang: {
            default: 'fas fa-chevron-down',
            workplaze: 'fal fa-chevron-down',
        },
    },
    sidebar_profile: {
        display_name: {
            default: 'far fa-user',
            workplaze: 'fal fa-user',
        },
        show_menu: {
            default: 'fas fa-chevron-up',
            workplaze: 'fal fa-chevron-up',
        },
        not_show_menu: {
            default: 'fas fa-chevron-down',
            workplaze: 'fal fa-chevron-down',
        },
        log_out: {
            default: 'fas fa-sign-out-alt',
            workplaze: 'fal fa-sign-out',
        },
        account_setting: {
            default: 'fas fa-users-cog',
            workplaze: 'fas fa-users-cog',
        },
    },
    tokbox: {
        name: {
            default: 'fas fa-circle',
            workplaze: 'fas fa-circle',
        },
        publish_status: {
            default: 'fas fa-podcast',
            workplaze: 'fal fa-podcast',
        },
        streams_status: {
            default: 'fab fa-chromecast',
            workplaze: 'fab fa-chromecast',
        },
        has_video: {
            default: 'fas fa-video',
            workplaze: 'fal fa-video',
        },
        has_audio: {
            default: 'fas fa-volume-up',
            workplaze: 'fal fa-volume-up',
        },
        publish_audio_unmute: {
            default: 'fas fa-microphone',
            workplaze: 'fal fa-microphone',
        },
        publish_audio_mute: {
            default: 'fas fa-microphone-slash',
            workplaze: 'fal fa-microphone-slash',
        },
        publish_audio_turn_on: {
            default: 'fas fa-video',
            workplaze: 'fal fa-video',
        },
        publish_video_turn_off: {
            default: 'fas fa-video-slash',
            workplaze: 'fal fa-video-slash',
        },
        end_call: {
            default: 'fas fa-phone',
            workplaze: 'fal fa-phone-alt',
        },
    },
    address_form: {
        add_new_label: {
            default: 'fas fa-plus',
            workplaze: 'fal fa-plus',
        },
        address_type_home: {
            default: 'fas fa-home',
            workplaze: 'fal fa-home-lg-alt',
        },
        address_type_Official: {
            default: 'far fa-envelope',
            workplaze: 'fal fa-envelope',
        },
        address_type_Office: {
            default: 'far fa-building',
            workplaze: 'fal fa-building',
        },
    },
    home_method: {
        steps_to_start: {
            default: 'fas fa-comment',
            workplaze: 'fas fa-comment',
        },
    },
    home_service_alive: {
        pt: {
            default: 'fas fa-dumbbell',
            workplaze: 'fal fa-dumbbell',
        },
        telepharmacy: {
            default: 'fas fa-video',
            workplaze: 'fal fa-video',
        },
    },
    home_service: {
        mth: {
            default: 'fas fa-comments',
            workplaze: 'fal fa-comments-alt',
        },
        tlm: {
            default: 'fas fa-comments',
            workplaze: 'fal fa-comments-alt',
        },
        tlmNcd: {
            default: 'fas fa-comments',
            workplaze: 'fal fa-comments-alt',
        },
        pt: {
            default: 'fas fa-dumbbell',
            workplaze: 'fal fa-dumbbell',
        },
        nt: {
            default: 'fas fa-salad',
            workplaze: 'fal fa-salad',
        },
        telemed: {
            default: 'fas fa-comments',
            workplaze: 'fal fa-comments-alt',
        },
        telepharmacy: {
            default: 'fas fa-video',
            workplaze: 'fal fa-video',
        },
        telepharmacyLTC: {
            default: 'fas fa-file-alt',
            workplaze: 'fal fa-file-alt',
        },
        fulfillment: {
            default: 'fas fa-file-alt',
            workplaze: 'fal fa-file-alt',
        },
        nearby: {
            default: 'fas fa-search',
            workplaze: 'fal fa-search',
        },
        fda: {
            default: 'fas fa-search',
            workplaze: 'fal fa-search',
        },
    },
    floating_side: {
        floating: {
            default: 'fad fa-alicorn',
            workplaze: 'fal fa-alicorn',
        },
    },
    landing_login: {
        line: {
            default: 'fab fa-line',
            workplaze: 'fab fa-line',
        },
    },
    question_box: {
        show_answer: {
            default: 'far fa-angle-down',
            workplaze: 'far fa-angle-down',
        },
    },
    service_rating: {
        star: {
            default: 'fas fa-star',
            workplaze: 'fas fa-star',
        },
        reverse: {
            default: 'fas fa-chevron-left',
            workplaze: 'fal fa-chevron-left',
        },
        next: {
            default: 'fas fa-chevron-right',
            workplaze: 'fal fa-chevron-right',
        },
    },
    service_relate: {
        see_more: {
            default: 'fas fa-chevron-right',
            workplaze: 'fal fa-chevron-right',
        },
    },
    add_credit_card_component: {
        payment_failed: {
            default: 'fas fa-exclamation-circle',
            workplaze: 'fal fa-exclamation-circle',
        },
    },
    create_pharmacy_info_window_content: {
        pharmacy_store_name: {
            default: 'fas fa-map-marker-alt',
            workplaze: 'fal fa-map-marker-alt',
        },
        pharmacy_favourite: {
            default: 'fas fa-heart',
            workplaze: 'fas fa-heart',
        },
        pharmacy_unfavourite: {
            default: 'far fa-heart',
            workplaze: 'fal fa-heart',
        },
        star: {
            default: 'fas fa-star',
            workplaze: 'fas fa-star',
        },
    },
    pharmacy_card: {
        pharmacy_address: {
            default: 'fas fa-map-marker-alt',
            workplaze: 'fal fa-map-marker-alt',
        },
        delivery: {
            default: 'fas fa-shipping-fast',
            workplaze: 'fal fa-shipping-fast',
        },
        time: {
            default: 'fas fa-clock',
            workplaze: 'fal fa-clock',
        },
        star: {
            default: 'fas fa-star',
            workplaze: 'fas fa-star',
        },
        favourite: {
            default: 'fas fa-heart',
            workplaze: 'fas fa-heart',
        },
        unfavourite: {
            default: 'far fa-heart',
            workplaze: 'fal fa-heart',
        },
    },
    pharmacy_comment_list: {
        star: {
            default: 'fas fa-star',
            workplaze: 'fas fa-star',
        },
    },
    pharmacy_detail_list: {
        close: {
            default: 'fas fa-times',
            workplaze: 'fal fa-times',
        },
    },
    pharmacy_filter: {
        video_call: {
            default: 'fas fa-video',
            workplaze: 'fal fa-video',
        },
        filter: {
            default: 'fas fa-filter',
            workplaze: 'fal fa-filter',
        },
        close: {
            default: 'fas fa-times',
            workplaze: 'fal fa-times',
        },
        star: {
            default: 'fas fa-star',
            workplaze: 'fas fa-star',
        },
    },
    pharmacy_list: {
        reverse: {
            default: 'fas fa-chevron-left',
            workplaze: 'fal fa-chevron-left',
        },
    },
    pharmacy_service: {
        service: {
            default: 'fas fa-heartbeat',
            workplaze: 'fal fa-heartbeat',
        },
    },
    search_auto_complete: {
        placeholder: {
            default: 'fas fa-map-marker-alt',
            workplaze: 'fal fa-map-marker-alt',
        },
        dropdown: {
            default: 'fas fa-chevron-down',
            workplaze: 'fal fa-chevron-down',
        },
    },
    detail_address_type01: {
        nominee_name: {
            default: 'fas fa-map-marker-alt',
            workplaze: 'fal fa-map-marker-alt',
        },
        nominee_phone_number: {
            default: 'fas fa-phone-alt',
            workplaze: 'fal fa-phone-alt',
        },
        address: {
            default: 'fas fa-map-marker-alt',
            workplaze: 'fal fa-map-marker-alt',
        },
        edit: {
            default: 'fas fa-chevron-right',
            workplaze: 'fal fa-chevron-right',
        },
        add_new_address: {
            default: 'fas fa-plus-circle',
            workplaze: 'fal fa-plus-circle',
        },
        check: {
            default: 'fas fa-check-circle',
            workplaze: 'fal fa-check-circle',
        },
    },
    waiting_question: {
        next: {
            default: 'fas fa-chevron-right',
            workplaze: 'fal fa-chevron-right',
        },
        search: {
            default: 'fas fa-search',
            workplaze: 'fal fa-search',
        },
        note: {
            default: 'fas fa-info-circle',
            workplaze: 'fal fa-info-circle',
        },
    },
    appoinment_comfirm: {
        breadcrumbs: {
            default: 'fas fa-calendar-check',
            workplaze: 'fal fa-calendar-check',
        },
        booking_successful: {
            default: 'fas fa-check-circle',
            workplaze: 'fal fa-check-circle',
        },
        date: {
            default: 'fas fa-calendar',
            workplaze: 'fas fa-calendar',
        },
        time: {
            default: 'fas fa-clock',
            workplaze: 'fal fa-clock',
        },
        type: {
            default: 'fas fa-user-md',
            workplaze: 'fal fa-user-md',
        },
    },
    appoinment_info: {
        breadcrumbs: {
            default: 'fas fa-calendar-check',
            workplaze: 'fal fa-calendar-check',
        },
        type: {
            default: 'fas fa-comments',
            workplaze: 'fal fa-comments-alt',
        },
        video_call: {
            default: 'fas fa-video',
            workplaze: 'fal fa-video',
        },
        reverse: {
            default: 'fas fa-angle-left',
            workplaze: 'fal fa-chevron-left',
        },
    },
    navigation: {
        home: {
            default: 'fas fa-home',
            workplaze: 'fal fa-home-lg-alt',
        },
        phr: {
            default: 'fas fa-heartbeat',
            workplaze: 'fal fa-heartbeat',
        },
        service: {
            default: 'fas fa-calendar-check',
            workplaze: 'fal fa-calendar-check',
        },
    },
    article_detail: {
        views: {
            default: 'far fa-eye',
            workplaze: 'fal fa-eye',
        },
        facebook: {
            default: 'fab fa-facebook-f',
            workplaze: 'fab fa-facebook-f',
        },
        twitter: {
            default: 'fab fa-twitter',
            workplaze: 'fab fa-twitter',
        },
        line: {
            default: 'fab fa-line',
            workplaze: 'fab fa-line',
        },
        envelope: {
            default: 'fas fa-envelope',
            workplaze: 'fal fa-envelope',
        },
        related_articles: {
            default: 'fas fa-book-open',
            workplaze: 'fal fa-book-open',
        },
        hashtag: {
            default: 'fas fa-hashtag',
            workplaze: 'fal fa-hashtag',
        },
    },
    article_edit: {
        views: {
            default: 'far fa-eye',
            workplaze: 'fal fa-eye',
        },
        facebook: {
            default: 'fab fa-facebook-f',
            workplaze: 'fab fa-facebook-f',
        },
        twitter: {
            default: 'fab fa-twitter',
            workplaze: 'fab fa-twitter',
        },
        line: {
            default: 'fab fa-line',
            workplaze: 'fab fa-line',
        },
        envelope: {
            default: 'fas fa-envelope',
            workplaze: 'fal fa-envelope',
        },
        related_articles: {
            default: 'fas fa-book-open',
            workplaze: 'fal fa-book-open',
        },
        hashtag: {
            default: 'fas fa-hashtag',
            workplaze: 'fal fa-hashtag',
        },
    },
    booking: {
        breadcrumbs: {
            default: 'fas fa-stethoscope',
            workplaze: 'fal fa-stethoscope',
        },
    },
    choose_pharmacy_favourite: {
        search: {
            default: 'fas fa-map',
            workplaze: 'fal fa-map',
        },
        next: {
            default: 'fas fa-angle-right',
            workplaze: 'fal fa-search-location',
        },
    },
    choose_pharmacy_store: {
        breadcrumbs: {
            default: 'fas fa-map-marker-alt',
            workplaze: 'fal fa-map-marker-alt',
        },
    },
    claim_not_found: {
        contact: {
            default: 'fas fa-phone-square-alt',
            workplaze: 'fal fa-phone-square-alt',
        },
    },
    claim_pid: {
        breadcrumbs: {
            default: 'fas fa-user',
            workplaze: 'fal fa-user',
        },
    },
    claim_select: {
        breadcrumbs: {
            default: 'fas fa-user',
            workplaze: 'fal fa-user',
        },
    },
    claim: {
        breadcrumbs: {
            default: 'fas fa-user',
            workplaze: 'fal fa-user',
        },
        history: {
            default: 'far fa-history',
            workplaze: 'fal fa-history',
        },
    },
    not_found_404: {
        error_page: {
            default: 'fas fa-exclamation-circle',
            workplaze: 'fal fa-exclamation-circle',
        },
    },
    unauthorized_401: {
        error_page: {
            default: 'fas fa-exclamation-circle',
            workplaze: 'fal fa-exclamation-circle',
        },
    },
    courier_status: {
        breadcrumbs: {
            default: 'fas fa-file-medical-alt',
            workplaze: 'fal fa-file-medical-alt',
        },
    },
    home_alive: {
        breadcrumbs: {
            default: 'fas fa-stethoscope',
            workplaze: 'fal fa-stethoscope',
        },
    },
    landing1_atk: {
        sevice_detail: {
            default: 'fas fa-comments',
            workplaze: 'fal fa-comments-alt',
        },
        dalivery_time: {
            default: 'fas fa-motorcycle',
            workplaze: 'fal fa-motorcycle',
        },
        sevice_rate: {
            default: 'fas fa-pills',
            workplaze: 'fal fa-pills',
        },
        dalivery_rate: {
            default: 'fas fa-motorcycle',
            workplaze: 'fal fa-motorcycle',
        },
        management_rate: {
            default: 'fas fa-hand-holding-heart',
            workplaze: 'fal fa-hand-holding-heart',
        },
    },
    landing5_atk: {
        star: {
            default: 'fas fa-star',
            workplaze: 'fas fa-star',
        },
    },
    landing1_nt: {
        sevice_detail_time: {
            default: 'fas fa-clock',
            workplaze: 'fal fa-clock',
        },
        sevice_detail: {
            default: 'fas fa-comments',
            workplaze: 'fal fa-comments-alt',
        },
        sevice_rate: {
            default: 'fas fa-user-md',
            workplaze: 'fal fa-user-md',
        },
    },
    landing5_nt: {
        star: {
            default: 'fas fa-star',
            workplaze: 'fas fa-star',
        },
    },
    landing1_pt: {
        sevice_detail_time: {
            default: 'fas fa-clock',
            workplaze: 'fal fa-clock',
        },
        sevice_detail: {
            default: 'fas fa-comments',
            workplaze: 'fal fa-comments-alt',
        },
        sevice_rate: {
            default: 'fas fa-user-md',
            workplaze: 'fal fa-user-md',
        },
    },
    landing5_pt: {
        star: {
            default: 'fas fa-star',
            workplaze: 'fas fa-star',
        },
    },
    landing1_tlm: {
        sevice_detail_time: {
            default: 'fas fa-clock',
            workplaze: 'fal fa-clock',
        },
        sevice_detail: {
            default: 'fas fa-comments',
            workplaze: 'fal fa-comments-alt',
        },
        dalivery_time: {
            default: 'fas fa-motorcycle',
            workplaze: 'fal fa-motorcycle',
        },
        sevice_rate: {
            default: 'fas fa-user-md',
            workplaze: 'fal fa-user-md',
        },
        medicine_rate: {
            default: 'fas fa-pills',
            workplaze: 'fal fa-pills',
        },
        dalivery_rate: {
            default: 'fas fa-motorcycle',
            workplaze: 'fal fa-motorcycle',
        },
        management_rate: {
            default: 'fas fa-hand-holding-heart',
            workplaze: 'fal fa-hand-holding-heart',
        },
    },
    landing5_tlm: {
        star: {
            default: 'fas fa-star',
            workplaze: 'fas fa-star',
        },
    },
    landing1_tlp: {
        sevice_detail_1: {
            default: 'fas fa-pills',
            workplaze: 'fal fa-pills',
        },
        sevice_detail_2: {
            default: 'fas fa-comments',
            workplaze: 'fal fa-comments-alt',
        },
        dalivery_time: {
            default: 'fas fa-motorcycle',
            workplaze: 'fal fa-motorcycle',
        },
        medicine_rate: {
            default: 'fas fa-pills',
            workplaze: 'fal fa-pills',
        },
        dalivery_rate: {
            default: 'fas fa-motorcycle',
            workplaze: 'fal fa-motorcycle',
        },
        consult_now: {
            default: 'fas fa-pills',
            workplaze: 'fal fa-pills',
        },
        management_rate: {
            default: 'fas fa-hand-holding-heart',
            workplaze: 'fal fa-hand-holding-heart',
        },
    },
    landing5_tlp: {
        star: {
            default: 'fas fa-star',
            workplaze: 'fas fa-star',
        },
    },
    landing1_tlp_c90: {
        sevice_detail_1: {
            default: 'fas fa-pills',
            workplaze: 'fal fa-pills',
        },
        sevice_detail_2: {
            default: 'fas fa-comments',
            workplaze: 'fal fa-comments-alt',
        },
        dalivery_time: {
            default: 'fas fa-motorcycle',
            workplaze: 'fal fa-motorcycle',
        },
        medicine_rate: {
            default: 'fas fa-pills',
            workplaze: 'fal fa-pills',
        },
        dalivery_rate: {
            default: 'fas fa-motorcycle',
            workplaze: 'fal fa-motorcycle',
        },
        consult_now: {
            default: 'fas fa-pills',
            workplaze: 'fal fa-pills',
        },
        management_rate: {
            default: 'fas fa-hand-holding-heart',
            workplaze: 'fal fa-hand-holding-heart',
        },
    },
    landing5_tlp_c90: {
        star: {
            default: 'fas fa-star',
            workplaze: 'fas fa-star',
        },
    },
    landing1_tlp_consult: {
        sevice_detail_1: {
            default: 'fas fa-clock',
            workplaze: 'fal fa-clock',
        },
        sevice_detail_2: {
            default: 'fas fa-comments',
            workplaze: 'fal fa-comments-alt',
        },
        dalivery_time: {
            default: 'fas fa-motorcycle',
            workplaze: 'fal fa-motorcycle',
        },
        service_rate: {
            default: 'fas fa-pills',
            workplaze: 'fal fa-pills',
        },
        medicine_rate: {
            default: 'fas fa-pills',
            workplaze: 'fal fa-pills',
        },
        dalivery_rate: {
            default: 'fas fa-motorcycle',
            workplaze: 'fal fa-motorcycle',
        },
        management_rate: {
            default: 'fas fa-hand-holding-heart',
            workplaze: 'fal fa-hand-holding-heart',
        },
    },
    landing5_tlp_consult: {
        star: {
            default: 'fas fa-star',
            workplaze: 'fas fa-star',
        },
    },
    landing1_tlp_ltc: {
        sevice_detail_1: {
            default: 'fas fa-pills',
            workplaze: 'fal fa-pills',
        },
        sevice_detail_2: {
            default: 'fas fa-comments',
            workplaze: 'fal fa-comments-alt',
        },
        dalivery_time: {
            default: 'fas fa-motorcycle',
            workplaze: 'fal fa-motorcycle',
        },
        medicine_rate: {
            default: 'fas fa-pills',
            workplaze: 'fal fa-pills',
        },
        dalivery_rate: {
            default: 'fas fa-motorcycle',
            workplaze: 'fal fa-motorcycle',
        },
        management_rate: {
            default: 'fas fa-hand-holding-heart',
            workplaze: 'fal fa-hand-holding-heart',
        },
        consult_now: {
            default: 'fas fa-pills',
            workplaze: 'fal fa-pills',
        },
    },
    landing5_tlp_ltc: {
        star: {
            default: 'fas fa-star',
            workplaze: 'fas fa-star',
        },
    },
    existing_additional_info: {
        breadcrumbs: {
            default: 'fas fa-file-medical',
            workplaze: 'fal fa-file-medical',
        },
        health_related_behaviors: {
            default: 'fas fa-notes-medical',
            workplaze: 'fal fa-clipboard-prescription',
        },
        smoke: {
            default: 'fas fa-smoking',
            workplaze: 'fal fa-smoking',
        },
        beer: {
            default: 'fas fa-beer',
            workplaze: 'fal fa-beer',
        },
    },
    existing_health_info: {
        breadcrumbs: {
            default: 'fas fa-file-medical',
            workplaze: 'fal fa-file-medical',
        },
        heath_record: {
            default: 'fas fa-book-medical',
            workplaze: 'fal fa-book-medical',
        },
    },
    existing_lab_detail: {
        breadcrumbs: {
            default: 'fas fa-heartbeat',
            workplaze: 'fal fa-heartbeat',
        },
        next: {
            default: 'fas fa-chevron-up',
            workplaze: 'fal fa-chevron-up',
        },
    },
    existing_lab: {
        breadcrumbs: {
            default: 'fas fa-heartbeat',
            workplaze: 'fal fa-heartbeat',
        },
        next: {
            default: 'fas fa-chevron-up',
            workplaze: 'fal fa-chevron-up',
        },
        type: {
            default: 'fas fa-circle',
            workplaze: 'fas fa-circle',
        },
    },
    existing_personal_info: {
        breadcrumbs: {
            default: 'fas fa-file-medical',
            workplaze: 'fal fa-file-medical',
        },
        personal_details: {
            default: 'far fa-user',
            workplaze: 'fal fa-user',
        },
    },
    existing: {
        breadcrumbs: {
            default: 'fas fa-heartbeat',
            workplaze: 'fal fa-heartbeat',
        },
    },
    phr_new_additional: {
        breadcrumbs: {
            default: 'fas fa-heartbeat',
            workplaze: 'fal fa-heartbeat',
        },
        smoke: {
            default: 'fas fa-smoking',
            workplaze: 'fal fa-smoking',
        },
        beer: {
            default: 'fas fa-beer',
            workplaze: 'fal fa-beer',
        },
    },
    phr_new_body: {
        breadcrumbs: {
            default: 'fas fa-heartbeat',
            workplaze: 'fal fa-heartbeat',
        },
    },
    phr_new_date: {
        breadcrumbs: {
            default: 'fas fa-heartbeat',
            workplaze: 'fal fa-heartbeat',
        },
    },
    phr_new_disease: {
        breadcrumbs: {
            default: 'fas fa-heartbeat',
            workplaze: 'fal fa-heartbeat',
        },
        search: {
            default: 'fas fa-search',
            workplaze: 'fal fa-search',
        },
        note: {
            default: 'fas fa-info-circle',
            workplaze: 'fal fa-info-circle',
        },
    },
    phr_new_drug_allergy: {
        breadcrumbs: {
            default: 'fas fa-heartbeat',
            workplaze: 'fal fa-heartbeat',
        },
        search: {
            default: 'fas fa-search',
            workplaze: 'fal fa-search',
        },
        note: {
            default: 'fas fa-info-circle',
            workplaze: 'fal fa-info-circle',
        },
        complete: {
            default: 'fas fa-heartbeat',
            workplaze: 'fal fa-heartbeat',
        },
    },
    phr_new_gender: {
        breadcrumbs: {
            default: 'fas fa-heartbeat',
            workplaze: 'fal fa-heartbeat',
        },
    },
    pickup: {
        breadcrumbs: {
            default: 'fas fa-file-alt',
            workplaze: 'fal fa-file-alt',
        },
        video_call: {
            default: 'fas fa-video',
            workplaze: 'fal fa-video',
        },
        gate_way: {
            default: 'fas fa-file-alt',
            workplaze: 'fal fa-file-alt',
        },
        pharmacy_location: {
            default: 'fas fa-map-marker-alt',
            workplaze: 'fal fa-map-marker-alt',
        },
        back: {
            default: 'fas fa-angle-left',
            workplaze: 'fal fa-chevron-left',
        },
        favourite: {
            default: 'fas fa-heart',
            workplaze: 'fas fa-heart',
        },
        unfavourite: {
            default: 'far fa-heart',
            workplaze: 'fal fa-heart',
        },
    },
    profile_face: {
        breadcrumbs: {
            default: 'fas fa-user',
            workplaze: 'fal fa-user',
        },
    },
    thai_id_card: {
        breadcrumbs: {
            default: 'fas fa-user',
            workplaze: 'fal fa-user',
        },
    },
    password_forget: {
        email_sent: {
            default: 'fas fa-check-circle',
            workplaze: 'fal fa-check-circle',
        },
    },
    screening_final: {
        breadcrumbs: {
            default: 'fas fa-heartbeat',
            workplaze: 'fal fa-heartbeat',
        },
    },
    screening_question: {
        breadcrumbs: {
            default: 'fas fa-stethoscope',
            workplaze: 'fal fa-stethoscope',
        },
    },
    location: {
        search: {
            default: 'far fa-search',
            workplaze: 'far fa-search',
        },
    },
    select_address_tlm: {
        breadcrumbs: {
            default: 'fas fa-pills',
            workplaze: 'fal fa-pills',
        },
        delivery: {
            default: 'fas fa-truck',
            workplaze: 'fal fa-truck',
        },
        check: {
            default: 'fas fa-check-circle',
            workplaze: 'fal fa-check-circle',
        },
        pickup: {
            default: 'fas fa-clinic-medical',
            workplaze: 'fal fa-clinic-medical',
        },
        close: {
            default: 'fas fa-times',
            workplaze: 'fal fa-times',
        },
    },
    select_address_tlp: {
        breadcrumbs: {
            default: 'fas fa-pills',
            workplaze: 'fal fa-pills',
        },
        delivery: {
            default: 'fas fa-truck',
            workplaze: 'fal fa-truck',
        },
        check: {
            default: 'fas fa-check-circle',
            workplaze: 'fal fa-check-circle',
        },
        pickup: {
            default: 'fas fa-clinic-medical',
            workplaze: 'fal fa-clinic-medical',
        },
        close: {
            default: 'fas fa-times',
            workplaze: 'fal fa-times',
        },
        video_call: {
            default: 'fas fa-video',
            workplaze: 'fal fa-video',
        },
        voice_call: {
            default: 'fas fa-phone-alt',
            workplaze: 'fal fa-phone-alt',
        },
        chat: {
            default: 'fas fa-comments',
            workplaze: 'fal fa-comments-alt',
        },
        breadcrumbs_tlp: {
            default: 'fas fa-search',
            workplaze: 'fal fa-search',
        },
        breadcrumbs_ful: {
            default: 'fas fa-pills',
            workplaze: 'fal fa-pills',
        },
    },
    wail_to_find: {
        search: {
            default: 'fas fa-search',
            workplaze: 'fal fa-search',
        },
    },
    select_department: {
        breadcrumbs: {
            default: 'fas fa-stethoscope',
            workplaze: 'fal fa-stethoscope',
        },
    },
    doctor_detail: {
        breadcrumbs: {
            default: 'fas fa-stethoscope',
            workplaze: 'fal fa-stethoscope',
        },
        reverse: {
            default: 'fas fa-chevron-left',
            workplaze: 'fal fa-chevron-left',
        },
        price: {
            default: 'fas fa-money-bill-wave',
            workplaze: 'fal fa-money-bill-alt',
        },
        detail: {
            default: 'fas fa-heartbeat',
            workplaze: 'fal fa-heartbeat',
        },
        book_your_consultation: {
            default: 'fas fa-calendar-check',
            workplaze: 'fal fa-calendar-check',
        },
    },
    select_doctor: {
        breadcrumbs: {
            default: 'fas fa-stethoscope',
            workplaze: 'fal fa-stethoscope',
        },
        price: {
            default: 'fas fa-money-bill-wave',
            workplaze: 'fal fa-money-bill-alt',
        },
    },
    select_hsp: {
        breadcrumbs: {
            default: 'fas fa-search',
            workplaze: 'fal fa-search',
        },
    },
    select_tlm: {
        breadcrumbs: {
            default: 'fas fa-calendar-check',
            workplaze: 'fal fa-calendar-check',
        },
    },
    service: {
        bookings: {
            default: 'fas fa-calendar-check',
            workplaze: 'fal fa-calendar-check',
        },
        favourite: {
            default: 'fas fa-heart',
            workplaze: 'fas fa-heart',
        },
        unfavourite: {
            default: 'far fa-heart',
            workplaze: 'fal fa-heart',
        },
        ongoing: {
            default: 'fas fa-user',
            workplaze: 'fal fa-user',
        },
        history: {
            default: 'fas fa-history',
            workplaze: 'fal fa-history',
        },
        continue: {
            default: 'fas fa-calendar-alt',
            workplaze: 'fal fa-calendar',
        },
        pending_confirmation: {
            default: 'fas fa-pills',
            workplaze: 'fal fa-pills',
        },
        medicine_on_the_way: {
            default: 'fas fa-shipping-fast',
            workplaze: 'fal fa-shipping-fast',
        },
        tlp: {
            default: 'fas fa-phone-square',
            workplaze: 'fal fa-phone-volume',
        },
        pt: {
            default: 'fas fa-dumbbell',
            workplaze: 'fal fa-dumbbell',
        },
        nt: {
            default: 'fas fa-salad',
            workplaze: 'fal fa-salad',
        },
        tlm: {
            default: 'fas fa-phone-square',
            workplaze: 'fal fa-phone-volume',
        },
        ful: {
            default: 'fas fa-file-medical',
            workplaze: 'fal fa-file-medical',
        },
    },
    call_cancel_appointment: {
        breadcrumbs: {
            default: 'fas fa-search',
            workplaze: 'fal fa-search',
        },
        service_has_been_cancelled: {
            default: 'fas fa-hourglass-end',
            workplaze: 'fal fa-hourglass-end',
        },
    },
    call_expired: {
        breadcrumbs: {
            default: 'fas fa-search',
            workplaze: 'fal fa-search',
        },
        service_timeout: {
            default: 'fas fa-stopwatch',
            workplaze: 'fal fa-stopwatch',
        },
    },
    in_call_twilio: {
        chat: {
            default: 'fas fa-comments',
            workplaze: 'fal fa-comments-alt',
        },
        video: {
            default: 'fas fa-video',
            workplaze: 'fal fa-video',
        },
        vioce: {
            default: 'fas fa-phone-alt',
            workplaze: 'fal fa-phone-alt',
        },
        upload: {
            default: 'fas fa-camera',
            workplaze: 'fal fa-camera',
        },
        send_message: {
            default: 'fas fa-paper-plane',
            workplaze: 'fal fa-paper-plane',
        },
    },
    join_call_appointment: {
        breadcrumbs: {
            default: 'fas fa-search',
            workplaze: 'fal fa-search',
        },
        join_call_ready: {
            default: 'fas fa-comments',
            workplaze: 'fal fa-comments-alt',
        },
    },
    join_call_tlp: {
        breadcrumbs: {
            default: 'fas fa-search',
            workplaze: 'fal fa-search',
        },
        info: {
            default: 'far fa-exclamation-circle',
            workplaze: 'far fa-exclamation-circle',
        },
        pharmacy_name: {
            default: 'fas fa-map-marker-alt',
            workplaze: 'fal fa-map-marker-alt',
        },
        join_call_ready: {
            default: 'fas fa-comments',
            workplaze: 'fal fa-comments-alt',
        },
    },
    history: {
        breadcrumbs: {
            default: 'fas fa-calendar-check',
            workplaze: 'fal fa-calendar-check',
        },
        favourite: {
            default: 'fas fa-heart',
            workplaze: 'fas fa-heart',
        },
        unfavourite: {
            default: 'far fa-heart',
            workplaze: 'fal fa-heart',
        },
        consult_now: {
            default: 'fas fa-phone-square',
            workplaze: 'fal fa-phone-volume',
        },
        book_again: {
            default: 'fas fa-calendar-check',
            workplaze: 'fal fa-calendar-check',
        },
        address: {
            default: 'fas fa-map-marker-alt',
            workplaze: 'fal fa-map-marker-alt',
        },
        star: {
            default: 'fas fa-star',
            workplaze: 'fas fa-star',
        },
    },
    comfirm_med: {
        breadcrumbs: {
            default: 'fas fa-pills',
            workplaze: 'fal fa-pills',
        },
        warning: {
            default: 'fas fa-exclamation-circle',
            workplaze: 'fal fa-exclamation-circle',
        },
    },
    pharmacist_cancel: {
        breadcrumbs: {
            default: 'fas fa-search',
            workplaze: 'fal fa-search',
        },
        prescription: {
            default: 'fas fa-clinic-medical',
            workplaze: 'fal fa-clinic-medical',
        },
    },
    pending_tlm: {
        breadcrumbs: {
            default: 'fas fa-stethoscope',
            workplaze: 'fal fa-stethoscope',
        },
    },
    pending_tlp: {
        breadcrumbs: {
            default: 'fas fa-pills',
            workplaze: 'fal fa-pills',
        },
        medicine_confirmation: {
            default: 'fas fa-pills',
            workplaze: 'fal fa-pills',
        },
        pharmacy_name: {
            default: 'fas fa-map-marker-alt',
            workplaze: 'fal fa-map-marker-alt',
        },
    },
    pending_tmf: {
        breadcrumbs: {
            default: 'fas fa-stethoscope',
            workplaze: 'fal fa-stethoscope',
        },
        medicine_confirmation: {
            default: 'fas fa-pills',
            workplaze: 'fal fa-pills',
        },
        pharmacy_name: {
            default: 'fas fa-map-marker-alt',
            workplaze: 'fal fa-map-marker-alt',
        },
    },
    request_med: {
        breadcrumbs: {
            default: 'fas fa-stethoscope',
            workplaze: 'fal fa-stethoscope',
        },
    },
    mobile_banking: {
        breadcrumbs: {
            default: 'fas fa-money-bill-wave',
            workplaze: 'fal fa-money-bill-alt',
        },
        check: {
            default: 'fas fa-check-circle',
            workplaze: 'fal fa-check-circle',
        },
        uncheck: {
            default: 'far fa-circle',
            workplaze: 'fal fa-circle',
        },
    },
    no_payment: {
        breadcrumbs: {
            default: 'fas fa-money-bill-wave',
            workplaze: 'fal fa-money-bill-alt',
        },
    },
    payment: {
        breadcrumbs: {
            default: 'fas fa-money-bill-wave',
            workplaze: 'fal fa-money-bill-alt',
        },
        check: {
            default: 'fas fa-check-circle',
            workplaze: 'fal fa-check-circle',
        },
        next: {
            default: 'fas fa-angle-right',
            workplaze: 'fal fa-chevron-right',
        },
        credit_card: {
            default: 'fas fa-mobile-alt',
            workplaze: 'fal fa-mobile-android',
        },
        reverse: {
            default: 'far fa-angle-left',
            workplaze: 'far fa-angle-left',
        },
        download: {
            default: 'fas fa-arrow-down',
            workplaze: 'fal fa-long-arrow-down',
        },
    },
    srvmed_order: {
        breadcrumbs: {
            default: 'fas fa-file-medical-alt',
            workplaze: 'fal fa-file-medical-alt',
        },
        order_confirmed: {
            default: 'fas fa-check-circle',
            workplaze: 'fal fa-check-circle',
        },
        order_pending: {
            default: 'fas fa-paper-plane',
            workplaze: 'fal fa-paper-plane',
        },
        order_error: {
            default: 'fas fa-times-circle',
            workplaze: 'fal fa-times-circle',
        },
        box: {
            default: 'fas fa-box',
            workplaze: 'fal fa-box-check',
        },
        prescription: {
            default: 'fas fa-file-alt',
            workplaze: 'fal fa-file-alt',
        },
    },
    store_detail: {
        breadcrumbs: {
            default: 'fas fa-clinic-medical',
            workplaze: 'fal fa-clinic-medical',
        },
        reverse: {
            default: 'far fa-angle-left',
            workplaze: 'far fa-angle-left',
        },
        is_expand: {
            default: 'fas fa-caret-right',
            workplaze: 'fal fa-angle-right',
        },
    },
    store_fovourit: {
        btn_pharmacy_card: {
            default: 'fas fa-phone-alt',
            workplaze: 'fal fa-phone-alt',
        },
    },
    store_nearby: {
        breadcrumbs: {
            default: 'fas fa-map-marker-alt',
            workplaze: 'fal fa-map-marker-alt',
        },
    },
    privacy_policy: {
        breadcrumbs: {
            default: 'fas fa-file-invoice-dollar',
            workplaze: 'fal fa-file-invoice-dollar',
        },
    },
    terms_conditions: {
        breadcrumbs: {
            default: 'fas fa-file-invoice-dollar',
            workplaze: 'fal fa-file-invoice-dollar',
        },
    },
    user_account: {
        default: {
            default: 'fas fa-checkicon-flame-circle',
            workplaze: 'fas fa-checkicon-flame-circle',
        },
        name: {
            default: 'fas fa-address-card',
            workplaze: 'fal fa-address-card',
        },
        phone_number: {
            default: 'fas fa-phone-alt',
            workplaze: 'fal fa-phone-alt',
        },
        address: {
            default: 'fas fa-map-marker-alt',
            workplaze: 'fal fa-map-marker-alt',
        },
    },
    address_add: {
        breadcrumbs: {
            default: 'fas fa-user-cog',
            workplaze: 'fal fa-user-cog',
        },
    },
    address_edit: {
        breadcrumbs: {
            default: 'fas fa-user-cog',
            workplaze: 'fal fa-user-cog',
        },
    },
    coverage: {
        breadcrumbs: {
            default: 'fas fa-user-cog',
            workplaze: 'fal fa-user-cog',
        },
    },
    credit_card: {
        breadcrumbs: {
            default: 'fas fa-user-cog',
            workplaze: 'fal fa-user-cog',
        },
    },
    credit_card_list: {
        breadcrumbs: {
            default: 'fas fa-user-cog',
            workplaze: 'fal fa-user-cog',
        },
        delete_credit_card: {
            default: 'fas fa-trash',
            workplaze: 'fal fa-trash',
        },
    },
    profile: {
        breadcrumbs: {
            default: 'fas fa-user-cog',
            workplaze: 'fal fa-user-cog',
        },
        change: {
            default: 'fas fa-angle-up',
            workplaze: 'fal fa-key',
        },
    },
    profile_photo: {
        photo: {
            default: 'fas fa-camera',
            workplaze: 'fal fa-camera',
        },
    },
    login: {
        line: {
            default: 'fab fa-line',
            workplaze: 'fab fa-line',
        },
    },
    user_plugin: {
        breadcrumbs: {
            default: 'fas fa-stethoscope',
            workplaze: 'fal fa-stethoscope',
        },
    },
    signup: {
        line: {
            default: 'fab fa-line',
            workplaze: 'fab fa-line',
        },
    },
    lab_result: {
        icon_card: {
            default: 'far fa-monitor-heart-rate',
            workplaze: 'fal fa-monitor-heart-rate',
        },
    },
    cta_fixed_on_right: {
        tlp: {
            default: 'fas fa-phone-square',
            workplaze: 'fal fa-phone-volume',
        },
        tlp_consult: {
            default: 'fas fa-phone-square',
            workplaze: 'fal fa-phone-volume',
        },
        tlm: {
            default: 'fas fa-comments',
            workplaze: 'fal fa-comments-alt',
        },
        mth: {
            default: 'fas fa-comments',
            workplaze: 'fal fa-comments-alt',
        },
        pt: {
            default: 'fas fa-dumbbell',
            workplaze: 'fal fa-dumbbell',
        },
        nt: {
            default: 'fas fa-salad',
            workplaze: 'fal fa-salad',
        },
    },
    ready_to_service: {
        tlp: {
            default: 'fas fa-phone-square',
            workplaze: 'fal fa-phone-volume',
        },
        tlp_consult: {
            default: 'fas fa-phone-square',
            workplaze: 'fal fa-phone-volume',
        },
        tlm: {
            default: 'fas fa-comments',
            workplaze: 'fal fa-comments-alt',
        },
        pt: {
            default: 'fas fa-dumbbell',
            workplaze: 'fal fa-dumbbell',
        },
        nt: {
            default: 'fas fa-salad',
            workplaze: 'fal fa-salad',
        },
    },
    service_onboarding: {
        telepharmacy1: {
            default: 'fas fa-pills',
            workplaze: 'fal fa-pills',
        },
        telepharmacy2: {
            default: 'fas fa-motorcycle',
            workplaze: 'fal fa-motorcycle',
        },
        tlmdoctorhub1: {
            default: 'fas fa-user-md',
            workplaze: 'fal fa-user-md',
        },
        tlmdoctorhub2: {
            default: 'fas fa-pills',
            workplaze: 'fal fa-pills',
        },
        tlmdoctorhub3: {
            default: 'fas fa-motorcycle',
            workplaze: 'fal fa-motorcycle',
        },
        tlmcentral20_1: {
            default: 'fas fa-user-md',
            workplaze: 'fal fa-user-md',
        },
        tlmcentral20_2: {
            default: 'fas fa-pills',
            workplaze: 'fal fa-pills',
        },
    },
    save_rating_box: {
        star: {
            default: 'fas fa-star',
            workplaze: 'fas fa-star',
        },
    },
};
