import { useEffect, useRef, useState } from 'react';
import { numberjs } from 'utils';
import { CarouselWrapper } from './Carousel.styled';

export type TCarouselProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    activeDefault: string;
};

function Carousel({ activeDefault = '01', children, ...props }) {
    const timer = useRef<NodeJS.Timeout>();
    const timerCancel = useRef<NodeJS.Timeout>();
    const maxSlide = children?.length;
    const [slideIndex, setSlideIndex] = useState<number>(0);
    const [cancel, setCancel] = useState<boolean>(false);
    const [slideAuto, setSlideAuto] = useState<number>(slideIndex + 1);
    const [changeClass, setChangeClass] = useState<string>('carousel-item active');

    useEffect(() => {
        if (Array.isArray(children) && maxSlide > 0 && children?.find((f) => f?.key === activeDefault)) {
            setSlideIndex(numberjs(activeDefault).value - 1);
            setSlideAuto(numberjs(activeDefault).value);
        }
    }, []);

    const onNextDot = (next: number) => {
        clearTimeout(timer.current);
        if (slideIndex < next) {
            setChangeClass('carousel-item active fade-next');
            clearTimeout(timerCancel.current);
            setCancel(true);
            setSlideAuto(next + 1);
        }
        if (slideIndex > next) {
            setChangeClass('carousel-item active fade-prev');
            clearTimeout(timerCancel.current);
            setCancel(true);
            setSlideAuto(next + 1);
        }
        if (maxSlide - 1 <= next) {
            setSlideAuto(0);
        }
        setSlideIndex(next);
        timerCancel.current = setTimeout(() => {
            setCancel(false);
        }, 5000);
    };

    useEffect(() => {
        if (maxSlide > 1 && !cancel) {
            timer.current = setTimeout(() => {
                setChangeClass('carousel-item active fade-next');
                setSlideIndex(slideAuto);
                if (maxSlide - 1 > slideAuto) {
                    setSlideAuto(slideAuto + 1);
                }
                if (maxSlide - 1 <= slideAuto) {
                    setSlideAuto(0);
                }
            }, 5000);
        }
    }, [slideAuto, cancel]);

    if (!Array.isArray(children))
        return (
            <CarouselWrapper {...props}>
                <div className='carousel-indicators'>
                    <div className='active'></div>
                </div>
                <div className='carousel-inner'>
                    <div className={changeClass}>{children}</div>
                </div>
            </CarouselWrapper>
        );
    return (
        <CarouselWrapper {...props}>
            <div className='carousel-indicators'>
                {maxSlide > 0 &&
                    children.map((value, index) => (
                        <div className={index === slideIndex ? 'active' : ''} onClick={() => onNextDot(index)} key={index}></div>
                    ))}
            </div>
            <div className='carousel-inner'>
                {maxSlide > 0 &&
                    children.map((value, index) => (
                        <div key={index} className={index === slideIndex ? changeClass : 'carousel-item'}>
                            {value.props?.children}
                        </div>
                    ))}
            </div>
        </CarouselWrapper>
    );
}

export default Carousel;
