import React from 'react'
import { ThemeWithPartnerContext } from 'context/ThemeWithPartnerProvider'

/**
 *
 * @returns {{
 * name: String,
 * setTheme: (theme: String)=> void
 * }}
 */
const useThemeWithPartner = () => {
    return React.useContext(ThemeWithPartnerContext)
}

export default useThemeWithPartner
