import React from 'react'
import LOCALE_TH from './locale/th'
import LOCALE_EN from './locale/en'
import { dateFormat, extendesClassname, splitIntoRows } from './utils'

const WEEK_DAYS = {
    full: {
        th: LOCALE_TH.weekdays,
        en: LOCALE_EN.weekdays
    },
    short: {
        th: LOCALE_TH.weekdaysShort,
        en: LOCALE_EN.weekdaysShort
    }
}

/**
 *
 * @param {{
 * currentDate: Date,
 * locale: string,
 * dayOfWeek: 'short'|'full',
 * disableDate: (never: Date)=> Boolean,
 * onSelectDate: (date: Date)=> void
 * }} props
 * @returns
 */
function CalendarJs({ currentDate = new Date(), locale = 'th', dayOfWeek = 'short', disableDate = () => null, onSelectDate = () => null }) {
    const getCalendar = React.useCallback(
        (date = new Date(), currentMonth = 0) => {
            const startDate = date.getDate()
            const startMonth = date.getMonth()
            const startYear = date.getFullYear()
            const result = []

            for (let i = 0; i < 42; i++) {
                const _date = new Date(startYear, startMonth, startDate + i)

                result.push({
                    label: _date.getDate(),
                    date: _date,
                    last: _date.getMonth() < currentMonth,
                    next: _date.getMonth() > currentMonth,
                    disabled: disableDate(_date)
                })
            }

            return result
        },
        [disableDate]
    )

    const calendar = React.useMemo(() => {
        const monthSelect = currentDate.getMonth() // 0 - 11
        const yearSelect = currentDate.getFullYear()

        const startDayOfMonth = new Date(yearSelect, monthSelect, 1) // Sunday - Saturday : 0 - 6 // *****ไม่จำเป็นต้องบวกเพิ่มเป็น 7 เพระไม่นับวันเริ่ม
        const startOfDatePrevMonth = startDayOfMonth.getDay()
        const startCalendarOfMonth = new Date(yearSelect, monthSelect, 1 - startOfDatePrevMonth)

        const dateOfCalendar = getCalendar(startCalendarOfMonth, monthSelect)

        return splitIntoRows(dateOfCalendar, 7)
    }, [currentDate.getMonth(), currentDate.getFullYear()])

    const onSelect = (item = {}) => {
        if (item.disabled) return

        // callback
        onSelectDate(item.date)
    }

    return (
        <table className='calendar-table'>
            <thead>
                <tr>
                    {WEEK_DAYS[dayOfWeek][locale.toLowerCase()].map((item, index) => (
                        <th key={index} className='calendar-column-header'>
                            <span className='calendar-column-header-inner'>{item}</span>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody className='calendar-tbody'>
                {calendar.map((week, weekIndex) => (
                    <tr key={weekIndex}>
                        {week?.map((item, dayIndex) => (
                            <td
                                key={dayIndex}
                                title={dateFormat(item.date, 'D MMMM YYYY')}
                                {...extendesClassname([
                                    'calendar-cell',
                                    currentDate.toDateString() === item.date.toDateString() && 'calendar-selected-day',
                                    item.last && 'calendar-last-month-cell',
                                    item.next && 'calendar-next-month-btn-day',
                                    item.disabled && 'calendar-disabled-btn-day'
                                ])}
                                onClick={() => onSelect(item)}
                            >
                                <div className='calendar-date'>{item.label}</div>
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export const LOCALE = { th: LOCALE_TH, en: LOCALE_EN }
export default CalendarJs
