import React, { useState } from 'react';
import { EXPCollapse } from 'components/common/exp-collapse';
import { Col, Row } from 'components/common/grid-layout';
import { useTranslation } from 'react-i18next';
import { DatetimeBox } from './datetime-box';
import IconConfigWithTheme from 'components/common/icon-config-with-theme';

/**
 *
 * @param {{
 * timeSelect:string | null;
 * dateSelect: string;
 * data: any[];
 * onSelect: (data: string, time: string) => void;
 * }} props
 * @returns
 */
export default function TimeSlote({ timeSelect, dateSelect, data, onSelect = () => null }) {
    const currentList = data.find((el) => el.date === dateSelect);

    const { t } = useTranslation();
    const [showMore, setShowMore] = useState(false);

    if (!currentList || currentList.length <= 0) return null;

    return (
        <div className='date-time'>
            <Row>
                {currentList?.timeList.slice(0, 6).map((time, index) => (
                    <Col key={index} tabIndex={index} data-test-id='timeSlot' xs={4} sm={4} className='pb-2'>
                        <DatetimeBox
                            key={index}
                            data-test-id='timeBox'
                            data-test-selected={timeSelect === time}
                            active={timeSelect === time}
                            onClick={() => onSelect(dateSelect, time)}
                        >
                            {time}
                        </DatetimeBox>
                    </Col>
                ))}
            </Row>
            {currentList?.timeList.length > 6 && (
                <React.Fragment>
                    <EXPCollapse show={showMore}>
                        <Row>
                            {currentList?.timeList.slice(6).map((time, index) => (
                                <Col key={index + 6} tabIndex={index + 6} data-test-id='timeSlot' xs={4} sm={4} className='pb-2'>
                                    <DatetimeBox
                                        data-test-id='timeBox'
                                        data-test-selected={timeSelect === time}
                                        active={timeSelect === time}
                                        onClick={() => onSelect(dateSelect, time)}
                                    >
                                        {time}
                                    </DatetimeBox>
                                </Col>
                            ))}
                        </Row>
                    </EXPCollapse>
                    <div
                        data-test-id='seeMore'
                        className='text-center text-second cursor-pointer mt-4'
                        onClick={() => setShowMore(!showMore)}
                    >
                        <span>{showMore ? t('claim:booking.show_less') : t('claim:booking.show_more')}</span>
                        &nbsp;
                        <IconConfigWithTheme
                            pageName='time_slote'
                            iconName='show_more'
                            className={`${showMore ? 'fa-rotate-0' : 'fa-rotate-180'}`}
                        />
                    </div>
                </React.Fragment>
            )}
        </div>
    );
}

TimeSlote.defaultProps = {
    data: [],
    onSelect: () => null,
};
