import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
function SidebarInfo() {
    const { t } = useTranslation()

    return (
        <div className='mt-auto mb-0 fw-200'>
            <div>System Version 10.23.00</div>
            {t('sidebar:our_terms')} <br />
            <a href='/terms-conditions' target='_blank' className='text-white'>
                {t('sidebar:terms_and_conditions')}
            </a>
            <br />
            <a href='/privacy-policy' target='_blank' className='text-white'>
                {t('sidebar:privacy_policy')}
            </a>
            <Text className='mt-3'>สนใจร่วมเป็นส่วนหนึ่งกับเครือข่ายร้านยา PharmCare</Text>
            <Text>สามารถเพิ่มเพื่อนและสอบถามไลน์ละเอียดได้ที่</Text>
            <Text>Line ID: @pharmcaresupport</Text>
        </div>
    )
}
const Text = styled.div`
    label: footer-text;
    margin-bottom: 5px;
`
export default SidebarInfo
