import { TextTitle } from 'components/common/styled-components-common';
import { CardAddress } from '../SelectLabAddress.styled';
import IconConfigWithTheme from 'components/common/icon-config-with-theme';
import { useTranslation } from 'react-i18next';
import type { AddressData, SelectSlotData } from '../SelectLabAddress';
import { useCallback } from 'react';

export interface SelectAddressListComponent {
    data: SelectSlotData;
    status?: 'success' | 'error';
    dataSource: AddressData[];
    onSelect: (data: SelectSlotData) => void;
    onEdit: (data: AddressData) => void;
}

function SelectAddressList({ data, status, dataSource, onSelect = () => null, onEdit = () => null }: Readonly<SelectAddressListComponent>) {
    const getClassStatus = useCallback(
        (id: string | undefined) => {
            if (data.addressId === id) return 'card-status-selected';
            if (data.addressId === id && status === 'error') return 'card-status-error';

            return 'card-status-none';
        },
        [status, data]
    );

    return (
        <>
            <TextTitle className='mt-5'>
                <div>เลือกสถานที่นัดหมาย</div>
            </TextTitle>
            <div className='px-4'>
                {dataSource.map((item) => (
                    <CardAddress key={item.id} className={getClassStatus(item.id)}>
                        <div className='card-select-body' onClick={() => onSelect({ ...data, addressId: item.id as string })}>
                            <div className='name'>
                                <div>
                                    <IconConfigWithTheme
                                        pageName='detail_address_type01'
                                        iconName='nominee_name'
                                        className='text-third-icon mr-2'
                                    />
                                    <span className='fw-500'>{item.nomineeName}</span>
                                </div>
                                <div className='text-center ml-auto selected-icon' style={{ fontSize: '20px' }}>
                                    <IconConfigWithTheme pageName='detail_address_type01' iconName='check' />
                                </div>
                            </div>
                            <div>
                                <IconConfigWithTheme
                                    pageName='detail_address_type01'
                                    iconName='nominee_phone_number'
                                    className='text-primary-icon mr-2'
                                />
                                <span className='text-gray-1'>{item.nomineePhoneNumber}</span>
                            </div>
                            <div className='d-inline-flex' title={item.address}>
                                <IconConfigWithTheme
                                    pageName='detail_address_type01'
                                    iconName='address'
                                    className='text-primary-icon mr-2'
                                />
                                <span className='text-gray-1 -data_address'>{`${item.address} ${item.subdistrict} ${item.district} ${item.province} ${item.zipcode}`}</span>
                            </div>
                        </div>
                        <div className='card-select-footer'>
                            <button type='button' className='text-second ml-auto edit-button' onClick={() => onEdit(item)}>
                                <span className='d-inline-block mr-1'>{'แก้ไข'}</span>{' '}
                                <IconConfigWithTheme pageName='detail_address_type01' iconName='edit' />
                            </button>
                        </div>
                    </CardAddress>
                ))}
            </div>
        </>
    );
}

export default SelectAddressList;
