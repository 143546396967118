import React from 'react'
import { extendesClassname } from './utils'

/**
 * @name FormLabel
 * @param {{
 * textSmall: Boolean,
 * required: Boolean
 * } & React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>} props
 * @returns
 */
function FormLabel({ textSmall = false, required = false, ...props }) {
    if (required)
        return React.createElement(
            'label',
            {
                ...props,
                ...extendesClassname(['exp-form-label', textSmall && 'exp-form-label-small', props.className])
            },
            props.children,
            React.createElement('span', { className: 'exp-form-required' }, '*')
        )

    return React.createElement(
        'label',
        {
            ...props,
            ...extendesClassname(['exp-form-label', textSmall && 'exp-form-label-small', props.className])
        },
        props.children
    )
}
export default FormLabel
