import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import { breakpoint, checkDevice } from 'utils';
import useAuth from 'hooks/useAuth';
import IconConfigWithTheme from 'components/common/icon-config-with-theme';
import useThemeWithPartner from 'hooks/useThemeWithPartner';
import { useNavigate } from 'react-router-dom';
import { HomeServiceBox, HomeServiceWrapper } from './Home.styled';
import { useServiceData } from './homeServiceData';
import type { TServiceWithTheme } from './homeServiceData';

const homeServiceSlideSetting = {
    arrows: false,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1680,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: false,
                dots: true,
            },
        },
        {
            breakpoint: 1080,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: false,
                dots: true,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                centerMode: true,
                dots: true,
            },
        },
    ],
};
const getServiceWithTheme = (themeName: string) => {
    if (themeName === 'rabbit') return ['telemed'];
    if (themeName === 'betterperiod') return ['telepharmacy'];
    if (themeName === 'benix') return ['telepharmacy', 'tlm'];
    if (themeName === 'doctorhub') return ['telepharmacy', 'tlm'];
    if (themeName === 'central') return ['telepharmacy', 'tlm', 'mth', 'nearby'];
    if (themeName === 'central20') return ['tlm', 'fulfillment', 'tlmNcd'];
    if (themeName === 'central30') return ['telepharmacy', 'tlm'];
    if (themeName === 'humatrix') return ['tlm', 'telepharmacy', 'nearby', 'pt', 'nt'];
    if (themeName === 'workplaze') return ['tlm', 'telepharmacy', 'nearby', 'pt', 'nt'];
    if (themeName === 'ptg') return ['tlm', 'telepharmacy'];
    if (themeName === 'default_humatrix') return ['tlm', 'telepharmacy', 'pt', 'nt'];
    if (themeName === 'default_oceanopd') return ['telepharmacy', 'tlm'];
    if (themeName === 'default_sunday') return ['tlm'];
    if (themeName === 'yaphrom') return ['telepharmacy', 'tlm'];
    if (themeName === 'partner_pharmacy') return ['telepharmacy'];
    if (themeName === 'pandf') return ['telepharmacy'];
    if (themeName === 'morningmind') return ['mth'];
    if (themeName === 'no_config') return ['tlm', 'telepharmacy'];
    if (themeName === 'cpa') return ['telepharmacy', 'telepharmacyCPA'];
    if (themeName === 'fastcare') return ['telepharmacy', 'tlm', 'nearby'];
    if (themeName === 'default_pharmcare') return ['telepharmacyLTC', 'telepharmacy', 'tlm', 'nearby', 'pt', 'nt'];

    return ['telepharmacy', 'tlm', 'tlmNcd', 'mth', 'fulfillment', 'nearby', 'fda', 'pt', 'nt'];
};

function HomeService({ showTitle = false }) {
    const { t } = useTranslation();
    const { auth, profile } = useAuth();
    const navigate = useNavigate();
    const serviceData = useServiceData();
    //

    const onRedirect = (data: TServiceWithTheme) => {
        if (data?.openExternalBrowser) {
            gotoExternalBrowser(data?.redirectUrl);
        } else if (data?.withParams) {
            navigate(data?.redirectUrl + window.location.search);
        } else if (data?.withPharmacyCode) {
            navigate(data?.redirectUrl + '&pharmacyCode=' + profile?.userXPartner?.partnerPharmacyCode);
        } else {
            navigate(data?.redirectUrl);
        }
    };

    const gotoExternalBrowser = (path: string) => {
        const authorization = auth.accessToken || '';
        const { isWebview, redirectUrl } = checkDevice(path, '', profile);

        if (isWebview) {
            if (authorization) {
                window.open(redirectUrl);
            } else {
                window.open(process.env.REACT_APP_WEB_URL + path + '?openExternalBrowser=1');
            }

            navigate('/home');
        } else {
            navigate(path);
        }
    };

    return (
        <HomeServiceWrapper>
            {!showTitle && (
                <div className='home-service-title' data-test-id='title'>
                    {t('home:our_services')}
                </div>
            )}
            <div className='home-service-list'>
                <Slider {...homeServiceSlideSetting}>
                    {serviceData.map((item, index) => (
                        <div data-test-id={item.serviceId} className='h-100' key={index}>
                            <HomeServiceBox backgroundColor={item.backgroundColor}>
                                <div className='home-service-box-container h-100' onClick={() => onRedirect(item)}>
                                    <div className='home-service-box-title' data-test-id='title'>
                                        {item.titleIcon.type === 'icon' ? (
                                            <IconConfigWithTheme
                                                pageName={item.titleIcon.pageName as string}
                                                iconName={item.titleIcon.iconName as string}
                                            />
                                        ) : (
                                            <img {...item.titleIcon.imageOptions} />
                                        )}{' '}
                                        {item.title}
                                    </div>
                                    <div className='home-service-box-content'>
                                        <div className='home-service-box-detail'>
                                            {item.detail}
                                            <div className='-text-subDetail'>{item.subDetail}</div>
                                        </div>
                                        <div className='home-service-box-img'>
                                            <img loading='eager' src={item.imgUrl} alt='' />
                                        </div>
                                    </div>
                                    {item.footer && (
                                        <div className='home-service-box-goto'>
                                            <span>{item.footer}</span>&nbsp;
                                        </div>
                                    )}
                                </div>
                            </HomeServiceBox>
                        </div>
                    ))}
                </Slider>
            </div>
        </HomeServiceWrapper>
    );
}

export default HomeService;
