import { Panel } from 'components/common/styled-components-common'
import { Stepper } from 'components/common/Stepper'
import styled from '@emotion/styled'

const StepprtVariable = styled.div`
    .xxx {
        --steps-item-icon-size: 32px;
        --steps-item-icon-size-active: 32px;
        --steps-item-icon-color: #ddd;
        --steps-item-icon-color-active: #00ffd0;
        --steps-item-dot-size: 12px;
        --steps-item-dot-color: #999;
        --steps-item-dot-color-active: #fff;
        --steps-item-tail-heght: 4px;
        --steps-item-tail-color: #ddd;
        --steps-item-tail-color-active: #00ffd0;
        --steps-item-content-color: #999;
        --steps-item-content-color-active: #000;
    }
`

const CustomDot = styled.div`
    z-index: 2;
    position: absolute;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    border-radius: 100%;
    background-color: #fff;
    top: 4px;
    left: 4px;
    line-height: 1.5;
    vertical-align: middle;
`

function Demo() {
    return (
        <Panel>
            <StepprtVariable>
                <Stepper
                    className='xxx'
                    // fixHeight={20}
                    current={4}
                    percent={60}
                    progressDot={(_, info) => {
                        console.log('info:', info)

                        return <CustomDot>{info.index + 1}</CustomDot>
                    }}
                    items={[
                        {
                            title: 'Finished',
                            description: 1
                        },
                        {
                            title: 'In Progress',
                            description: 2
                        },
                        {
                            title: 'Waiting',
                            description: 3
                        },
                        {
                            title: 'Waiting',
                            description: 4,
                            onClick: () => null
                        },
                        {
                            title: 'Waiting',
                            description: 5,
                            disabled: true
                        },
                        {
                            title: 'Waiting',
                            description: 6
                        },
                        {
                            title: 'Waiting',
                            description: 7
                            // icon: <i className='fas fa-check-circle' />
                        }
                    ]}
                />
            </StepprtVariable>
        </Panel>
    )
}

export default Demo
