export const firstNamePattern = '[A-Za-z\\-,.\\(\\)\\u0E00-\\u0E7F]{1,}$';
export const lastNamePattern = '[A-Za-z\\-,.\\(\\)\\u0E00-\\u0E7F]{1,}$';
export const firstNameLastNamePattern =
    '([ ]||[])+([A-Za-z\\-,.\\(\\)\\u0E00-\\u0E7F])+[ ]*([A-Za-z\\-,.\\(\\)\\u0E00-\\u0E7F])+([ ]||[])*';
export const idCardPattern = '^\\d{13}$';
export const passportPattern = '[\\w\\-]{6,20}$';
export const phoneNumberPattern = '^0\\d{9,9}$';
export const bodyWeightPattern = '^\\d{1,3}$';
export const bodyHeightPattern = '^\\d{1,3}$';
export const creditCardNumberPattern = '[ \\d]{19,19}$';
export const creditCardExpirationPattern = '[/\\d]{5}';
export const creditCardSecurityCodePattern = '^\\d{3}';
export const passwordPattern = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d]{8,16}$';
export const emailPattern = '[\\w\\d.]+@([\\w]+\\.)+[\\w]{2,4}';
// [\w\d.]+@+[\w]+(.)+[\w]?.[\w]
// ^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}
