import React from 'react'
import { extendesClassname } from './utils'

/**
 *
 * @param {{
 * isInvalid: Boolean
 * } & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns
 */
function FormInvalidFeedback({ isInvalid = false, ...props }) {
    return React.createElement(
        'div',
        { ...props, ...extendesClassname(['exp-invalid-feedback', isInvalid && 'is-invalid', props.className]) },
        props.children
    )
}
export default FormInvalidFeedback
