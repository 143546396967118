import React, { useEffect } from 'react'

export default function DetectSwitchDom({ children }) {
    useEffect(() => {
        console.log('useEffect: switch dom')
        const onChangePage = new Event('onchangepage')
        window.addEventListener('popstate', () => {
            console.log('popstate')
        })
        window.addEventListener('onpopstate', () => {
            console.log('onpopstate')
        })
        document.addEventListener('popstate', () => {
            console.log('document popstate')
        })
        document.addEventListener('onpopstate', () => {
            console.log('document onpopstate')
        })

        return () => {
            window.dispatchEvent(onChangePage)
            console.log('unmount: switch dom')
        }
    }, [window.location.pathname])

    return <>{children}</>
}
