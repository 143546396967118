import { css } from '@emotion/react'

const extentsBootstrapGrid = () => {
    let result = ''

    for (let index = 1; index <= 12; index++) {
        result += `
        &-${index} {
            -ms-flex: 0 0 calc(8.333333% * ${index});
            flex: 0 0 calc(8.333333% * ${index});
            max-width: calc(8.333333% * ${index});
            position: relative;
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
        }
        
        `
    }

    return result
}

export const col = css`
    /* add extent bootstrap grid */
    @media screen and (min-width: 1400px) {
        .col-xxl {
            ${extentsBootstrapGrid()}
        }
    }

    @media screen and (min-width: 1600px) {
        .col-xxxl {
            ${extentsBootstrapGrid()}
        }
    }
`
