import React, { useRef, useState } from 'react'
import styled from '@emotion/styled'
import { breakpoint } from 'utils'
import useOnClickOutside from 'hooks/useOnClickOutside'
import { useTranslation } from 'react-i18next'
import { updateUserLanguage } from 'api/api-often'
import useAuth from 'hooks/useAuth'

const LANGUAGE_LIST = ['th', 'en']
const LANGUAGE_ICON = {
    th: '/images/sidebar/th.png',
    en: '/images/sidebar/en.png'
}

const DesktopStyle = styled.div`
    label: nav-language-desktop;

    display: flex;
    align-items: center;
    height: 100%;
    border-right: 2px solid var(--navbar-vertical-bar-color);
    margin-left: auto;
    padding-right: 15px;
    z-index: 2;

    ${breakpoint('LG')} {
        display: none;
    }

    .list {
        color: var(--navbar-language-color-inactive);
        padding: 0 5px;
        line-height: 1;
        cursor: pointer;

        &:nth-of-type(n + 2) {
            border-left: 2px solid var(--navbar-vertical-bar-color);
        }
    }

    .active {
        color: var(--navbar-language-color-active);
        font-weight: 600;
    }
`

const MobileStyle = styled.div`
    label: nav-language-mobile;

    position: relative;
    display: none;
    z-index: 6;

    ${breakpoint('LG')} {
        display: block;
    }

    .lang-img {
        width: 25px;
        height: 25px;
        border-radius: 100%;
        overflow: hidden;
        cursor: pointer;
    }

    .lang-dropdown {
        position: absolute;
        top: calc(100% + 6px);
        /* right: 0; */
        left: calc(50% - (25px / 2) - 6px);
        padding: 6px;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 0 5px #00000040;
    }
`

function NavLanguage({ mobileLanguage = false }) {
    const ref = useRef()
    //
    const { i18n } = useTranslation()
    const { auth, updateProfile } = useAuth()
    //
    const [isToggle, setIsToggle] = useState(false)

    const onSwitchLanguage = async (language) => {
        await updateUserLanguage(language, auth.accessToken)

        if (auth.isAuth) await updateProfile(auth.accessToken)
    }

    useOnClickOutside(ref, () => setIsToggle(false))

    return (
        <>
            <DesktopStyle>
                {LANGUAGE_LIST.map((lang, index) => (
                    <div
                        key={index}
                        className={i18n.language.toLowerCase() === lang ? 'list active' : 'list'}
                        onClick={() => onSwitchLanguage(lang)}
                        data-test-id={lang}
                    >
                        {lang.toLocaleUpperCase()}
                    </div>
                ))}
            </DesktopStyle>
            {mobileLanguage && (
                <MobileStyle ref={ref}>
                    <div className='lang-img' onClick={() => setIsToggle(!isToggle)}>
                        <img src={LANGUAGE_ICON[i18n.language.toLowerCase()]} alt={LANGUAGE_ICON[i18n.language.toLowerCase()]} />
                    </div>
                    {isToggle && (
                        <div className='lang-dropdown'>
                            {LANGUAGE_LIST.map(
                                (lang, index) =>
                                    i18n.language.toLowerCase() !== lang && (
                                        <div key={index} className='lang-img' onClick={() => onSwitchLanguage(lang)}>
                                            <img src={LANGUAGE_ICON[lang]} alt={lang} />
                                        </div>
                                    )
                            )}
                        </div>
                    )}
                </MobileStyle>
            )}
        </>
    )
}

export default NavLanguage
