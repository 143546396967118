import React, { useState } from 'react';
import styled from '@emotion/styled';
import { cryptojs, getFullName, breakpoint } from 'utils';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EXPPopupJs from 'components/common/exp-popupjs';
import useAuth from 'hooks/useAuth';
import IconConfigWithTheme from 'components/common/icon-config-with-theme';
import useOnClickOutside from 'hooks/useOnClickOutside';
import useThemeWithPartner from 'hooks/useThemeWithPartner';

const Style = styled.div`
    label: nav-profile;

    position: relative;
    color: var(--navbar-profile-color);
    margin-left: 15px;

    ${breakpoint('LG')} {
        display: none;
    }

    .profile {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;

        .photo {
            position: relative;
            width: 45px;
            height: 45px;
            border-radius: 50%;
            overflow: hidden;
            background-color: var(--navbar-profile-background-color);

            img {
                object-fit: cover;
                z-index: 2;
            }
            .first-letter {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                color: #fff;
                font-size: 1.4rem;
            }
        }
    }
    .name {
        margin: 0 15px;
    }
    .menu {
        position: absolute;
        top: 65px;
        right: 0;
        z-index: 10;
        min-width: 200px;
        white-space: nowrap;
        border-radius: 10px;
        padding: 15px 20px;
        background-color: var(--navbar-dropdown-background-color);

        > .list {
            display: grid;
            grid-template-columns: 30px auto;
            color: var(--navbar-dropdown-color-inactive);
            margin: 10px 0;
            cursor: pointer;

            &:hover {
                color: var(--navbar-dropdown-color-active);
            }

            .nav-dropdown-icon {
                color: var(--navbar-dropdown-icon-color);
            }
        }
    }
`;

function ProfileMenu() {
    const { t } = useTranslation();
    const { profile, userLogout } = useAuth();
    const theme = useThemeWithPartner();
    const navigate = useNavigate();

    const onCheckPid = () => {
        if (profile.userPid) {
            navigate('/phr/existing');
        } else {
            EXPPopupJs.alert({
                title: t('common:warning'),
                message: 'กรุณากรอกข้อมูลส่วนตัวก่อนไปหน้า Health',
                classNames: { message: 'font-detail2' },
                buttons: [
                    {
                        label: t('common:ok'),
                        variant: 'primary',
                        action: nextAction,
                    },
                ],
            });
        }
    };

    const nextAction = ({ startAction, close }) => {
        startAction();
        navigate('/user/account');

        close();
    };
    const gotoFavourite = () => {
        if (profile.userEmail) {
            let params = '';
            if (profile.userXPartner) {
                if (profile.userXPartner.partnerTheme === 'default_humatrix') {
                    params = '?voucher=TLP&billing=human01';
                }
                if (profile.userXPartner.partnerTheme === 'default_sunday') {
                    params = '?voucher=sunday01';
                }
                if (profile.userXPartner.partnerTheme === 'default_oceanopd') {
                    params = '?voucher=oceanopd01';
                }
            }

            navigate('/store/favourite' + params);
        } else {
            navigate('/user/line', { staete: window.location.pathname });
        }
    };

    const onLogout = () => {
        userLogout();
        setTimeout(() => {
            if (window.location.origin.includes('fastcare.co')) {
                const to = { path: '/user/plugin', data: { partner: '18', autoLogin: 0 } };
                window.location.replace('/user/line?to=' + cryptojs.encode(to));
            } else if (window.location.origin.includes('yaphrom')) {
                const to = { path: '/user/plugin', data: { partner: '15', autoLogin: 0 } };
                window.location.replace('/user/line?to=' + cryptojs.encode(to));
            } else {
                navigate('/user/line');
            }
        }, 350);
    };

    return (
        <>
            {theme.name !== 'default_oceanopd' && (
                <div className='list' onClick={onCheckPid} data-test-id='item'>
                    <div>
                        <IconConfigWithTheme pageName='nav_profile' iconName='health' className='nav-dropdown-icon' />
                    </div>
                    <div>{t('navbar:profile_dropdown.health')}</div>
                </div>
            )}
            <a href='/service' className='list' data-test-id='item'>
                <div>
                    <IconConfigWithTheme pageName='nav_profile' iconName='activities' className='nav-dropdown-icon' />
                </div>
                <div>{t('navbar:profile_dropdown.activities')}</div>
            </a>
            <hr />
            <a className='list' href='/user/account' data-test-id='item'>
                <div>
                    <IconConfigWithTheme pageName='nav_profile' iconName='account_setting' className='nav-dropdown-icon' />
                </div>
                <div>{t('navbar:profile_dropdown.account_setting')}</div>
            </a>
            <div className='list' onClick={gotoFavourite} data-test-id='item'>
                <div>
                    <IconConfigWithTheme pageName='nav_profile' iconName='favourite_pharmacy' className='nav-dropdown-icon' />
                </div>
                <div>{t('navbar:profile_dropdown.favourite_pharmacy')}</div>
            </div>
            <div className='list' onClick={onLogout} data-test-id='item'>
                <div>
                    <IconConfigWithTheme pageName='nav_profile' iconName='log_out' className='nav-dropdown-icon' />
                </div>
                <div>{t('navbar:profile_dropdown.logout')}</div>
            </div>
        </>
    );
}

function NavProfile() {
    const { auth, profile } = useAuth();
    const menuRef = React.useRef();
    const [showMenu, setShowMenu] = useState(false);

    const getProfilePhoto = () => {
        if (!profile.displayName && !profile.userImgUrl) return null;

        if (profile.userImgUrl) {
            return <img src={profile.userImgUrl} alt='' />;
        }

        return <div className='first-letter'>{profile.displayName.substring(0, 1)?.toUpperCase()}</div>;
    };

    useOnClickOutside(menuRef, () => setShowMenu(false));

    if (!auth.isAuth) return null;

    return (
        <Style>
            <div className='profile' onClick={() => setShowMenu(!showMenu)}>
                <div className='photo'>{getProfilePhoto()}</div>
                <div className='name' data-test-id='profileName'>
                    {getFullName(profile.displayName)}
                </div>
                <IconConfigWithTheme pageName='nav_profile' iconName='profile_menu' className='nav-dropdown-icon' />
            </div>
            <div ref={menuRef} className='menu' style={{ display: showMenu ? 'block' : 'none' }}>
                <ProfileMenu />
            </div>
        </Style>
    );
}

export default NavProfile;
