import styled from '@emotion/styled'

/**
 * @param {string} align
 */
const TextTitle = styled.div`
    label: text-title;

    display: flex;
    justify-content: ${(props) => props.align || 'space-between'};
    align-items: center;
    font-size: 1.4rem;
    font-weight: var(--text-title-weight);
    color: var(--text-title-color);
    white-space: ${(props) => props.wrap || 'break-spaces'};
    margin-bottom: 1rem;

    strong {
        font-size: 1.2rem;
        font-weight: var(--text-title-weight);
    }
`

/**
 * @see borderLeft - only color
 * @param {{
 * bgc?: string,
 * radius?: string,
 * borderLeft?: string,
 * boxShadow?: string,
 * }} props
 *
 */
const Panel = styled.div`
    label: panel;

    position: relative;
    width: 100%;
    padding: var(--panel-padding-y) var(--panel-padding-x);
    margin: 0 auto 30px auto;
    border-radius: ${(props) => props.radius || 'var(--panel-border-radius)'};
    border-left: ${(props) => (props.borderLeft ? '10px solid ' + props.borderLeft : 'unset')};
    background-color: ${(props) => props.bgc || '#ffffff'};
    box-shadow: ${(props) => props.boxShadow || '0 0 10px 0 rgba(0, 0, 0, 0.1)'};
    z-index: var(--panel-zindex);
`

// @version 1.0.1

/**
 * @version 1.0.2 add panel
 */
const commons = {
    TextTitle,
    Panel
}

export default commons

export { TextTitle, Panel }
