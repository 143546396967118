import React, { useEffect, useState } from 'react';
import { setCookie, hasCookie } from 'cookies-next';
import styled from '@emotion/styled';
import Button from '../button';
import { breakpoint } from 'utils';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/useAuth';
import { POST } from 'api';

const Style = styled.div`
    label: cookie-consent;

    position: fixed;
    left: 24px;
    bottom: 24px;
    max-width: 500px;
    padding: 24px;
    box-shadow: 0 0 5px #00000030;
    background-color: var(--text-normal-7);
    z-index: var(--cookie-consent-zindex);

    ${breakpoint('XS')} {
        left: 0;
        right: 0;
        bottom: 0;
        max-width: none;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
    }

    .cookie-consent-title {
        font-size: 1.222rem;
        font-weight: 500;
        color: var(--text-normal-1);
    }

    .cookie-consent-detail {
        display: grid;
        grid-template-columns: 75px auto;
        margin-top: 1.267rem;
    }
    .cookie-consent-description {
        padding-left: 1rem;
    }
    .cookie-consent-image {
        width: 75px;
        height: 75px;
    }
    .cookie-consent-footer {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 1.6rem;
        margin-left: -0.5rem;
        margin-right: -0.5rem;

        button {
            width: 100%;
            max-width: 250px;
            margin: auto 0.5rem;
        }
    }
`;

function PopupCookieConsent() {
    const { t } = useTranslation();
    const { auth, profile } = useAuth();
    // state
    const [consent, setConsent] = useState(true);
    // method
    const acceptCookie = () => {
        if (auth.isAuth) saveCookieConsent(1);

        setConsent(true);
        if (process.env.NODE_ENV === 'production ') {
            setCookie('localConsent', 'true', { maxAge: 60 * 60 * 24 * 365, domain: 'pharmcare.co', sameSite: 'none', secure: true });
        } else {
            setCookie('localConsent', 'true', { maxAge: 60 * 60 * 24 * 365 });
        }
        console.log('accepring cookies');

        window.location.reload();
    };

    const denyCookie = () => {
        if (auth.isAuth) saveCookieConsent(0);

        setConsent(true);
        setConsent(true);
        if (process.env.NODE_ENV === 'production ') {
            setCookie('localConsent', 'false', { maxAge: 60 * 60 * 24 * 365, domain: 'pharmcare.co', sameSite: 'none', secure: true });
        } else {
            setCookie('localConsent', 'false', { maxAge: 60 * 60 * 24 * 365 });
        }
        console.log('denying cookie');
    };

    const saveCookieConsent = async (cookieConsent = 0) => {
        try {
            await POST('/user/save-cookie-consent', { userId: profile.userId, cookieConsent: cookieConsent });
        } catch (error) {
            console.error('/user/save-cookie-consent -> error', error);
        }
    };
    useEffect(() => {
        const checkProfileWithConsent = async () => {
            if (auth.isAuth) {
                if (profile.cookieConsent === 1) setConsent(true);
            } else {
                setConsent(hasCookie('localConsent'));
            }
        };

        checkProfileWithConsent();
    }, []);

    // return
    if (consent === true) return null;

    return (
        <Style className='cookie-consent'>
            <div className='cookie-consent-title'>{t('cookie-consent:cookie.subject')}</div>
            <div className='cookie-consent-detail'>
                <div className='cookie-consent-image'>
                    <img src={'/images/common/cookies.webp'} alt='cookie consent' />
                </div>
                <div className='cookie-consent-description'>{t('cookie-consent:cookie.detail')}</div>
            </div>
            <div className='cookie-consent-footer'>
                <Button variant='primary' onClick={acceptCookie}>
                    {t('cookie-consent:cookie.accept')}
                </Button>
                <Button variant='second' onClick={denyCookie}>
                    {t('cookie-consent:cookie.reject')}
                </Button>
            </div>
        </Style>
    );
}

export default PopupCookieConsent;
