import React, { useCallback } from 'react'
import FormGroup from '../form-group'
import FieldCheckbox from './field-checkbox'
import { extendesClassname } from '../utils'
import FormInvalidFeedback from '../form-invalid-feedback'
import FormRecommend from '../form-recommend'
import FormLabel from '../form-label'

/**
 *
 * @param {{
 * title: String | Element,
 * value: String[],
 * checkListGroup: Array<{
 *      label: String | Element,
 *      value: String,
 *      xs: xs,
 *      sm?: xs,
 *      md?: xs,
 *      lg?: xs,
 *      xl?: xs,
 *      xxl?: xs,
 *      xxxl?: xs,
 * }>,
 * classNameLabel: String,
 * classNameField: String,
 * errorMsg: String | Element,
 * recommendMsg: String | Element,
 * isInvalid: Boolean,
 * titleTextSmall: Boolean,
 * checked: Boolean,
 * xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'auto' | undefined,
 * sm?: xs,
 * md?: xs,
 * lg?: xs,
 * xl?: xs,
 * xxl?: xs,
 * xxxl?: xs,
 * } & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>} peops
 * @returns
 */
function FieldCheckboxGroup({
    title = '',
    value = [],
    checkListGroup = [],
    classNameLabel = '',
    classNameField = '',
    errorMsg = '',
    recommendMsg = '',
    isInvalid = false,
    titleTextSmall = true,
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
    xxxl,
    children,
    ...props
}) {
    const getCheckedListGroup = useCallback(
        (currentValue = '') => {
            if (Array.isArray(value) && value.length > 0) return value.some((e) => e === currentValue)

            return false
        },
        [value.length]
    )

    const getRequired = React.useMemo(() => {
        if (props.required && Array.isArray(value) && value.length >= 1) return false

        return props.required
    }, [value.length])

    return (
        <FormGroup xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl} xxxl={xxxl} className={classNameField}>
            {title && (
                <FormLabel textSmall={titleTextSmall} className={classNameLabel} required={props.required}>
                    {title}
                </FormLabel>
            )}
            <div className='exp-form-row'>
                {checkListGroup.map((item, index) => (
                    <FieldCheckbox
                        {...props}
                        {...extendesClassname(['exp-form-check-input', props.className])}
                        key={index}
                        type='checkbox'
                        id={props.id ? `${props.id}-nth-${index}` : `${props.name}-nth-${index}`}
                        label={item.label}
                        value={item.value}
                        checked={getCheckedListGroup(item.value)}
                        required={getRequired}
                        isInvalid={isInvalid}
                        xs={item.xs}
                        sm={item.sm}
                        md={item.md}
                        lg={item.lg}
                        xl={item.xl}
                        xxl={item.xxl}
                        xxxl={item.xxxl}
                    />
                ))}
                {children}
            </div>
            {errorMsg && <FormInvalidFeedback isInvalid={isInvalid}>{errorMsg}</FormInvalidFeedback>}
            {recommendMsg && <FormRecommend>{recommendMsg}</FormRecommend>}
        </FormGroup>
    )
}

export default FieldCheckboxGroup
