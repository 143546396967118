import styled from '@emotion/styled';

export const CarouselWrapper = styled.div`
    label: carousel;

    position: relative;

    .carousel-indicators {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 15;
        display: flex;
        justify-content: center;
        padding-left: 0;
        margin-right: 15%;
        margin-left: 15%;
        list-style: none;
        .active {
            opacity: 1;
        }
        div {
            box-sizing: content-box;
            flex: 0 1 auto;
            width: 30px;
            height: 3px;
            margin-right: 3px;
            margin-left: 3px;
            text-indent: -999px;
            cursor: pointer;
            background-color: #fff;
            /* background-color: red; */
            background-clip: padding-box;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            opacity: 0.5;
            transition: opacity 0.6s ease;
        }
    }

    .carousel-inner {
        position: relative;
        width: 100%;
        overflow: hidden;
        /* background-color: aqua; */
    }
    .carousel-item {
        position: relative;
        display: none;
        float: left;
        width: 100%;
        margin-right: -100%;
        backface-visibility: hidden;
        transition: transform 0.6s ease-in-out;
    }
    .carousel-item.active {
        display: block;
    }

    .fade-next {
        animation-name: fade-next;
        animation-duration: 1s;
    }

    @keyframes fade-next {
        from {
            transform: translateX(100%);
        }
        to {
            transform: translateX(0%);
        }
    }
    .fade-prev {
        animation-name: fade-prev;
        animation-duration: 1s;
    }
    @keyframes fade-prev {
        from {
            transform: translateX(-100%);
        }
        to {
            transform: translateX(0%);
        }
    }
`;
