import { css } from '@emotion/react'

export const fontFace = css`
    // Kanit, sans-serif
    @font-face {
        font-family: 'Kanit';
        src: local('Kanit'), url('/fonts/Kanit-Regular.ttf') format('truetype');
    }
    // SukhumvitSet, sans-serif
    @font-face {
        font-family: 'SukhumvitSet';
        src: local('SukhumvitSet'), url('/fonts/SukhumvitSet-Medium.ttf') format('truetype');
    }
    // Sarabun-Regular, sans-serif
    @font-face {
        font-family: 'Sarabun-Regular';
        src: local('Sarabun-Regular'), url('/fonts/Sarabun-Regular.ttf') format('truetype');
    }
    // WorkSans-Regular, sans-serif
    @font-face {
        font-family: 'WorkSans-Regular';
        src: local('WorkSans-Regular'), url('/fonts/WorkSans-Regular.ttf') format('truetype');
    }
    @font-face {
        font-family: 'WorkSans-Semi-Bold';
        src: local('WorkSans-Semi-Bold'), url('/fonts/WorkSans-SemiBold.ttf') format('truetype');
    }

    // Raleway-Medium, sans-serif
    @font-face {
        font-family: 'Raleway-Medium';
        src: local('Raleway-Medium'), url('/fonts/Raleway-Medium.ttf') format('truetype');
    }

    /* @font-face {
        font-family: 'Font Awesome 5 Free';
        src: url('/fonts/fa-solid-900.eot');
        src: url('/fonts/fa-solid-900.eot?#iefix') format('embedded-opentype'), url('/fonts/fa-solid-900.woff2') format('woff2'),
            url('/fonts/fa-solid-900.woff') format('woff'), url('/fonts/fa-solid-900.ttf') format('truetype'),
            url('/fonts/fa-solid-900.svg#fontawesome') format('svg');
    } */
`
