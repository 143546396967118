function isJson(data) {
    try {
        JSON.parse(data)
        return true
    } catch (error) {
        return false
    }
}
/**
 *
 * @param {string | object} data
 * @returns
 */
function getEncode(data) {
    let string = data

    if (typeof data !== 'string') {
        string = JSON.stringify(data)
    }

    const codeUnits = window.encodeURIComponent(string)

    return window.btoa(codeUnits)
}

/**
 *
 * @param {string} data
 * @returns {string | object}
 */
function getDecode(data) {
    const decodeUriComponent = window.decodeURIComponent(data)
    const binary = window.atob(decodeUriComponent)
    const decodeUri = window.decodeURIComponent(binary)

    if (isJson(decodeUri)) {
        return JSON.parse(decodeUri)
    }

    return decodeUri
}

/**
 *
 * @param {string} data
 * @returns
 */
function isEncode(data) {
    try {
        getDecode(data)

        return true
    } catch (error) {
        return false
    }
}

const cryptojs = {
    isEncode: isEncode,
    encode: getEncode,
    decode: getDecode
}

export default cryptojs
