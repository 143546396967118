export const SERVICE_CONFIG = {
    telepharmacy: {
        name: 'telepharmacy',
        serviceOrigin: 'telepharmacy',
        servicePath: '/telepharmacy',
        redirectServicePath: '/telepharmacy',
        paymentType: 'MEDICINE',
        communicationType: 'VIDEO_CALL',
        defaultVoucherCode: 'TLP',
        defaultPharmcareProgram: '02',
    },
    telepharmacyLTC: {
        name: 'telepharmacyLTC',
        serviceOrigin: 'telepharmacy',
        servicePath: '/telepharmacy-ltc',
        redirectServicePath: '/telepharmacy-ltc',
        paymentType: '',
        communicationType: 'VIDEO_CALL',
        defaultVoucherCode: 'TLP',
        defaultPharmcareProgram: '',
    },
    telemed: {
        name: 'telemed',
        serviceOrigin: 'telemed',
        servicePath: '/telemed',
        redirectServicePath: '/telemed',
        paymentType: '',
        communicationType: 'TELE_MED',
        defaultVoucherCode: 'telemed',
        defaultPharmcareProgram: '02',
    },
    telemedConsult: {
        name: 'telemedConsult',
        serviceOrigin: 'telemed',
        servicePath: '/telemed/consult',
        redirectServicePath: '/telemed',
        paymentType: 'SERVICE',
        communicationType: 'TELE_MED',
        defaultVoucherCode: 'telemed',
        defaultPharmcareProgram: '',
    },
    telemedFulfillment: {
        name: 'telemedFulfillment',
        serviceOrigin: 'telemed',
        servicePath: '/telemed/fulfillment',
        redirectServicePath: '/telemed',
        paymentType: 'MEDICINE',
        communicationType: 'GATE_WAY',
        defaultVoucherCode: 'telemed',
        defaultPharmcareProgram: '02',
    },
    tlmNcd: {
        name: 'tlmNcd',
        serviceOrigin: 'tlm-ncd',
        servicePath: '/tlm-ncd',
        redirectServicePath: '/tlm-ncd',
        paymentType: '',
        communicationType: 'TELE_MED',
        defaultVoucherCode: 'telemed',
        defaultPharmcareProgram: '08',
    },
    tlmNcdConsult: {
        name: 'tlmNcdConsult',
        serviceOrigin: 'tlm-ncd',
        servicePath: '/tlm-ncd/consult',
        redirectServicePath: '/tlm-ncd',
        paymentType: 'SERVICE',
        communicationType: 'TELE_MED',
        defaultVoucherCode: 'telemed',
        defaultPharmcareProgram: '',
    },
    tlmNcdFulfillment: {
        name: 'tlmNcdFulfillment',
        serviceOrigin: 'tlm-ncd',
        servicePath: '/tlm-ncd/fulfillment',
        redirectServicePath: '/tlm-ncd',
        paymentType: 'MEDICINE',
        communicationType: 'GATE_WAY',
        defaultVoucherCode: 'telemed',
        defaultPharmcareProgram: '02',
    },
    tlm: {
        name: 'tlm',
        serviceOrigin: 'tlm',
        servicePath: '/tlm',
        redirectServicePath: '/tlm',
        paymentType: '',
        communicationType: 'TELE_MED',
        defaultVoucherCode: 'telemed',
        defaultPharmcareProgram: '08',
    },
    tlmConsult: {
        name: 'tlmConsult',
        serviceOrigin: 'tlm',
        servicePath: '/tlm/consult',
        redirectServicePath: '/tlm',
        paymentType: 'SERVICE',
        communicationType: 'TELE_MED',
        defaultVoucherCode: 'telemed',
        defaultPharmcareProgram: '',
    },
    tlmFulfillment: {
        name: 'tlmFulfillment',
        serviceOrigin: 'tlm',
        servicePath: '/tlm/fulfillment',
        redirectServicePath: '/tlm',
        paymentType: 'MEDICINE',
        communicationType: 'GATE_WAY',
        defaultVoucherCode: 'telemed',
        defaultPharmcareProgram: '02',
    },
    mth: {
        name: 'mth',
        serviceOrigin: 'mth',
        servicePath: '/mth',
        redirectServicePath: '/mth',
        paymentType: '',
        communicationType: 'TELE_MED',
        defaultVoucherCode: 'telemed',
        defaultPharmcareProgram: null,
    },
    mthConsult: {
        name: 'mthConsult',
        serviceOrigin: 'mth',
        servicePath: '/mth/consult',
        redirectServicePath: '/mth',
        paymentType: 'SERVICE',
        communicationType: 'TELE_MED',
        defaultVoucherCode: 'telemed',
        defaultPharmcareProgram: null,
    },
    mthFulfillment: {
        name: 'mthFulfillment',
        serviceOrigin: 'mth',
        servicePath: '/mth/fulfillment',
        redirectServicePath: '/mth',
        paymentType: 'MEDICINE',
        communicationType: 'GATE_WAY',
        defaultVoucherCode: 'telemed',
        defaultPharmcareProgram: '02',
    },
    physiotherapy: {
        name: 'physiotherapy',
        serviceOrigin: 'physiotherapy',
        servicePath: '/pt',
        redirectServicePath: '/pt',
        paymentType: '',
        communicationType: 'TELE_MED',
        defaultVoucherCode: 'telemed',
        defaultPharmcareProgram: '04',
    },
    physiotherapyConsult: {
        name: 'physiotherapyConsult',
        serviceOrigin: 'physiotherapy',
        servicePath: '/pt/consult',
        redirectServicePath: '/pt',
        paymentType: 'SERVICE',
        communicationType: 'TELE_MED',
        defaultVoucherCode: 'telemed',
        defaultPharmcareProgram: '',
    },
    physiotherapyFulfillment: {
        name: 'physiotherapyFulfillment',
        serviceOrigin: 'physiotherapy',
        servicePath: '/pt/fulfillment',
        redirectServicePath: '/pt',
        paymentType: '',
        communicationType: 'TELE_MED',
        defaultVoucherCode: 'telemed',
        defaultPharmcareProgram: '',
    },
    nutritionist: {
        name: 'nutritionist',
        serviceOrigin: 'nutritionist',
        servicePath: '/nt',
        redirectServicePath: '/nt',
        paymentType: '',
        communicationType: 'TELE_MED',
        defaultVoucherCode: 'telemed',
        defaultPharmcareProgram: '06',
    },
    nutritionistConsult: {
        name: 'nutritionistConsult',
        serviceOrigin: 'nutritionist',
        servicePath: '/nt/consult',
        redirectServicePath: '/nt',
        paymentType: 'SERVICE',
        communicationType: 'TELE_MED',
        defaultVoucherCode: 'telemed',
        defaultPharmcareProgram: '',
    },
    nutritionistFulfillment: {
        name: 'nutritionistFulfillment',
        serviceOrigin: 'nutritionist',
        servicePath: '/nt/fulfillment',
        redirectServicePath: '/nt',
        paymentType: '',
        communicationType: 'TELE_MED',
        defaultVoucherCode: 'telemed',
        defaultPharmcareProgram: '',
    },
    fulfillment: {
        name: 'fulfillment',
        serviceOrigin: 'fulfillment',
        servicePath: '/fulfillment',
        redirectServicePath: '/fulfillment',
        paymentType: '',
        communicationType: 'GATE_WAY',
        defaultVoucherCode: 'TLP',
        defaultPharmcareProgram: '02',
    },
};

export const DEFAULT_VOUCHER_CODE = {
    [SERVICE_CONFIG.telepharmacy.name]: 'TLP',
    [SERVICE_CONFIG.telemed.name]: 'telemed',
    [SERVICE_CONFIG.fulfillment.name]: 'TLP',
    [SERVICE_CONFIG.physiotherapy.name]: 'telemed',
    [SERVICE_CONFIG.nutritionist.name]: 'telemed',
    [SERVICE_CONFIG.tlm.name]: 'telemed',
    [SERVICE_CONFIG.mth.name]: 'telemed',
    [SERVICE_CONFIG.tlmNcd.name]: 'telemed',
};

export const PAYMENT_TYPE = {
    [SERVICE_CONFIG.telepharmacy.name]: 'MEDICINE',
    [SERVICE_CONFIG.telemedConsult.name]: 'SERVICE',
    [SERVICE_CONFIG.telemedFulfillment.name]: 'MEDICINE',
    [SERVICE_CONFIG.fulfillment.name]: 'MEDICINE',
    [SERVICE_CONFIG.physiotherapyConsult.name]: 'SERVICE',
    [SERVICE_CONFIG.nutritionistConsult.name]: 'SERVICE',
    [SERVICE_CONFIG.tlmConsult.name]: 'SERVICE',
    [SERVICE_CONFIG.tlmFulfillment.name]: 'MEDICINE',
    [SERVICE_CONFIG.mthConsult.name]: 'SERVICE',
    [SERVICE_CONFIG.mthFulfillment.name]: 'MEDICINE',
    [SERVICE_CONFIG.tlmNcdConsult.name]: 'SERVICE',
    [SERVICE_CONFIG.tlmNcdFulfillment.name]: 'MEDICINE',
};

export const DEFAULT_PHARMCARE_PROGRAM = {
    [SERVICE_CONFIG.telepharmacy.name]: '02',
    [SERVICE_CONFIG.telemed.name]: '02',
    [SERVICE_CONFIG.telemedFulfillment.name]: '02',
    [SERVICE_CONFIG.fulfillment.name]: '02',
    [SERVICE_CONFIG.physiotherapy.name]: '04',
    [SERVICE_CONFIG.nutritionist.name]: '06',
    [SERVICE_CONFIG.tlm.name]: '08',
    [SERVICE_CONFIG.tlmConsult.name]: '08',
    [SERVICE_CONFIG.tlmFulfillment.name]: '02',
    [SERVICE_CONFIG.tlmNcd.name]: '08',
    [SERVICE_CONFIG.tlmNcdConsult.name]: '08',
    [SERVICE_CONFIG.tlmNcdFulfillment.name]: '02',
    [SERVICE_CONFIG.mth.name]: null,
    [SERVICE_CONFIG.mthConsult.name]: null,
    [SERVICE_CONFIG.mthFulfillment.name]: '02',
};
