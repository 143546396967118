import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { breakpoint } from 'utils'
import IconConfigWithTheme from 'components/common/icon-config-with-theme'

function Footer() {
    const { t } = useTranslation()
    return (
        <FooterWrapper>
            <Box data-test-id='address'>
                <Img>
                    <img src='/images/logo/footer-logo.png' alt='footer-logo' />
                </Img>
                <p>
                    {t('common:footer.pharmcare')}
                    <br />
                    {t('common:footer.rong_muang')}
                    <br />
                    {t('common:footer.pathum')}
                    <br />
                    {t('common:footer.bangkok')}
                    <br />
                    Mail: info@pharmcare.co{' '}
                </p>
            </Box>
            <Box data-test-id='general'>
                <Title> {t('common:footer.our_terms')}</Title>
                <a href='/terms-conditions' target='_blank' className='text-white'>
                    Terms & Conditions
                </a>
                <br />
                <a href='/privacy-policy' target='_blank' className='text-white'>
                    Privacy Policy
                </a>
                <Text className='mt-3'>สนใจร่วมเป็นส่วนหนึ่งกับเครือข่ายร้านยา PharmCare</Text>
                <Text>สามารถเพิ่มเพื่อนและสอบถามไลน์ละเอียดได้ที่</Text>
                <Text>Line ID: @pharmcaresupport</Text>
            </Box>
            <Box data-test-id='contact'>
                <Title> {t('common:footer.contact_us')}</Title>
                <Text>
                    <IconConfigWithTheme pageName='footer' iconName='envelope' />
                    &nbsp;info@pharmcare.com
                </Text>
                <Text>
                    <IconConfigWithTheme pageName='footer' iconName='phone' /> @app.pharmcare
                </Text>
                <Text>
                    <IconConfigWithTheme pageName='footer' iconName='line' /> @app.pharmcare
                </Text>
            </Box>
            <BoxBottom data-test-id='version'>System Version 10.23.00</BoxBottom>
        </FooterWrapper>
    )
}

const FooterWrapper = styled.div`
    label: footer-wrapper;
    width: 100%;
    color: var(--white);
    font-weight: 300;
    padding: 40px 10%;
    background-color: var(--primary-color);
    ${breakpoint('XL')} {
        display: none;
    }
`
const Box = styled.div`
    label: footer-box;
    display: inline-block;
    vertical-align: top;
    padding-right: 6%;
`
const BoxBottom = styled.div`
    label: footer-box-bottom;
    display: flex;
    vertical-align: bottom;
    justify-content: end;
`
const Img = styled.div`
    label: footer-img;
    width: 150px;
    margin-bottom: 1.5rem;
`
const Title = styled.div`
    label: footer-title;
    font-weight: 400;
    margin-bottom: 0.5rem;
`
const Text = styled.div`
    label: footer-text;
    margin-bottom: 8px;
`

export default Footer
