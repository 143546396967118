import { css } from '@emotion/react'

// text color
export const color = css`
    .text {
        &-primary {
            color: var(--primary-color) !important;
        }
        &-second {
            color: var(--second-color) !important;
        }
        &-gray-2 {
            color: var(--text-normal-4) !important;
        }
        &-gray-1 {
            color: var(--text-normal-2) !important;
        }
        &-gray-5 {
            color: var(--text-normal-7) !important;
        }
        &-gray-4 {
            color: var(--text-normal-7) !important;
        }
        &-gray-3 {
            color: var(--color-text-gray-3) !important;
        }

        &-green-3 {
            color: var(--green-3) !important;
        }

        &-black-transparent-90 {
            color: var(--text-normal-1) !important;
        }

        &-green-2 {
            color: var(--green-2) !important;
        }

        &-lightseagreen-1 {
            color: var(--lightseagreen-1) !important;
        }

        &-white {
            color: var(--white) !important;
        }

        &-orange-2 {
            color: var(--orange-2) !important;
        }
        &-orange-1 {
            color: var(--orange-1) !important;
        }
        &-red-1 {
            color: var(--red-1) !important;
        }
        &-green-1 {
            color: var(--green-1) !important;
        }
        &-yellow {
            color: var(--yellow) !important;
        }
        &-black-1 {
            color: var(--text-normal-1) !important;
        }
        &-black-2 {
            color: var(--text-normal-1) !important;
        }
        &-orange-5 {
            color: var(--orange-5) !important;
        }
        &-primary-icon {
            color: var(--icon-primary-color);
        }
        &-second-icon {
            color: var(--icon-second-color);
        }
        &-third-icon {
            color: var(--icon-third-color);
        }
        &-ellipsis {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
`
