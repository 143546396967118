import React, { Component } from 'react'
import PopupComponent from './popup-component'

export class PopupWrapper extends Component {
    constructor(props) {
        super(props)
        this.state = {
            popups: [] // includes multiple models
        }

        this.totalIndex = 0
    }

    closeAll = () => {
        let { popups } = this.state

        // clear all popup
        if (popups.length > 0) popups.splice(0, popups.length)

        this.setState({ popups })
    }

    open = ({ ...args }) => {
        const popup = { ...args }
        let { popups } = this.state

        // isVisible controls the visibility of bottomsheet
        this.totalIndex++

        popup.id = `exp-popupjs-${this.totalIndex}`

        // ref controls the popup behaviour, like closing the popup our update the popup
        if (!popup.ref) {
            popup.ref = React.createRef()
        }
        if (!popup.onClose) {
            popup.onClose = () => null
        }

        popups.push({ ...popup })
        this.setState({ popups })

        // return index
        return popup.id
    }

    close = (index) => {
        let { popups } = this.state

        if (!index) return

        const indexOf = popups.findIndex((f) => f.id === index)
        setTimeout(() => {
            popups.splice(indexOf, 1)
            this.setState({ popups })
        }, 200)

        // in order to return close effect
        if (popups[indexOf]) {
            popups[indexOf].onClose()
            this.setState({ popups })
        }
    }

    updateProps = ({ ...props }, index) => {
        const { popups } = this.state

        if (!index) return

        const indexOf = popups.findIndex((f) => f.id === index)
        popups[indexOf] = { ...popups[indexOf], ...props }
        this.setState({ popups })
    }

    render() {
        const { popups } = this.state

        return (
            <>
                {popups.map((popup, index) => (
                    <PopupComponent key={popup.id + '' + index} close={() => this.close(popup.id)} {...popup} />
                ))}
            </>
        )
    }
}
