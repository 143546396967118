import React, { useState } from 'react';

export interface PageContextProps {
    props: any;
    setProps: (...arg: any) => void;
    isPageLoad: boolean;
    setPageLoaded: (setState: boolean) => void;
    hasFavourite: boolean;
    setHasFavourite: (setState: boolean) => void;
}

const initialValue: PageContextProps = {
    props: {},
    setProps: () => null,
    isPageLoad: true,
    setPageLoaded: () => null,
    hasFavourite: false,
    setHasFavourite: () => null,
};

const PageContext = React.createContext<PageContextProps>(initialValue);

const PageConsumer = PageContext.Consumer;

const PageProvider = ({ children }) => {
    const [props, setProps] = useState<any>({});
    const [isPageloaded, setIsPageloaded] = useState<boolean>(false);
    const [hasFavourite, setHasFavourite] = useState<boolean>(false);

    const setLoadPage = (finish: boolean) => {
        if (finish) {
            document.body.style.overflow = 'auto';
        } else {
            document.body.style.overflow = 'hidden';
        }

        setIsPageloaded(finish);
    };

    const values: PageContextProps = React.useMemo(() => {
        return {
            props: props,
            setProps: setProps,
            isPageLoad: isPageloaded,
            setPageLoaded: setLoadPage,
            hasFavourite: hasFavourite,
            setHasFavourite: setHasFavourite,
        };
    }, [props, isPageloaded, hasFavourite]);

    return <PageContext.Provider value={values}>{children}</PageContext.Provider>;
};

export { PageProvider, PageConsumer };
export default PageContext;
