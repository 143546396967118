import { extendesClassname } from 'utils'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

/**
 * @version 1.0.1
 * @see xs= devices (<576px)
 * @see sm= devices (≥576px)
 * @see md= devices (≥768px)
 * @see lg= devices (≥992px)
 * @see xl= devices (≥1200px)
 * @see xxl= devices (≥1400px)
 * @see xxxl= devices (≥1600px)
 * @see css className referent from bootstrap version 4.4.1
 *
 * @param {{
 * xs?: number | 'auto' | undefined,
 * sm?: xs,
 * md?: xs,
 * lg?: xs,
 * xl?: xs,
 * xxl?: xs,
 * xxxl?: xs,
 * className?: string,
 * children?: element
 * } & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns
 */

function Col({ xs, sm, md, lg, xl, xxl, xxxl, className, children, ...props }) {
    const getCol = useCallback((colList) => {
        const keys = Object.keys(colList)
        const values = Object.values(colList)
        const colClass = values.map((value, index) => {
            if (value && keys[index] === 'xs' && value === 'auto') return `col`

            if (value && keys[index] === 'xs' && value !== 'auto') return `col-${value}`

            if (value) return `col-${keys[index]}-${value}`

            return null
        })
        // return
        // if all values equa null or undefined return "col"
        if (values.every((e) => !e)) return ['col']

        return colClass
    })
    return (
        <div {...extendesClassname([...getCol({ xs, sm, md, lg, xl, xxl, xxxl }), className])} {...props}>
            {children}
        </div>
    )
}

Col.propTypes = {
    xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    xl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    xxl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    xxxl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    children: PropTypes.node
}

export default Col
