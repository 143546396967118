function getParamsToObject(params = '') {
    const _params = params || window.location.search
    let result = {}

    if (_params) _params.replace(/[?&]+([^=&]+)=([^&]*)/gi, (substring, key, value) => (result[key] = value))

    return result
}

function getQueryToParams(query = {}) {
    let queryArr = []

    if (Object.keys(query).length > 0) {
        Object.keys(query).forEach((element, index) => {
            if (!Object.values(query)[index]) return

            queryArr.push(`${element}=${Object.values(query)[index]}`)
        })

        return `?${queryArr.join('&')}`
    }

    return ''
}

const paramsjs = {
    search: () => window.location.search,
    query: (params = '') => getParamsToObject(params),
    queryToParams: (query = {}) => getQueryToParams(query)
}

export default paramsjs
