import { DEFAULT_BREAKPOINTS_SIZE } from 'constants/default-config'

const linearInterpolation = (data) => {
    const keys = Object.keys(data)
    const m = (data[keys[1]] - data[keys[0]]) / (keys[1] - keys[0])
    let b = data[keys[0]] - m * keys[0]

    let sign = '+'

    if (b < 0) {
        sign = '-'
        b = Math.abs(b)
    }

    return `calc(${m * 100}vw ${sign} ${b}px)`
}

const getBreakpoint = (breakpoint) => {
    const defaultBreakpoint = { ...DEFAULT_BREAKPOINTS_SIZE, SM: 0, XS: 0 }
    const isExistingBreakpoint = Object.keys(defaultBreakpoint).some((b) => b === breakpoint)

    if (isExistingBreakpoint) {
        return defaultBreakpoint[breakpoint]
    }

    return breakpoint
}
/**
 * @see return '@media (min-width: ${DEFAULT_BREAKPOINTS_SIZE[propertyValue.key]}px) {${property}: ${propertyValue.value};}'
 * @param  {string} property
 * @param  {DEFAULT_BREAKPOINTS_SIZE} propertyValue
 * @returns
 */
function fluidSizing(property, propertyValue) {
    const keys = Object.keys(propertyValue)

    let mediaQuery = ''

    for (let i = 0; i < keys.length - 1; i++) {
        const breakpoint = getBreakpoint(keys[i])
        const nextBreakpoint = getBreakpoint(keys[i + 1])
        const value = propertyValue[keys[i]]
        const nextValue = propertyValue[keys[i + 1]]

        const temp = {
            [breakpoint]: value,
            [nextBreakpoint]: nextValue
        }

        mediaQuery += `
      @media screen and (min-width: ${breakpoint}px) {
        ${property}: ${linearInterpolation(temp)};
      }
    `
    }

    mediaQuery += `
    @media screen and (min-width: ${getBreakpoint(keys[keys.length - 1])}px) {
      ${property}: ${propertyValue[keys[keys.length - 1]]}px;
    }
  `

    return mediaQuery
}

export default fluidSizing
