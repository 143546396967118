// / <reference types="google.maps" />

import React, { useEffect, useRef, useState } from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { DEFAULT_LATLNG } from 'constants/default-config';
import { useTranslation } from 'react-i18next';

/**
 * @param {{
 * defaultCenter: {lat: number, lng: number},
 * defaultZoom: number,
 * center: {lat: number, lng: number},
 * zoom: number,
 * options: object,
 * onGoogleApiLoaded: (map:Map)=> void,
 * onZoomChanged: (zoom:number)=> void,
 * onDragEnd: onDragEnd: (latlng:{lat: ()=> number, lng:()=> number} , map)=> void
 * }}
 * @see options see https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
 * @returns *
 */
function Maps({
    defaultCenter,
    defaultZoom,
    center,
    zoom,
    options,
    onGoogleApiLoaded,
    onDragEnd,
    onZoomChanged = () => null,
    children,
    ...props
}) {
    const ref = useRef();
    const [maps, setMaps] = useState(null);
    const [drag, setDrag] = useState(null);
    const [zooms, setZooms] = useState(null);

    useEffect(() => {
        const initialMap = () => {
            if (window.google?.maps) {
                const map = new window.google.maps.Map(ref.current, {
                    center: defaultCenter,
                    zoom: defaultZoom,
                    gestureHandling: 'cooperative',
                    zoomControl: false,
                    fullscreenControl: false,
                    mapTypeControl: false,
                    overviewMapControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    ...options,
                });

                map.addListener('dragend', () => {
                    setDrag(map.getCenter());
                });
                map.addListener('zoom_changed', () => {
                    setZooms(map.getZoom());
                });
                if (center) map.panTo(center);
                if (zoom) map.setZoom(zoom);

                setMaps(map);
                onGoogleApiLoaded({ map });
            }
        };
        initialMap();
    }, []);

    useEffect(() => {
        const onSetCenter = () => {
            if (maps && center) {
                maps.panTo(center);
            }
        };

        onSetCenter();
    }, [maps, JSON.stringify(center)]);

    useEffect(() => {
        if (drag) onDragEnd(drag, maps);
    }, [drag]);

    useEffect(() => {
        if (zooms) onZoomChanged(zooms);
    }, [zooms]);
    return (
        <>
            <Style ref={ref} {...props} />
            {children}
        </>
    );
}

Maps.options = {
    styles: [
        {
            featureType: 'poi',
            elementType: 'labels.icon',
            stylers: [{ visibility: 'on' }],
        },
        {
            featureType: 'transit',
            stylers: [{ visibility: 'off' }],
        },
    ],
};

Maps.defaultProps = {
    defaultCenter: { lat: DEFAULT_LATLNG.latitude, lng: DEFAULT_LATLNG.longitude },
    defaultZoom: 0,
    zoom: 0,
    onGoogleApiLoaded: () => null,
    onDragEnd: () => null,
};
Maps.propTypes = {
    /** center = { lat: number, lng: number} */
    center: PropTypes.object,
    zoom: PropTypes.number,
    defaultCenter: PropTypes.object.isRequired,
    defaultZoom: PropTypes.number.isRequired,
    /** See https://developers.google.com/maps/documentation/javascript/reference/map?hl=en#MapOptions */
    options: PropTypes.object,
    onGoogleApiLoaded: PropTypes.func,
    onDragEnd: PropTypes.func,
};

/**
 * @param {{
 * defaultCenter?: {lat: number, lng: number},
 * defaultZoom?: number,
 * center?: {lat: number, lng: number},
 * zoom?: number,
 * options?: object,
 * onGoogleApiLoaded?: (event:{ map })=> void,
 * onZoomChanged?: (zoom:number)=> void,
 * onDragEnd?: (latlng:{lat: ()=> number, lng:()=> number} , map)=> void
 * }} props
 * @see options see https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
 * @returns *
 */
function GoogleMaps(props) {
    const { i18n } = useTranslation();
    const render = (status) => {
        switch (status) {
            case Status.LOADING:
                return <div>maps loading...</div>;
            case Status.FAILURE:
                return <div>maps error</div>;
            case Status.SUCCESS:
                return <div>maps loaded</div>;
        }
    };
    return (
        <Wrapper
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
            language={i18n.language.toLowerCase()}
            libraries={['places', 'geometry']}
            render={render}
        >
            <Maps {...props} />
        </Wrapper>
    );
}

export default GoogleMaps;

GoogleMaps.defaultProps = Maps.defaultProps;

GoogleMaps.propTypes = Maps.propTypes;

const Style = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;
