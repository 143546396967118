import React from 'react'
import { dateFormat } from './utils'
import CalendarMonthPanel from './calendar-month-panel'
import CalendarYearPanel from './calendar-year-panel'
import CalendarDecadePanel from './calendar-decade-panel'
import CalendarJs, { LOCALE } from 'components/common/exp-calendarjs'
import './calendarjs.css'

function DatePickerCalendar({
    value = '',
    placeholder = '',
    locale = 'th',
    format = 'YYYY-MM-DD',
    autoFocus = false,
    onChangeDate = () => null,
    datePickerRef,
    ...props
}) {
    const inputDateRef = React.useRef()
    const [currentDate, setCurrentDate] = React.useState(new Date())
    const [showMonth, setShowMonth] = React.useState(false)
    const [showYear, setShowYear] = React.useState(false)
    const [showYearOfDecade, setShowYearOfDecade] = React.useState(false)
    const [inputDate, setInputDate] = React.useState('')

    const onPrevMonth = () => {
        const date = new Date(currentDate)
        date.setMonth(date.getMonth() - 1)

        setCurrentDate(date)
    }

    const onNextMonth = () => {
        const date = new Date(currentDate)
        date.setMonth(date.getMonth() + 1)

        setCurrentDate(date)
    }

    const onPrevYear = () => {
        const date = new Date(currentDate)
        date.setFullYear(date.getFullYear() - 1)

        setCurrentDate(date)
    }

    const onNextyear = () => {
        const date = new Date(currentDate)
        date.setFullYear(date.getFullYear() + 1)

        setCurrentDate(date)
    }

    const onSelectDate = (date) => {
        setCurrentDate(date)
        setInputDate(dateFormat(date, format))
        // callback
        onChangeDate(date)
    }

    const onSelectMonth = (valueDate = 0) => {
        const date = new Date(currentDate)
        date.setMonth(valueDate)

        setCurrentDate(date)
        setShowMonth(false)
    }

    const onSelectYear = (valueDate = 0, fromDecade = false) => {
        const date = new Date(currentDate)

        date.setFullYear(valueDate)

        setCurrentDate(date)

        if (fromDecade) {
            setShowYearOfDecade(false)
        } else {
            setShowYear(false)
        }
    }

    const isValidDate = (d) => {
        return d instanceof Date && !isNaN(d)
    }

    const replaceBetween = (origin, startIndex, endIndex, insertion) =>
        origin.substring(0, startIndex) + insertion + origin.substring(endIndex)

    const onChangeInputDate = (event) => {
        const value = event.target.value
        setInputDate(value)

        let dateWithFormat = {}
        format.replace(/([DdMmYHhs]+[^\-/: ]*)/gi, (match, p1, offset) => {
            dateWithFormat[p1] = value.substring(offset, offset + p1.length)
        })

        const date = `${dateWithFormat.YYYY}-${dateWithFormat.MM}-${dateWithFormat.DD}`
        const inputDates = replaceBetween(format, 0, value.length, value)
        const inputDateWithFormat = dateFormat(new Date(date), format)

        if (Object.keys(dateWithFormat).length > 0 && inputDateWithFormat === inputDates) {
            setCurrentDate(new Date(date))
        }
    }

    const onBlurInputDate = () => {
        setInputDate(dateFormat(currentDate, format))
    }

    const onEnterKey = (e) => {
        if (e.key === 'Enter') {
            const _currentDate = dateFormat(currentDate, format)

            if (_currentDate !== inputDate) {
                setInputDate(dateFormat(currentDate, format))
            }
            // callback
            onChangeDate(new Date(currentDate))

            e.target.blur()
            e.preventDefault()
        }
    }

    React.useEffect(() => {
        const init = () => {
            const date = new Date(value)
            const isDate = isValidDate(date)

            if (isDate) {
                setCurrentDate(date)
                setInputDate(dateFormat(date, format))
            } else {
                const newDate = new Date()

                setCurrentDate(newDate)
            }

            if (autoFocus) {
                inputDateRef.current.focus()
            }
        }

        init()
    }, [value, autoFocus])

    return (
        <div ref={datePickerRef} {...props}>
            <div className='calendar-container'>
                <div className='calendar'>
                    <div className='calendar-panel'>
                        <div className='calendar-input-wrap'>
                            <input
                                ref={inputDateRef}
                                value={inputDate || ''}
                                placeholder={placeholder || format}
                                className='calendar-input'
                                onKeyDown={onEnterKey}
                                onBlur={onBlurInputDate}
                                onChange={onChangeInputDate}
                            />
                        </div>
                        <div className='calendar-date-panel'>
                            <div className='calendar-header'>
                                <div style={{ position: 'relative' }}>
                                    <button className='calendar-prev-year-btn' onClick={onPrevYear}>
                                        {'<<'}
                                    </button>
                                    <button className='calendar-prev-month-btn' onClick={onPrevMonth}>
                                        {'<'}
                                    </button>
                                    <span className='calendar-month-select' onClick={() => setShowMonth(true)}>
                                        {LOCALE[locale].monthsShort[currentDate.getMonth()]}
                                    </span>
                                    <span className='calendar-year-select' onClick={() => setShowYear(true)}>
                                        {currentDate.getFullYear()}
                                    </span>
                                    <button className='calendar-next-month-btn' onClick={onNextMonth}>
                                        {'>'}
                                    </button>
                                    <button className='calendar-next-year-btn' onClick={onNextyear}>
                                        {'>>'}
                                    </button>
                                </div>
                                {showMonth && (
                                    <CalendarMonthPanel
                                        locale={locale}
                                        currentDate={currentDate}
                                        onPrevYear={onPrevYear}
                                        onNextyear={onNextyear}
                                        onSelectMonth={onSelectMonth}
                                        onShowYearOfDecade={() => setShowYear(true)}
                                    />
                                )}
                                {showYear && (
                                    <CalendarYearPanel
                                        currentDate={currentDate}
                                        onSelectYear={onSelectYear}
                                        onShowYearOfCentury={() => setShowYearOfDecade(true)}
                                    />
                                )}
                                {showYearOfDecade && <CalendarDecadePanel currentDate={currentDate} onSelectYear={onSelectYear} />}
                            </div>
                        </div>
                        <div className='calendar-body'>
                            <CalendarJs locale={locale} currentDate={currentDate} onSelectDate={onSelectDate} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DatePickerCalendar
