import PropTypes from 'prop-types'
import React from 'react'
import { extendesClassname } from './utils'

/**
 * @version 1.0.1
 * @param { React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 */
function FormRow(props) {
    return React.createElement('div', { ...props, ...extendesClassname(['exp-form-row', props.className]) }, props.children)
}

export default FormRow

FormRow.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
}
