import React from 'react'
import { extendesClassname, getColClass } from './utils'

/**
 *
 * @param {{
 * xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'auto' | undefined,
 * sm?: xs,
 * md?: xs,
 * lg?: xs,
 * xl?: xs,
 * xxl?: xs,
 * xxxl?: xs
 * } & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> }
 * @returns
 */
function FormGroup({ xs, sm, md, lg, xl, xxl, xxxl, ...props }) {
    return React.createElement(
        'fieldset',
        {
            ...props,
            ...extendesClassname([
                'exp-form-group',
                ...getColClass({
                    xs: xs,
                    sm: sm,
                    md: md,
                    lg: lg,
                    xl: xl,
                    xxl: xxl,
                    xxxl: xxxl
                }),
                props.className
            ])
        },
        props.children
    )
}

export default FormGroup
