import React from 'react'
import { extendesClassname } from './utils'

/**
 *
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns
 */
function FormRecommend(props) {
    return React.createElement('div', { ...props, ...extendesClassname(['exp-form-recommend', props.className]) }, props.children)
}
export default FormRecommend
