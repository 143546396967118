import React from 'react'
import FormGroup from '../form-group'
import { extendesClassname } from '../utils'
import FormInvalidFeedback from '../form-invalid-feedback'
import FormRecommend from '../form-recommend'
import FormLabel from '../form-label'

/**
 *
 * @param {{
 * title: String | Element,
 * label: String | Element,
 * classNameLabel: String,
 * classNameField: String,
 * errorMsg: String | Element,
 * recommendMsg: String | Element,
 * isInvalid: Boolean,
 * labelTextSmall: Boolean,
 * titleTextSmall: Boolean,
 * xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'auto' | undefined,
 * sm?: xs,
 * md?: xs,
 * lg?: xs,
 * xl?: xs,
 * xxl?: xs,
 * xxxl?: xs,
 * } & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>} peops
 * @returns
 */
function FieldNumeric({
    title = '',
    label = '',
    classNameLabel = '',
    classNameField = '',
    errorMsg = '',
    recommendMsg = '',
    isInvalid = false,
    labelTextSmall = true,
    titleTextSmall = true,
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
    xxxl,
    children,
    onChange = () => null,
    ...props
}) {
    const inputRef = React.useRef(null)

    const onHandleNumber = (event) => {
        const _value = String(event.target.value)
        if (isNaN(_value)) {
            onChange({ ...event, target: { name: event.target.name, value: props.value } })
        } else if (String(props.value) === '0') {
            onChange({ ...event, target: { name: event.target.name, value: _value.replace('0', '') } })
        } else if (!_value) {
            onChange({ ...event, target: { name: event.target.name, value: 0 } })
        } else {
            onChange({ ...event, target: { name: event.target.name, value: _value } })
        }
    }

    React.useEffect(() => {
        const setInvalid = () => {
            if (props.required && !isInvalid && String(props.value) === '0') {
                inputRef.current.setCustomValidity('Invalid field.')
            } else if (props.required && !isInvalid && String(props.value) !== '0') {
                inputRef.current.setCustomValidity('')
            }
        }

        setInvalid()
    }, [props.required, props.value, isInvalid])

    React.useEffect(() => {
        const setInvalid = () => {
            const _isInvalidClassname = inputRef.current?.classList?.contains('is-invalid')

            if (isInvalid) {
                inputRef.current.setCustomValidity('Invalid field.')

                if (!_isInvalidClassname) inputRef.current.classList.add('is-invalid')
            } else if (_isInvalidClassname) {
                inputRef.current.classList.remove('is-invalid')
            }
        }

        setInvalid()

        return () => inputRef.current.setCustomValidity('')
    }, [isInvalid])

    return (
        <FormGroup xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl} xxxl={xxxl} className={classNameField}>
            {(title || label) && (
                <FormLabel textSmall={labelTextSmall && titleTextSmall} className={classNameLabel} required={props.required}>
                    {title || label}
                </FormLabel>
            )}
            <input
                {...props}
                {...extendesClassname(['exp-form-control', props.className])}
                type='tel'
                ref={inputRef}
                onChange={onHandleNumber}
            />
            {children}
            {errorMsg && <FormInvalidFeedback>{errorMsg}</FormInvalidFeedback>}
            {recommendMsg && <FormRecommend>{recommendMsg}</FormRecommend>}
        </FormGroup>
    )
}

export default FieldNumeric
